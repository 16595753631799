import React, { useContext, useState, useEffect } from "react";
import { GameContext } from "../../../../../network/Connector";

import * as Colyseus from "colyseus.js";
import "./../../components/selectionStyles.css";
import _ from "lodash";
import { AppContext } from "../../../../AppContext.js";
import {
  SelectedSpot,
  NftSelectionModal,
} from "../components/InventoryComponents";
import {
  MAX_INVENTORY_SLOTS,
  DEFAULT_INVENTORY_SLOTS,
  RARITY_WEIGHTS,
} from "../../../../../Constants";
import { StatPanel } from "../components/StatPanel";
import { InventorySlotsDisplay } from "../components/InventorySlotsDisplay";
import ColyseusHelper from "../../../../../utils/ColyseusHelper";
import { UpgradeSlotsDisplay } from "../components/UpgradeComponents";

/**
 * @param
 * @param {Status} String options: "filled","empty","locked"
 * @param {DerbyDriver||DerbyDriver} nft
 */
class Slot {
  constructor(status, nft) {
    this.status = status;
    this.nft = nft;
  }
}
export default () => {
  /**
   * @type {{room:Colyseus.Room}}
   */
  const { room, player } = useContext(GameContext);
  const { switchMenu, ShowLoading } = useContext(AppContext);

  //Upgrade modal switches
  const [officeSlotIndex, setOfficeSlotIndex] = useState(false);
  const [officeManagerSlotIndex, setOfficeManagerSlotIndex] = useState(false);

  const [officeSlots, setOfficeSlots] = useState([]);
  const [officeManagerSlots, setOfficeManagerSlots] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);

  let [driverPool, setDriverPool] = useState([]);
  let [selectedDrivers, setSelectedDrivers] = useState([]);
  const [equippedDriver, setEquippedDriver] = useState(null);
  const [selectedDriverIndex, setSelectedDriverIndex] = useState(-1);

  let [managersPool, setManagersPool] = useState([]);
  let [selectedManagers, setSelectedManagers] = useState([]);
  const [equippedManager, setEquippedManager] = useState(null);
  const [selectedManagerIndex, setSelectedManagerIndex] = useState(-1);

  const [driverSelector, setDriverSelector] = useState(false);
  const showDriverSelector = (bool, index) => {
    setOfficeSlotIndex(index);
    setDriverSelector(bool);
  };

  const [managerSelector, setManagerSelector] = useState(false);

  const showManagerSelector = (bool, index) => {
    setOfficeManagerSlotIndex(index);
    setManagerSelector(bool);
  };

  const updateSlot = (index, nft) => {
    const slots = [...officeSlots];
    slots[index].status = "filled";
    slots[index].nft = nft;
    if (selectedDriverIndex < 0) {
      setSelectedDriverIndex(index);
    }
    setOfficeSlots([...slots]);

    let selected = [];

    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];
      if (slot.status == "filled") {
        selected.push(slot.nft);
      }
    }

    setSelectedDrivers([...selected]);
  };

  const onUnequip = (
    _officeSlots,
    _selectedIndex,
    _setGarageSlots,
    _setSelectedSlots,
    _setSelectedIndex
  ) => {
    try {
      const slots = [..._officeSlots];

      console.log("onUnequip 1", {
        officeSlots: [..._officeSlots],
        _selectedIndex,
        _setGarageSlots,
        _setSelectedSlots,
        _setSelectedIndex,
      });
      //Empty slot
      slots[_selectedIndex].status = "empty";
      slots[_selectedIndex].nft = null;

      //Auto Equip
      let rarityWeights = slots.map((slot) => {
        if (slot.status == "filled") {
          console.log("onUnequip 3", {
            slot,
          });
          return RARITY_WEIGHTS[slot?.nft?.rarity?.toLowerCase()];
        }

        return -1;
      });

      let selectedIndex = -1;
      let highestRarity = Math.max(...rarityWeights);
      if (highestRarity > 0)
        selectedIndex = rarityWeights.indexOf(highestRarity);

      console.log("onUnequip 2", {
        selectedIndex,
        highestRarity,
        rarityWeights,
      });
      _setGarageSlots([...slots]);

      let selected = [];

      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        if (slot.status == "filled") {
          selected.push(slot.nft);
        }
      }

      _setSelectedSlots([...selected]);
      _setSelectedIndex(selectedIndex);
    } catch (error) {
      console.error("onUnequip", { error });
    }
  };

  const onUnequipManager = () => {
    onUnequip(
      officeManagerSlots,
      selectedManagerIndex,
      setOfficeManagerSlots,
      setSelectedManagers,
      setSelectedManagerIndex
    );
  };

  const onUnequipDriver = () => {
    onUnequip(
      officeSlots,
      selectedDriverIndex,
      setOfficeSlots,
      setSelectedDrivers,
      setSelectedDriverIndex
    );
  };

  const updateManagerSlot = (index, nft) => {
    const slots = [...officeManagerSlots];
    slots[index].status = "filled";
    slots[index].nft = nft;
    setOfficeManagerSlots([...slots]);
    if (selectedManagerIndex < 0) {
      setSelectedManagerIndex(index);
    }

    let selected = [];

    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];
      if (slot.status == "filled") {
        selected.push(slot.nft);
      }
    }

    setSelectedManagers([...selected]);
  };

  const returnToLobby = async () => {
    console.log("Return To Lobby");
    switchMenu("Main");
  };

  useEffect(() => {
    /**
     *
     */
    console.log("debug-office officeManagerSlots 0", {
      ...officeSlots,
    });
    let ctr = 0;
    let _driverSlots = officeSlots.reduce((slotsResult, slot) => {
      if (slot.nft) slotsResult[ctr] = slot.nft;
      ctr++;
      return slotsResult;
    }, {});
    console.log("debug-office officeManagerSlots 1", {
      ..._driverSlots,
    });
    loadDriverSlots(_driverSlots, selectedManagers.length);
    console.log("debug-office officeManagerSlots 2", {
      ..._driverSlots,
    });
  }, [officeManagerSlots]);

  useEffect(() => {
    console.log("debug-office");
    let _driverSlots = player?.office?.drivers?.slots;
    let _managerSlots = player?.office?.managers?.slots;
    let _activeDriverIndex = player?.office?.drivers?.active;
    let _activeManagerIndex = player?.office?.managers?.active;
    window.managerSlots = _managerSlots;

    setEquippedManager(_managerSlots?.[_activeManagerIndex]);
    setEquippedDriver(_driverSlots?.[_activeDriverIndex]);

    setSelectedManagerIndex(_activeManagerIndex);
    setSelectedDriverIndex(_activeDriverIndex);

    loadManagerSlots(_managerSlots);
    console.log("aaaa", { _managerSlots, _driverSlots });
    loadDriverSlots(
      _driverSlots,
      Object.keys(ColyseusHelper.mapSchemaToArrayObject(_managerSlots)).length
    );

    console.log("debug-office", {
      _activeManagerIndex,
      _activeDriverIndex,
      _managerSlots,
      _driverSlots,
    });

    setManagersPool([...player.managers]);
    setDriverPool([...player.drivers]);
  }, []);

  useEffect(() => {
    setEquippedDriver(officeSlots[selectedDriverIndex]?.nft);
  }, [selectedDriverIndex, officeSlots]);
  useEffect(() => {
    setEquippedManager(officeManagerSlots[selectedManagerIndex]?.nft);
  }, [selectedManagerIndex]);

  const loadManagerSlots = (slotsSource) => {
    let slots = [];
    let equipped = [];

    for (let i = 0; i < MAX_INVENTORY_SLOTS.managers; i++) {
      let slot = new Slot("locked", null);

      let officeSlot = slotsSource[i];
      if (officeSlot) {
        slot.status = "filled";
        slot.nft = officeSlot;
        equipped.push(officeSlot);
      } else {
        slot.status = "empty";
      }

      slots.push(slot);
    }

    console.log({ slots });
    setOfficeManagerSlots(slots);
    setSelectedManagers(equipped);
  };

  const loadDriverSlots = (slotsSource, managersEquipped) => {
    let slots = [];
    let equipped = [];

    let capacity = DEFAULT_INVENTORY_SLOTS.drivers + managersEquipped;
    console.log({ DEFAULT_INVENTORY_SLOTS, capacity, managersEquipped });

    console.log("debug-office 1", { slotsSource, capacity });
    for (let i = 0; i < MAX_INVENTORY_SLOTS.drivers; i++) {
      let slot = new Slot("locked", null);

      if (i < capacity) {
        let officeSlot = slotsSource[i];
        console.log("debug-office 2", { officeSlot, i });
        if (officeSlot) {
          slot.status = "filled";
          slot.nft = officeSlot?.nft || officeSlot;
          equipped.push(officeSlot?.nft || officeSlot);
        } else {
          slot.status = "empty";
        }
      }

      slots.push(slot);
    }

    console.log("setOfficeSlots", { slots });
    setOfficeSlots(slots);
    setSelectedDrivers(equipped);
  };

  console.log("debug-office 3", { officeSlots });
  return (
    <>
      <div
        id="backdrop"
        onClick={(event) => {
          event.preventDefault();
          if (event.target === event.currentTarget) {
            returnToLobby();
          }
        }}
      />
      <div className="inventory-container">
        <div className="inventory-details">
          <SelectedSpot
            selectedNft={equippedManager}
            id="equipped"
            type="manager"
          />
          <div className="inventory-center">
            <StatPanel
              title={"Office"}
              slots={officeSlots}
              showDriverSelector={showDriverSelector}
              officeSlotIndex={officeSlotIndex}
              selectedManager={selectedManager}
              player={player}
              selectedDrivers={selectedDrivers}
              selectedCars={Object.keys(
                ColyseusHelper.mapSchemaToArrayObject(player.garage.cars.slots)
              ).map((key) => player.garage.cars.slots[key])}
              equippedDriver={equippedDriver}
              equippedManager={equippedManager}
              equippedCar={player.selectedCar}
              equippedMechanic={player.selectedMechanic}
              onClose={returnToLobby}
              inventoryData={{
                drivers: {
                  //We convert officeSlots from array into object with slot keys
                  slots: officeSlots
                    .filter((slot) => slot.status == "filled")
                    .reduce(
                      (slotsResult, slot) => (
                        (slotsResult[
                          officeSlots.findIndex(
                            (_slot) =>
                              _slot?.nft?.asset_id == slot?.nft?.asset_id
                          )
                        ] = slot.nft),
                        slotsResult
                      ),
                      {}
                    ),
                  active: selectedDriverIndex,
                },
                managers: {
                  //We convert officeSlots from array into object with slot keys
                  slots: officeManagerSlots
                    .filter((slot) => slot.status == "filled")
                    .reduce(
                      (slotsResult, slot) => (
                        (slotsResult[
                          officeManagerSlots.findIndex(
                            (_slot) => _slot?.nft?.asset_id == slot.nft.asset_id
                          )
                        ] = slot.nft),
                        slotsResult
                      ),
                      {}
                    ),
                  active: selectedManagerIndex,
                },
              }}
              inventoryDataKeys={["managers", "drivers"]}
            />
            <UpgradeSlotsDisplay className="inventory-upgrades" />
          </div>
          <SelectedSpot
            selectedNft={equippedDriver}
            id="equipped"
            type="driver"
          />
        </div>
        <div className="inventory-slots">
          <InventorySlotsDisplay
            nftsAvailable={managersPool.length - selectedManagers.length}
            slots={officeManagerSlots}
            showNftSelector={showManagerSelector}
            container_type="inventory_left"
            setSelectedNftIndex={setSelectedManagerIndex}
            selectedNftIndex={selectedManagerIndex}
            onUnequip={onUnequipManager}
          />
          <InventorySlotsDisplay
            nftsAvailable={driverPool.length - selectedDrivers.length}
            slots={officeSlots}
            showNftSelector={showDriverSelector}
            container_type="inventory_right"
            setSelectedNftIndex={setSelectedDriverIndex}
            selectedNftIndex={selectedDriverIndex}
            onUnequip={onUnequipDriver}
          />
        </div>

        {driverSelector && (
          <NftSelectionModal
            nfts={driverPool}
            selectedNfts={selectedDrivers}
            onSelect={(driver) => {
              updateSlot(officeSlotIndex, driver);
              showDriverSelector(false);
            }}
            closeModal={() => {
              showDriverSelector(false);
            }}
          />
        )}

        {managerSelector && (
          <NftSelectionModal
            nfts={managersPool}
            selectedNfts={selectedManagers}
            onSelect={(manager) => {
              console.log("SelectManager", { manager });
              updateManagerSlot(officeManagerSlotIndex, manager);
              showManagerSelector(false);
            }}
            closeModal={() => {
              showManagerSelector(false);
            }}
          />
        )}
      </div>
    </>
  );
};
