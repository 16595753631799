import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import binx_gear_logo from "../../static/binx_gear_logo.png";

export default () => {
  const { loading, loadingText } = useContext(AppContext);

  return (
    <div
      className="loading_screen"
      style={{ display: loading ? "flex" : "none" }}
    >
      <img src={binx_gear_logo} />
      {
        //This is for conditionally loading text for processes if in case we need to add texts to loading
        loadingText == "" ? (
          <h1 id="main_loading_text">LOADING</h1>
        ) : (
          <h4 id="main_loading_text">{loadingText}</h4>
        )
      }
    </div>
  );
};
