import React, { useContext, useState, useEffect } from "react";
import { GameContext } from "../../../../../network/Connector";
import _ from "lodash";
import fillerCard from "../../../../../static/popup_filler.png";
import lockedCard from "../../../../../static/locked_popup.png";
import Modal from "../../../../atom/Modal";
import { IPFS_BASE_URL } from "../../../../../Constants";
import NftImage from "../../../../atom/NftImage";
//#region drivers

const DriverSpot = ({ showDriverSelector }) => {
  const { player } = useContext(GameContext);

  return (
    <div
      id="driver_selection_spot"
      onClick={() => {
        showDriverSelector(true);
      }}
      style={{
        height: 275,
        width: 197,
        backgroundColor: "rgba(0,0,0,1)",
        position: "absolute",
        left: 440,
        zIndex: 1,
      }}
    >
      <NftImage
        style={{ height: 275, width: 197, objectFit: "cover" }}
        src={
          player.selectedDriver == null
            ? fillerCard
            : `${IPFS_BASE_URL}${player?.selectedDriver?.img}`
        }
        hash={player?.selectedDriver?.img}
      />
    </div>
  );
};

//Driver selection

const DriverNftCard = ({ driver, className, stateText, onSelect }) => {
  return (
    <a
      onClick={() => {
        onSelect(driver);
      }}
      className={className}
    >
      <span className="state">{stateText}</span>

      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={driver == null ? fillerCard : `${IPFS_BASE_URL}${driver?.img}`}
        hash={driver?.img}
      />
    </a>
  );
};

const DriversSelectionPanel = ({ onSelect, selectedDriverId }) => {
  const { player } = useContext(GameContext);
  const allDrivers = player.office.drivers.map((slot) => slot.driver);
  //I use the term active as it is the currently active driver
  const activeDriverId = player.selectedDriver?.asset_id;

  //Selected is used for the currently clicked driver in the selection menu

  var driversSelection = allDrivers.map((driver, index) => {
    console.log("driver", driver);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    if (driver.asset_id == activeDriverId) {
      renderClass += " active";
      stateText = "Equipped";
    } else if (driver.asset_id == selectedDriverId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    return (
      <div
        className="selection_cell"
        key={"driver_selection_" + driver.asset_id}
      >
        <DriverNftCard
          driver={driver}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return <div className="selection_panel">{driversSelection}</div>;
};

const DriverSelectionModal = ({
  onSelect,
  closeModal,
  activeDriver = null,
}) => {
  const { player } = useContext(GameContext);
  const allDrivers = player.drivers;
  if (activeDriver == null) {
    activeDriver = player.selectedDriver;
  }

  const [selectedDriver, setSelectedDriver] = useState(activeDriver);

  console.log("allDrivers", allDrivers);

  return (
    <Modal
      title={"Select A Driver"}
      onClose={() => closeModal()}
      buttonLabel={"Equip Driver"}
      onClick={() => onSelect(selectedDriver)}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <div className="nft_info_display_container">
              <span className="nft_text_name">
                {selectedDriver?.name} #{selectedDriver?.asset_id}
              </span>
              <NftImage
                className="nft_img"
                style={{ width: "auto" }}
                imgClass="nft_img"
                src={
                  selectedDriver == null
                    ? fillerCard
                    : `${IPFS_BASE_URL}${selectedDriver?.img}`
                }
                hash={selectedDriver?.img}
              />
            </div>
            <div className="nft_text_container">
              {selectedDriver == null ? (
                ""
              ) : (
                <span
                  className="nft_text_stats"
                  style={{
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <div className="nft_text_item">
                    SKL: {selectedDriver?.skill}
                  </div>
                  <div className="nft_text_item">
                    STM: {selectedDriver?.stamina}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <DriversSelectionPanel
            onSelect={(newlySelectedDriver) => {
              setSelectedDriver(newlySelectedDriver);
            }}
            selectedDriverId={selectedDriver?.asset_id || ""}
          />
        </div>
      </>
    </Modal>
  );
};
//#endregion drivers

//#region office
const OfficeDriverSelectionPanel = ({
  drivers,
  activeDrivers,
  onSelect,
  selectedDriverId,
}) => {
  const { player } = useContext(GameContext);
  //Selected is used for the currently clicked driver in the selection menu

  var driversSelection = drivers.map((driver, index) => {
    console.log("driver", driver);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    if (driver.asset_id == selectedDriverId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    return (
      <div
        className="selection_cell"
        key={"driver_selection_" + driver.asset_id}
      >
        <DriverNftCard
          driver={driver}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return <div className="selection_panel">{driversSelection}</div>;
};

/**
 *
 * @param {[DerbyDriver]} options list of drivers that can be equipped to the office
 * @param {[String]} exempted list of asset_ids that are currently equipped
 * @returns
 */
const OfficeDriverSelectionModal = ({
  options,
  exempted,
  onSelect,
  closeModal,
  driverIndex,
}) => {
  const { player } = useContext(GameContext);
  const allDrivers = options.filter(
    (driver) => !exempted.includes(driver.asset_id)
  );
  console.log({ allDrivers });
  const activeDrivers = player.office.drivers.map((officeSlot) => {
    return officeSlot.driver.asset_id;
  });

  const [selectedDriver, setSelectedDriver] = useState(
    _.get(player, "office.drivers[" + driverIndex + "].driver", allDrivers[0])
  );

  console.log("allDrivers", allDrivers);

  return (
    <Modal
      title={"Select A Driver"}
      onClose={() => closeModal()}
      buttonLabel={"Equip Driver"}
      onClick={() => onSelect(selectedDriver)}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <div className="nft_info_display_container">
              <span className="nft_text_name">
                {selectedDriver?.name} #{selectedDriver?.asset_id}
              </span>
              <NftImage
                className="nft_img"
                style={{ width: "auto" }}
                imgClass="nft_img"
                src={`${IPFS_BASE_URL}${selectedDriver?.img}`}
                hash={selectedDriver?.img}
              />
            </div>
            <div className="nft_text_container">
              <span
                className="nft_text_stats"
                style={{
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div className="nft_text_item">SKL: {selectedDriver.skill}</div>
                <div className="nft_text_item">
                  STM: {selectedDriver.stamina}
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <OfficeDriverSelectionPanel
            onSelect={(newlySelectedDriver) => {
              setSelectedDriver(newlySelectedDriver);
            }}
            selectedDriverId={selectedDriver.asset_id}
            activeDrivers={activeDrivers}
            drivers={allDrivers}
          />
        </div>
      </>
    </Modal>
  );
};

//#endregion office

//#region Burns

const BurnDriverNftCard = ({ burnDriver, className, stateText, onSelect }) => {
  return (
    <a
      onClick={() => {
        onSelect(burnDriver);
      }}
      className={className}
    >
      <span className="state">{stateText}</span>

      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={
          burnDriver == null ? fillerCard : `${IPFS_BASE_URL}${burnDriver?.img}`
        }
        hash={burnDriver?.img}
      />

      <span className="burnDriver stats">
        {burnDriver.name} <br /> #{burnDriver.asset_id}
      </span>
    </a>
  );
};

const BurnDriversSelectionPanel = ({
  onSelect,
  selectedBurnDriverId,
  allBurnDrivers,
}) => {
  //Selected is used for the currently clicked burnDriver in the selection menu

  var burnDriversSelection = allBurnDrivers.map((burnDriver, index) => {
    console.log("burnDriver", burnDriver);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    if (burnDriver.asset_id == selectedBurnDriverId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    return (
      <div
        className="selection_cell"
        key={"burnDriver_selection_" + burnDriver.asset_id}
      >
        <BurnDriverNftCard
          burnDriver={burnDriver}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return <div className="selection_panel">{burnDriversSelection}</div>;
};

const BurnDriverSelectionModal = ({
  onBurn,
  closeModal,
  selectedDrivers,
  equippedManager,
}) => {
  const { player } = useContext(GameContext);

  const [selectedBurnDriver, setSelectedBurnDriver] = useState(null);
  const [burning, setBurning] = useState(false);

  let officeDrivers = selectedDrivers.map((_driver) => {
    return _driver.asset_id;
  });
  const allBurnDrivers = player.drivers.filter((_driver) => {
    return !officeDrivers.includes(_driver.asset_id);
  });

  console.log({ officeDrivers, allBurnDrivers });

  const calculateRewards = (equippedManager) => {
    if (!selectedBurnDriver) return 0;

    const rarityBonus = {
      Common: 0,
      Uncommon: 1,
      Rare: 2,
      Epic: 3,
      Legendary: 4,
      Mythic: 5,
    };

    return (
      rarityBonus[selectedBurnDriver?.rarity] +
      rarityBonus[equippedManager?.rarity] +
      1
    );
  };

  return (
    <Modal
      title={"Select A BurnDriver"}
      onClose={() => closeModal()}
      buttonLabel={burning ? "Burning..." : "Burn NFT"}
      onClick={async () => {
        if (burning) {
          alert("Please wait for current burn to finish processing...");
          return;
        }
        setBurning(true);
        await onBurn(selectedBurnDriver);

        setBurning(false);
      }}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <NftImage
              className="nft_img"
              style={{ width: "auto" }}
              imgClass="nft_img"
              src={
                selectedBurnDriver == null
                  ? fillerCard
                  : `${IPFS_BASE_URL}${selectedBurnDriver?.img}`
              }
              hash={selectedBurnDriver?.img}
            />
            <div
              className="nft_text_container"
              style={{
                visibility: selectedBurnDriver == null ? "hidden" : "visible",
              }}
            >
              <div className="nft_text_item">
                Snacks Received: {calculateRewards(equippedManager)}
              </div>
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <BurnDriversSelectionPanel
            allBurnDrivers={allBurnDrivers}
            onSelect={(newlySelectedBurnDriver) => {
              setSelectedBurnDriver(newlySelectedBurnDriver);
            }}
            selectedBurnDriverId={selectedBurnDriver?.asset_id}
          />
        </div>
      </>
    </Modal>
  );
};
//#endregion

export {
  OfficeDriverSelectionModal,
  DriverSelectionModal,
  DriversSelectionPanel,
  DriverNftCard,
  DriverSpot,
  BurnDriverSelectionModal,
};
