import React, { useContext, useState, useEffect } from "react";
import { GameContext } from "../../../../../network/Connector";
import _ from "lodash";
import fillerCard from "../../../../../static/popup_filler.png";
import lockedCard from "../../../../../static/locked_popup.png";
import { IPFS_BASE_URL } from "../../../../../Constants";
import Modal from "../../../../atom/Modal";
import NftImage from "../../../../atom/NftImage";
//#region cars

const CarSpot = ({ selectedCar = null, showCarSelector }) => {
  const { player } = useContext(GameContext);

  let displayCar = selectedCar ? selectedCar : player.selectedCar;

  return (
    <div
      id="car_selection_spot"
      onClick={() => {
        showCarSelector(true);
      }}
    >
      <NftImage
        style={{ height: "100%", width: "inherit", objectFit: "cover" }}
        src={
          displayCar == null ? fillerCard : `${IPFS_BASE_URL}${displayCar?.img}`
        }
        hash={displayCar?.img}
      />
    </div>
  );
};

//Car selection

const CarNftCard = ({ car, className, stateText, onSelect }) => {
  return (
    <a
      onClick={() => {
        onSelect(car);
      }}
      className={className}
    >
      <span className="state">{stateText}</span>

      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={car == null ? fillerCard : `${IPFS_BASE_URL}${car?.img}`}
        hash={car?.img}
      />

      <span className="car stats">
        {car.name} <br />
        Dmg: {car.damage} | Dur: {car.durability}
      </span>
    </a>
  );
};

const CarsSelectionPanel = ({ onSelect, selectedCarId }) => {
  const { player } = useContext(GameContext);
  const allCars = player.garage.cars.map((slot) => slot.car);
  //I use the term active as it is the currently active car
  const activeCarId = player.selectedCar?.asset_id;

  //Selected is used for the currently clicked car in the selection menu

  var carsSelection = allCars.map((car, index) => {
    console.log("car", car);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    if (car.asset_id == activeCarId) {
      renderClass += " active";
      stateText = "Equipped";
    } else if (car.asset_id == selectedCarId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    return (
      <div className="selection_cell" key={"car_selection_" + car.asset_id}>
        <CarNftCard
          car={car}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return <div className="selection_panel">{carsSelection}</div>;
};

const CarSelectionModal = ({ onSelect, closeModal, activeCar = null }) => {
  const { player } = useContext(GameContext);
  const allCars = player.cars;
  if (activeCar == null) {
    activeCar = player.selectedCar;
  }

  const [selectedCar, setSelectedCar] = useState(activeCar);

  console.log("allCars", allCars);

  return (
    <Modal
      title={"Select A Car"}
      onClose={() => closeModal()}
      buttonLabel={"Equip Car"}
      onClick={() => onSelect(selectedCar)}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <div className="nft_info_display_container">
              <span className="nft_text_name">
                {selectedCar?.name} #{selectedCar?.asset_id}
              </span>
              <NftImage
                className="nft_img"
                style={{ width: "auto" }}
                imgClass="nft_img"
                src={
                  selectedCar == null
                    ? fillerCard
                    : `${IPFS_BASE_URL}${selectedCar?.img}`
                }
                hash={selectedCar?.img}
              />
            </div>
            <div className="nft_text_container">
              {selectedCar == null ? (
                ""
              ) : (
                <span
                  className="nft_text_stats"
                  style={{
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <div className="nft_text_item">
                    Dmg: {selectedCar?.damage}
                  </div>
                  <div className="nft_text_item">
                    Dur: {selectedCar?.durability}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <CarsSelectionPanel
            onSelect={(newlySelectedCar) => {
              setSelectedCar(newlySelectedCar);
            }}
            selectedCarId={selectedCar?.asset_id || ""}
          />
        </div>
      </>
    </Modal>
  );
};
//#endregion cars

//#region garage
const GarageCarSelectionPanel = ({
  cars,
  activeCars,
  onSelect,
  selectedCarId,
}) => {
  const { player } = useContext(GameContext);
  //Selected is used for the currently clicked car in the selection menu

  var carsSelection = cars.map((car, index) => {
    console.log("car", car);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    /**
        var carSlot = activeCars.indexOf(car.asset_id);
        if (carSlot >= 0) {
          renderClass += " active";
          stateText = "Equipped on Slot #" + (carSlot + 1);
        } else  */
    if (car.asset_id == selectedCarId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    return (
      <div className="selection_cell" key={"car_selection_" + car.asset_id}>
        <CarNftCard
          car={car}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return <div className="selection_panel">{carsSelection}</div>;
};

/**
 *
 * @param {[DerbyCar]} options list of cars that can be equipped to the garage
 * @param {[String]} exempted list of asset_ids that are currently equipped
 * @returns
 */
const GarageCarSelectionModal = ({
  options,
  exempted,
  onSelect,
  closeModal,
  carIndex,
}) => {
  const { player } = useContext(GameContext);
  const allCars = options.filter((car) => !exempted.includes(car.asset_id));
  console.log({ allCars });
  const activeCars = player.garage.cars.map((garageSlot) => {
    return garageSlot.car.asset_id;
  });

  const [selectedCar, setSelectedCar] = useState(
    _.get(player, "garage.cars[" + carIndex + "].car", allCars[0])
  );

  console.log("allCars", allCars);

  return (
    <Modal
      title={"Select A Car"}
      onClose={() => closeModal()}
      buttonLabel={"Equip Car"}
      onClick={() => onSelect(selectedCar)}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <div className="nft_info_display_container">
              <span className="nft_text_name">
                {selectedCar?.name} #{selectedCar?.asset_id}
              </span>
              <NftImage
                className="nft_img"
                style={{ width: "auto" }}
                imgClass="nft_img"
                src={`${IPFS_BASE_URL}${selectedCar?.img}`}
                hash={selectedCar?.img}
              />
            </div>
            <div className="nft_text_container">
              <span
                className="nft_text_stats"
                style={{
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div className="nft_text_item">Dmg: {selectedCar.damage}</div>
                <div className="nft_text_item">
                  Dur: {selectedCar.durability}
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <GarageCarSelectionPanel
            onSelect={(newlySelectedCar) => {
              setSelectedCar(newlySelectedCar);
            }}
            selectedCarId={selectedCar.asset_id}
            activeCars={activeCars}
            cars={allCars}
          />
        </div>
      </>
    </Modal>
  );
};

//#endregion garage

//#region Burns

const BurnCarNftCard = ({ burnCar, className, stateText, onSelect }) => {
  return (
    <a
      onClick={() => {
        onSelect(burnCar);
      }}
      className={className}
    >
      <span className="state">{stateText}</span>

      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={burnCar == null ? fillerCard : `${IPFS_BASE_URL}${burnCar?.img}`}
        hash={burnCar?.img}
      />

      <span className="burnCar stats">
        {burnCar.name} <br /> #{burnCar.asset_id}
      </span>
    </a>
  );
};

const BurnCarsSelectionPanel = ({
  onSelect,
  selectedBurnCarId,
  allBurnCars,
}) => {
  //Selected is used for the currently clicked burnCar in the selection menu

  var burnCarsSelection = allBurnCars.map((burnCar, index) => {
    console.log("burnCar", burnCar);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    if (burnCar.asset_id == selectedBurnCarId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    return (
      <div
        className="selection_cell"
        key={"burnCar_selection_" + burnCar.asset_id}
      >
        <BurnCarNftCard
          burnCar={burnCar}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return <div className="selection_panel">{burnCarsSelection}</div>;
};

const BurnCarSelectionModal = ({
  onBurn,
  closeModal,
  selectedCars,
  equippedMechanic,
}) => {
  const { player } = useContext(GameContext);

  const [selectedBurnCar, setSelectedBurnCar] = useState(null);
  const [burning, setBurning] = useState(false);

  let garageCars = selectedCars.map((_car) => {
    return _car.asset_id;
  });
  const allBurnCars = player.cars.filter((_car) => {
    return !garageCars.includes(_car.asset_id);
  });

  console.log({ garageCars, allBurnCars });

  const calculateRewards = (equippedMechanic) => {
    if (!selectedBurnCar) return 0;

    const rarityBonus = {
      Common: 0,
      Uncommon: 1,
      Rare: 2,
      Epic: 3,
      Legendary: 4,
      Mythic: 5,
    };

    return (
      rarityBonus[selectedBurnCar?.rarity] +
      rarityBonus[equippedMechanic?.rarity] +
      1
    );
  };

  return (
    <Modal
      title={"Select A BurnCar"}
      onClose={() => closeModal()}
      buttonLabel={burning ? "Burning..." : "Burn NFT"}
      onClick={async () => {
        if (burning) {
          alert("Please wait for current burn to finish processing...");
          return;
        }
        setBurning(true);
        await onBurn(selectedBurnCar);

        setBurning(false);
      }}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <NftImage
              className="nft_img"
              style={{ width: "auto" }}
              imgClass="nft_img"
              src={
                selectedBurnCar == null
                  ? fillerCard
                  : `${IPFS_BASE_URL}${selectedBurnCar?.img}`
              }
              hash={selectedBurnCar?.img}
            />
            <div
              className="nft_text_container"
              style={{
                visibility: selectedBurnCar == null ? "hidden" : "visible",
              }}
            >
              <div className="nft_text_item">
                Parts Received: {calculateRewards(equippedMechanic)}
              </div>
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <BurnCarsSelectionPanel
            allBurnCars={allBurnCars}
            onSelect={(newlySelectedBurnCar) => {
              setSelectedBurnCar(newlySelectedBurnCar);
            }}
            selectedBurnCarId={selectedBurnCar?.asset_id}
          />
        </div>
      </>
    </Modal>
  );
};
//#endregion

export {
  GarageCarSelectionModal,
  CarSelectionModal,
  CarsSelectionPanel,
  CarNftCard,
  CarSpot,
  BurnCarSelectionModal,
};
