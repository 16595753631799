import React from "react";

import {
  lightCollisionSfxCallback,
  hardCollisionSfxCallback,
  startEngineSfxCallback,
  boostEngineSfxCallback,
  stopEngineSfxCallback,
  explodeEngineSfxCallback,
  runEngineSfxCallback,
  glassBreakSfxCallback,
  newChallengerSfxCallback
} from "../../../network/Connector";

class SfxHandler extends React.Component {
  constructor(props) {
    super(props);
  }

  playLightCollisionSfx = () => {
    console.log("debug-1 light collision");
    this.props.sfxLightCrash.play();
  };

  playHardCollisionSfx = () => {
    console.log("debug-1 hard collision");
    this.props.sfxHardCrash.play();
  };

  startEngine = () => {
    this.props.sfxStartEngine.play();
  };

  playNewChallenger = () => {
    this.props.sfxNewChallenger.play();
  };

  boostEngine = () => {
    console.log("debug-1 Boosted 1");
    this.props.sfxBoostEngine.play();
  };

  stopEngine = () => {
    this.props.sfxRollEngine.stop();
    this.props.sfxRunningEngine.stop();
  };

  explodeEngine = () => {
    console.log("exploding: ", new Date());
    this.props.sfxExplosion.play();
    this.stopEngine();
  };

  runEngine = () => {
    this.props.sfxRunningEngine.play();
  };

  breakGlass = () => {
    this.props.sfxGlassBreak.play();
  };

  componentDidMount() {
    lightCollisionSfxCallback.setListener(
      this.playLightCollisionSfx.bind(this)
    );
    hardCollisionSfxCallback.setListener(this.playHardCollisionSfx.bind(this));
    startEngineSfxCallback.setListener(this.startEngine.bind(this));
    boostEngineSfxCallback.setListener(this.boostEngine.bind(this));
    stopEngineSfxCallback.setListener(this.stopEngine.bind(this));
    explodeEngineSfxCallback.setListener(this.explodeEngine.bind(this));
    runEngineSfxCallback.setListener(this.runEngine.bind(this));
    glassBreakSfxCallback.setListener(this.breakGlass.bind(this));
    newChallengerSfxCallback.setListener(this.playNewChallenger.bind(this));
  }

  componentWillUnmount() {
    if (this.props.sfxStartEngine) {
      this.props.sfxStartEngine.stop();
    }
    if (this.props.sfxBoostEngine) {
      this.props.sfxBoostEngine.stop();
    }
    if (this.props.sfxRollEngine) {
      this.props.sfxRollEngine.stop();
    }
    if (this.props.sfxStopEngine) {
      this.props.sfxStopEngine.stop();
    }
    if (this.props.sfxLightCrash) {
      this.props.sfxLightCrash.stop();
    }
    if (this.props.sfxHardCrash) {
      this.props.sfxHardCrash.stop();
    }
    if (this.props.sfxExplosion) {
      this.props.sfxExplosion.stop();
    }
    if (this.props.sfxRunningEngine) {
      this.props.sfxRunningEngine.stop();
    }
    if (this.props.sfxGlassBreak) {
      this.props.sfxGlassBreak.stop();
    }
  }

  render() {
    return <></>;
  }
}

export default SfxHandler;
