import React, { useContext, useState, useEffect } from "react";
import { GameContext, sendMessage } from "../../../../../network/Connector";
import _ from "lodash";
import { IPFS_BASE_URL } from "../../../../../Constants";
import fillerCard from "../../../../../static/popup_filler.png";
import SelectionSpot from "./SelectionSpot";
import Modal from "../../../../atom/Modal";
import NftImage from "../../../../atom/NftImage";

//Upgrade selection

const UpgradeNftCard = ({ upgrade, className, stateText, onSelect }) => {
  return (
    <a onClick={() => onSelect(upgrade)} className={className}>
      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={`${IPFS_BASE_URL}${upgrade?.img}`}
        hash={upgrade?.img}
      />
      <span className="stats">
        +1{" "}
        {upgrade?.initiative >= 1
          ? "Skill"
          : upgrade?.durability >= 1
          ? "Durability"
          : upgrade?.damage >= 1
          ? "Damage"
          : upgrade?.luck >= 1
          ? "Luck"
          : ""}
      </span>
    </a>
  );
};

const UpgradesSelectionPanel = ({
  onSelect,
  upgrades,
  activeUpgrades,
  selectedUpgradeId,
}) => {
  //Selected is used for the currently clicked upgrade in the selection menu

  var upgradesSelection = upgrades
    .filter((upgrade) => !activeUpgrades.includes(upgrade?.asset_id))
    .map((upgrade) => {
      console.log("upgrade", upgrade);

      //default classes to be added here
      var renderClass = "btn";
      var stateText = "";

      var upgradeSlot = activeUpgrades.indexOf(upgrade?.asset_id);
      if (upgradeSlot >= 0) {
        renderClass += " active";
        stateText = "Equipped on Slot #" + (upgradeSlot + 1);
      } else if (upgrade?.asset_id == selectedUpgradeId) {
        renderClass += " selected";
        stateText = "Selected";
      }

      return (
        <div
          className="selection_cell"
          key={"upgrade_selection_" + upgrade?.asset_id}
        >
          <UpgradeNftCard
            upgrade={upgrade}
            className={renderClass}
            onSelect={onSelect}
            stateText={stateText}
          />
        </div>
      );
    });

  return <div className="selection_panel">{upgradesSelection}</div>;
};

const UpgradeSelectionModal = ({ onSelect, closeModal, upgradeIndex }) => {
  const { player } = useContext(GameContext);
  const allUpgrades = player.upgrades;

  const [selectedUpgrade, setSelectedUpgrade] = useState(
    _.get(player, "selectedPup" + upgradeIndex, allUpgrades[0])
  );

  console.log("allUpgrades", allUpgrades);

  return (
    <Modal
      title={"Select An Upgrade"}
      onClose={() => closeModal()}
      buttonLabel={"Equip Upgrade"}
      onClick={() => onSelect(selectedUpgrade)}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <NftImage
              className="nft_img"
              style={{ width: "auto" }}
              imgClass="nft_img"
              src={`${IPFS_BASE_URL}${selectedUpgrade?.img}`}
              hash={selectedUpgrade?.img}
            />
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <UpgradesSelectionPanel
            upgrades={player.upgrades}
            activeUpgrades={player.equippedPups}
            onSelect={(newlySelectedUpgrade) => {
              //Check first if this item is already equipped

              var upgradeSlot = player.equippedPups.indexOf(
                newlySelectedUpgrade?.asset_id
              );
              if (upgradeSlot >= 0) {
                alert("Please select an unequipped upgrade");
                return;
              }

              setSelectedUpgrade(newlySelectedUpgrade);
            }}
            selectedUpgradeId={selectedUpgrade?.asset_id}
          />
        </div>
      </>
    </Modal>
  );
};

const UpgradeSlotsDisplay = ({ className }) => {
  const { room, player } = useContext(GameContext);
  const [pupIndex, setPupIndex] = useState(false);
  const [pupModalSwitch, setPupModalSwitch] = useState(false);

  const showPupSelector = (bool) => setPupModalSwitch(bool);
  return (
    <>
      {pupModalSwitch && (
        <UpgradeSelectionModal
          onSelect={(pup) => {
            sendMessage(() => {
              room.send("setCurrentPup", {
                pupNum: pupIndex,
                pup: pup,
              });
            });
            showPupSelector(false);
          }}
          closeModal={() => {
            showPupSelector(false);
          }}
          upgradeIndex={pupIndex}
        />
      )}

      <div className={"main_lobby_upgrades " + className}>
        <SelectionSpot
          type="Upgrade"
          showSelector={(selectorState) => {
            if (player["selectedPup1"] != null) {
              let confirmed = window.confirm(
                "Are you sure that you want to unequip this upgrade?"
              );
              if (confirmed)
                sendMessage(() => {
                  room.send("unequipUpgrade", {
                    pupNum: 1,
                  });
                });
            } else {
              if (player.upgrades.length <= 0) {
                alert(
                  "You don't have any powerups! Please buy some at atomic hub!"
                );
                return;
              }

              setPupIndex(1);
              showPupSelector(selectorState);
            }
          }}
          selectionObject="selectedPup1"
        />
        <SelectionSpot
          type="Upgrade"
          showSelector={(selectorState) => {
            if (player["selectedPup2"] != null) {
              let confirmed = window.confirm(
                "Are you sure that you want to unequip this upgrade?"
              );
              if (confirmed)
                sendMessage(() => {
                  room.send("unequipUpgrade", {
                    pupNum: 2,
                  });
                });
            } else {
              if (player.upgrades.length <= 0) {
                alert(
                  "You don't have any powerups! Please buy some at atomic hub!"
                );
                return;
              }

              setPupIndex(2);
              showPupSelector(selectorState);
            }
          }}
          selectionObject="selectedPup2"
        />
        <SelectionSpot
          type="Upgrade"
          showSelector={(selectorState) => {
            if (player["selectedPup3"] != null) {
              let confirmed = window.confirm(
                "Are you sure that you want to unequip this upgrade?"
              );
              if (confirmed)
                sendMessage(() => {
                  room.send("unequipUpgrade", {
                    pupNum: 3,
                  });
                });
            } else {
              if (player.upgrades.length <= 0) {
                alert(
                  "You don't have any powerups! Please buy some at atomic hub!"
                );
                return;
              }

              setPupIndex(3);
              showPupSelector(selectorState);
            }
          }}
          selectionObject="selectedPup3"
        />

        <SelectionSpot
          type="Upgrade"
          showSelector={(selectorState) => {
            if (player["selectedPup4"] != null) {
              let confirmed = window.confirm(
                "Are you sure that you want to unequip this upgrade?"
              );
              if (confirmed)
                sendMessage(() => {
                  room.send("unequipUpgrade", {
                    pupNum: 4,
                  });
                });
            } else {
              if (player.upgrades.length <= 0) {
                alert(
                  "You don't have any powerups! Please buy some at atomic hub!"
                );
                return;
              }

              setPupIndex(4);
              showPupSelector(selectorState);
            }
          }}
          selectionObject="selectedPup4"
        />
      </div>
    </>
  );
};

export {
  UpgradeSlotsDisplay,
  UpgradeSelectionModal,
  UpgradesSelectionPanel,
  UpgradeNftCard,
};
