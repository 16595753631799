import React from "react";
import Loading from "./sub/Loading";
import Stage from "./sub/Stage";
import App from "./AppContext";
export default ({ ual }) => {
  return (
    <div id="Main">
      <App>
        <Loading />
        <Stage ual={ual}></Stage>
      </App>
    </div>
  );
};
