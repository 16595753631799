class ColyseusHelper {
    /**
     * 
     * @param {MapSchema} _mapSchema Colyseus Map Schema
     * @returns Plain Object || Original value if fails checks
     * @description Converts map schema to an array object (Object with sequence of numbers as keys)
     */
    mapSchemaToArrayObject = (_mapSchema) => {
        //Check if this is actually an object, if not, return itself
        if(typeof _mapSchema !== "object"){
            return _mapSchema;
        }

        //Check if this is not a mapSchema, if not, return itself you an object already
        if(!Object.keys(_mapSchema).includes("$items")){
            return _mapSchema;
        }

        //This is a mapSchema, convert to plain object
        let _map = {};
        let _index = 0;
        _mapSchema.forEach(_mapAttr => {
            _map[_index++] = _mapAttr;
        })
        return _map;
    }
    
}


export default new ColyseusHelper();