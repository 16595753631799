import Long from "long";

class EosHelper {
  constructor() {
    this.charmap = ".12345abcdefghijklmnopqrstuvwxyz";
  }

  charidx = (ch) => {
    const idx = this.charmap.indexOf(ch);
    if (idx === -1) throw new TypeError(`Invalid character: '${ch}'`);

    return idx;
  };

  bytesToHex = (bytes) => {
    let leHex = "";
    for (const b of bytes) {
      const n = Number(b).toString(16);
      leHex += (n.length === 1 ? "0" : "") + n;
    }
    return leHex;
  };

  getTableBoundsForName = (name, asLittleEndianHex = true) => {
    const nameValue = this.nameToValue(name);
    const nameValueP1 = nameValue.add(1);

    if (!asLittleEndianHex) {
      return {
        lower_bound: nameValue.toString(),
        upper_bound: nameValueP1.toString(),
      };
    }

    const lowerBound = this.bytesToHex(nameValue.toBytesLE());
    const upperBound = this.bytesToHex(nameValueP1.toBytesLE());

    return {
      lower_bound: lowerBound,
      upper_bound: upperBound,
    };
  };

  nameToValue = (name) => {
    if (typeof name !== "string")
      throw new TypeError("name parameter is a required string");

    if (name.length > 12)
      throw new TypeError("A name can be up to 12 characters long");

    let bitstr = "";
    for (let i = 0; i <= 12; i++) {
      // process all 64 bits (even if name is short)
      const c = i < name.length ? this.charidx(name[i]) : 0;
      const bitlen = i < 12 ? 5 : 4;
      let bits = Number(c).toString(2);
      if (bits.length > bitlen) {
        throw new TypeError("Invalid name " + name);
      }
      bits = "0".repeat(bitlen - bits.length) + bits;
      bitstr += bits;
    }

    return Long.fromString(bitstr, true, 2);
  };

  /** Original Name encode and decode logic is in github.com/eosio/eos  native.hpp */

  /**
  Encode a name (a base32 string) to a number.
  For performance reasons, the blockchain uses the numerical encoding of strings
  for very common types like account names.
  @see types.hpp string_to_name
  @arg {string} name - A string to encode, up to 12 characters long.
  @return {string<UInt64>} - compressed string (from name arg).  A string is
    always used because a number could exceed JavaScript's 52 bit limit.
*/
  encodeName = (name, littleEndian = true) => {
    if (typeof name !== "string")
      throw new TypeError("name parameter is a required string");

    if (name.length > 13)
      throw new TypeError("A name can be up to 13 characters long");

    let bitstr = "";
    for (let i = 0; i <= 12; i++) {
      // process all 64 bits (even if name is short)
      const c = i < name.length ? this.charidx(name[i]) : 0;
      const bitlen = i < 12 ? 5 : 4;
      let bits = Number(c).toString(2);
      if (bits.length > bitlen) {
        throw new TypeError("Invalid name " + name);
      }
      bits = "0".repeat(bitlen - bits.length) + bits;
      bitstr += bits;
    }

    const value = Long.fromString(bitstr, true, 2);

    // convert to LITTLE_ENDIAN
    let leHex = "";
    const bytes = littleEndian ? value.toBytesLE() : value.toBytesBE();
    for (const b of bytes) {
      const n = Number(b).toString(16);
      leHex += (n.length === 1 ? "0" : "") + n;
    }

    const ulName = Long.fromString(leHex, true, 16).toString();

    // console.log('encodeName', name, value.toString(), ulName.toString(), JSON.stringify(bitstr.split(/(.....)/).slice(1)))

    return ulName.toString();
  };
}

var eosHelper = new EosHelper();

export default eosHelper;
