import React from "react";
import { LongButton } from "./Button";
import "./modal.css";

export default ({
  className = "",
  children,
  style,
  onClose,
  title,
  buttonLabel,
  buttonDisabled,
  onClick,
  autoHeight,
  modalStyle = {},
  closeDisabled = false,
}) => (
  <div
    className={"modal-container"}
    onClick={(event) => {
      event.preventDefault();
      if (event.target === event.currentTarget) {
        if (!closeDisabled) onClose();
      }
    }}
  >
    <div
      className={"modal " + className}
      style={{
        height: autoHeight && "auto",

        ...modalStyle,
      }}
    >
      <div className="modal-title">
        {title}
        <button className="modal-close" onClick={() => {
          if (!closeDisabled) onClose(); 
        }}>
          x
        </button>
      </div>
      <div className={"modal-content"} style={style}>
        {children}
      </div>
      {buttonLabel && (
        <LongButton
          onClick={onClick}
          className="modal-button"
          disabled={buttonDisabled}
        >
          {buttonLabel}
        </LongButton>
      )}
    </div>
  </div>
);
