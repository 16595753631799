import { AppContext } from "../../../AppContext.js";
import React, { useContext, useState, useEffect } from "react";
import Exchange from "./Exchange";
import Main from "./Main";
import Garage from "./Garage";
import Office from "./Office";
import Market from "./Market";
import "./lobbyStyles.css";
import "./Inventory/inventoryStyles.css";

export default () => {
  const { activeMenu } = useContext(AppContext);

  const getScreen = () => {
    if (activeMenu === "Main") return <Main />;
    if (activeMenu === "Garage") return <Garage />;
    if (activeMenu === "Office") return <Office />;
    if (activeMenu === "Exchange") return <Exchange />;
    if (activeMenu === "Market") return <Market />;

    console.log("activeMenu", activeMenu);
    return <div>Loading...</div>;
  };

  return <div className="container">{getScreen()}</div>;
};
