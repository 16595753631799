import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  GameContext,
  createMarketPurchaseActions,
  SignTransaction,
  sendMessage,
} from "../../../../../network/Connector";
import { IPFS_BASE_URL } from "../../../../../Constants";
import { ATOMIC_HUB_LINK } from "../../../../../Constants";
import { LongButton } from "../../../../atom/Button";
import NftImage from "../../../../atom/NftImage";
import tokenIcon from "../../../../../static/icons/Token.png";
import trophyIcon from "../../../../../static/icons/Skill.png";

import ReactTooltip from "react-tooltip";

const MarketTooltip = (props) => {

  const [content, setContent] = useState("");
  const [contentTitle, setContentTitle] = useState("");

  useEffect(() => {
    const _content = props.description.split("*");
    const _contentTitle = _content.shift();

    setContent(_content)
    setContentTitle(_contentTitle)

  }, [props.description])

  return (
    <ReactTooltip
      className="tooltip"
      place="top"
      id={"tooltip_" + props.template_id}
    >
      <p>{contentTitle}</p>
      {content.length > 0 && (
        <ul>
          {content.map((_c) => (
            <li>{_c}</li>
          ))}
        </ul>
      )}
    </ReactTooltip>
  );
};

export default function MarketItem(props) {
  const { player, room, ual } = useContext(GameContext);

  const buyItem = useMemo(() => {
    return async () => {
      try {
        if (player.trophyAmount < props.trophy_price) {
          alert("Not enough Trophies");
          return;
        }
        if (parseFloat(player.tokenAmount.split(" ")[0]) < props.token_price) {
          alert("Not enough DERBY");
          return;
        }

        console.log("[buyItem]", {
          trophyAmount: player.trophyAmount,
          tokenAmount: player.tokenAmount,
          trophyPrice: props.trophy_price,
          tokenPrice: props.token_price,
        });
        const actions = createMarketPurchaseActions(
          ual.activeUser.accountName,
          props.template_id
        );

        const success = await SignTransaction(actions);
        if (success) {
          sendMessage(() => {
            room.send("marketPurchase", {
              template_id: props.template_id,
              schema_name: props.schema_name,
            });
          });
        }
      } catch (error) {
        console.error({ error });
        alert(error);
      }
    };
  }, [props.token_price, props.trophy_price]);

  return (
    <div className="market_cell">
      <a
        className="market_name column-centered"
        target="_blank"
        href={`${ATOMIC_HUB_LINK}/explorer/template/${props.collection_name}/${props.template_id}`}
      >
        {props.name}
      </a>

      <NftImage
        className="market_img"
        src={`${IPFS_BASE_URL}${props?.img}`}
        hash={props?.img}
        style={{
          backgroundPositionX: "center",
        }}
        imgStyle={{
          height: "100%",
          width: "auto",
        }}
        data-tip={props.template_id}
        data-for={"tooltip_" + props.template_id}
      />

      <div className="market_left">
        {props?.remaining} Left
        <br />
      </div>

      <LongButton className="market_button" onClick={buyItem}>
        Buy {"  "}
        <span
          style={{
            marginLeft: 5,
          }}
        >
          {props?.token_price > 0 && (
            <>
              <img src={tokenIcon} style={{ width: 20 }} />
              {` ${props?.token_price.toFixed(4)}`}
            </>
          )}
          {props?.trophy_price > 0 && (
            <>
              {
                //This is to add a bit of space between token and trophy price
                props?.token_price > 0 && " "
              }
              <img src={trophyIcon} style={{ width: 20 }} />
              {` ${props?.trophy_price}`}
            </>
          )}
        </span>
      </LongButton>

      <MarketTooltip template_id={props.template_id} description={props.description}></MarketTooltip>
    </div>
  );
}
