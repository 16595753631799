import React, { useEffect, useState } from "react";
import fallback from "../../static/nft_placeholder.png";
import loadingImg from "../../static/loading_card.png";
import { IPFS_ENDPOINTS } from "../../Constants.js";

/**
 *
 * @param {Prop} props
 * @returns Image
 * @description handles fallback image in cases where nft images fail to load
 * @requires props.hash used to determine hash when switching IPFS endpoints
 */
export default function NftImage(props) {
  const [imgSrc, setImgSrc] = useState(props.src);
  const [oldImgSrc, setOldImgSrc] = useState(props.src);
  const [ipfsIndex, setIpfsIndex] = useState(0);

  const onError = () => {
    console.log("onError", { fallback, imgSrc });
    setIpfsIndex(ipfsIndex + 1);
  };

  const ipfsImageSrc = () => {
    const endpoint = IPFS_ENDPOINTS[ipfsIndex];

    //Default to src if no hash
    if (!props?.hash) {
      console.warn("Hash not found");
      return oldImgSrc;
    }

    return endpoint.replace("%IPFS_HASH%", props.hash);
  };

  useEffect(() => {
    if (ipfsIndex >= IPFS_ENDPOINTS.length) setImgSrc(fallback);
    else setImgSrc(ipfsImageSrc());
  }, [ipfsIndex]);

  useEffect(() => {
    if (oldImgSrc !== props.src) {
      setOldImgSrc(props.src);
      setImgSrc(props.src);
      setIpfsIndex(0);
    }
  }, [props.src]);

  return (
    <div
      {...props}
      style={{
        ...props?.style,
        backgroundSize: "contain",
        backgroundImage: `url(${loadingImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <img
        {...props}
        src={imgSrc}
        className={props.imgClass || ""}
        onError={onError}
        style={{
          margin: 0,
          ...props?.style,
          width: props?.style?.width || "100%",
          objectFit: props?.style?.objectFit || "contain",
          ...props?.imgStyle,
        }}
      />
    </div>
  );
}
