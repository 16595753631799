import React, { useEffect, useState, useContext } from "react";
import logo from "../../../static/logo.png";
import logoutBtn from "../../../static/logout.png";
import background from "../../../static/background.png";
import Button from "../../atom/Button";
import Connector, {
  setUAL,
} from "../../../network/Connector";

import { AppContext } from "../../AppContext";
import "./CenterConsole.css";
import { EnergyDrinkModal } from "./EnergyDrinkModal";
import { CarPartModal } from "./CarPartModal";
import { ClosedWrapper } from "./ClosedWrapper";

export default ({
  ual,
  energyDrinkModalSwitch,
  closeDrinkModal,
  carPartModalSwitch,
  closePartModal,
  processingBurn,
}) => {
  const { ShowLoading } = useContext(AppContext);

  const [timeout, settimeout] = useState(null);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;

    var countdown = setTimeout(() => {
      console.log("No user logged in");
      ShowLoading(false);
    }, 5000);

    settimeout(countdown);
  }, []);

  useEffect(() => {
    if (ual.activeUser) {
      ShowLoading(true, "Logging in...");
      console.log("A user is logged in, please wait...");
      clearTimeout(timeout);
      setUAL(ual);
    }
  }, [ual.activeUser]);

  const logout = () => {
    var confirmed = confirm("Are you sure that you want to log out?");
    if (confirmed) {
      ual.logout();
      window.location.reload();
    }
  };

  return (
    <div id="center_console_root">
      {!ual.activeUser && (
        <div className="center_login">
          <img src={logo} />
          <Button onClick={ual.showModal}>Login</Button>
        </div>
      )}

      {ual.activeUser && (
        <Connector
          ual={ual}
          eosioName={ual.activeUser.accountName}
          connectedCallback={() => {
            ShowLoading(false);
          }}
          notConnectedCallback={() => {
            ShowLoading(false);
          }}
        >
          <ClosedWrapper />
          <EnergyDrinkModal
            energyDrinkModalSwitch={energyDrinkModalSwitch}
            closeDrinkModal={closeDrinkModal}
            processingBurn={processingBurn}
          />
          <CarPartModal
            carPartModalSwitch={carPartModalSwitch}
            closePartModal={closePartModal}
            processingBurn={processingBurn}
          />
          <img id="logout-btn" src={logoutBtn} onClick={logout} />
        </Connector>
      )}
    </div>
  );
};
