import React from "react";
import MessageFrame from "../../MessageFrame";
import { DERBY_TOKEN_DISPLAY } from "../../../../Constants";

export function ClaimButton({ onClaim, coinRewardsText, trophyAmount }) {
  console.log({ onClaim, coinRewardsText });

  return (
    <MessageFrame
      onClick={onClaim}
      maintext={"CLAIM"}
      subtext={
        <>
          <span>
            {parseFloat(coinRewardsText).toFixed(4)} {DERBY_TOKEN_DISPLAY}
          </span>{" "}
          {trophyAmount > 0 && <span style={{
            display:"block",
            marginTop: -4
          }}>
            {trophyAmount} TROPHIES
          </span>}
        </>
      }
    />
  );
}
