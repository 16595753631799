import React from "react";
import { damagedCallback } from "../../../network/Connector";
import DamageText from "./DamageText.jsx";

class DamageDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      damage_displays: [],
    };
  }

  showDamage = (damage) => {
    const { damage_displays } = this.state;
    console.log("debug-1 showDamage called");
    //Set time as key
    this.setState({
      damage_displays: [
        ...damage_displays,
        {
          time: new Date().getTime(),
          damage,
        },
      ],
    });
  };

  cleanUp = (time) => {
    const { damage_displays } = this.state;

    const displayIndex = damage_displays.findIndex((display) => {
      return display.time == time;
    });

    damage_displays.splice(displayIndex, 1);

    this.setState({
      damage_displays,
    });
  };

  componentDidMount() {
    damagedCallback.setListener(this.showDamage.bind(this));
  }

  render() {
    const { damage_displays } = this.state;

    const damageTexts = damage_displays.map((display) => {
      return (
        <DamageText
          damage={display.damage}
          key={"damage_text_" + display.time}
          addedAt={display.time}
          onExpire={this.cleanUp}
        />
      );
    });

    return <>{damageTexts}</>;
  }
}

export default DamageDisplay;
