import React, { useContext, useState, useEffect } from "react";
import {
  GameContext,
  attemptCloseGame,
  createClaimNftsAction,
  sendMessage,
} from "../../../../../network/Connector";
import staticBag from "../../../../../static/static_bag.png";
import fillerCard from "../../../../../static/popup_filler.png";
import trophyImage from "../../../../../static/trophy.png";
import garageIcon from "../../../../../static/icons/garage-icon.png";
import exchangeIcon from "../../../../../static/icons/exchange-icon.png";
import marketIcon from "../../../../../static/icons/market-icon.png";
import officeIcon from "../../../../../static/icons/office-icon.png";
import closeBtn from "../../../../../static/cftn.png";
import processingClaimImage from "../../../../../static/processing-claim.png";
import TimerFrame from "../../../TimerFrame";
import ImageButton from "../../../../atom/ImageButton";
import { LongButton } from "../../../../atom/Button";
import * as Colyseus from "colyseus.js";
import "./../../components/selectionStyles.css";
import _ from "lodash";
import { AppContext } from "../../../../AppContext.js";
import SelectionSpot from "../components/SelectionSpot.jsx";
import { CarSelectionModal, CarSpot } from "./../components/CarComponents.jsx";
import { DriverSelectionModal } from "./../components/DriverComponents.jsx";
import { UpgradeSlotsDisplay } from "./../components/UpgradeComponents.jsx";
import ClaimModal from "../../components/ClaimModal.jsx";
import ActionButton from "./ActionButton";
import "./MainLobby.css";
import CacheHelper from "../../../../../utils/CacheHelper";

export default () => {
  /**
   * @type {{room:Colyseus.Room}}
   */
  const { room, player } = useContext(GameContext);
  const { switchMenu, ShowLoading } = useContext(AppContext);
  window.player = player;
  const [carSelector, setCarSelector] = useState(false);
  const showCarSelector = (bool) => {
    if (player.cars.length <= 0) {
      alert("You don't have any cars! Please buy some at atomic hub!");
      return;
    }
    setCarSelector(bool);
  };

  //Driver modal switches
  const [driverModalSwitch, setDriverModalSwitch] = useState(false);
  const showDriverSelector = (bool) => {
    if (player.drivers.length <= 0) {
      alert("You don't have any drivers! Please buy some at atomic hub!");
      return;
    }

    setDriverModalSwitch(bool);
  };

  //Upgrade modal switches
  const [joining, setJoining] = useState(false);
  const [joins, setJoins] = useState(
    CacheHelper.loadFromLocal("JOINS", 1, "int")
  );

  //Claim modal switches
  const [claimModalSwitch, setClaimModalSwitch] = useState(false);
  const showClaimModal = (bool) => setClaimModalSwitch(bool);

  const closeForDay = () => {
    console.log("Close for the day");
    if (room.state.staminaConsumption > player.energy.stamina) {
      alert(
        "You need can gain stamina and durability by burning energy drinks and car parts."
      );
    }

    attemptCloseGame(room, "Do you want to close for the night?");
    return;
  };

  const joinGame = () => {
    //Check if energies are sufficient
    console.log({
      staminaConsumption: room.state.staminaConsumption,
      stamina: player.energy.stamina,
      durabilityConsumption: room.state.durabilityConsumption,
      durability: player.energy.durability,
    });

    if (!player.selectedCar) {
      alert("Please equip a car before joining!");
      return false;
    }

    if (!player.selectedDriver) {
      alert("Please equip a driver before joining!");
      return false;
    }

    if (room.state.durabilityConsumption > player.energy.durability) {
      alert(
        "You need " +
          room.state.durabilityConsumption +
          " durability to enter a game. Gain durability by burning parts."
      );
      attemptCloseGame(room, "Do you want to close for the night?");
      return;
    }

    if (room.state.staminaConsumption > player.energy.stamina) {
      alert(
        "You need " +
          room.state.staminaConsumption +
          " stamina to enter a game. Gain stamina by burning coffee."
      );
      attemptCloseGame(room, "Do you want to close for the night?");
      return;
    }

    setJoining(true);
    //Let's use autojoin for all for now
    sendMessage(() => {
      room.send("autoJoin", {
        entries: player?.selectedManager != null ? joins : 1,
      });
    });

    return true;
  };

  const openExchange = async () => {
    switchMenu("Exchange");
  };

  const openGarage = async () => {
    switchMenu("Garage");
  };

  const openOffice = async () => {
    switchMenu("Office");
  };

  const openMarket = async () => {
    switchMenu("Market");
  };

  const ClaimBnx = async () => {
    console.log("ClaimBnx");
    showClaimModal(true);
  };

  useEffect(() => {
    console.log("debug-1", {
      derby: player.rewards.claimable_derby,
      nfts: player.rewards.claimable_nfts.length,
      claiming: player.rewards.is_claiming,
    });
  }, []);

  /**
   * Disabled processing overlay for now
  useEffect(() => {
    console.log({ rewards: player.rewards });
    if (player.rewards.is_claiming) {
      setTimeout(() => {
        ShowLoading(true, "Processing Claim....");
      }, 100);
    }
  }, [player.rewards.is_claiming]); 
  */

  /**
   * 
  useEffect(() => {
    setTimeout(() => {
      joinGame();
    }, 5000);
  }, []);
   */

  return (
    <>
      <div className="container">
        <ActionButton
          onClaim={ClaimBnx}
          onClaimNfts={ClaimBnx}
          onJoin={joinGame}
          onClose={closeForDay}
          joining={joining}
          joins={joins}
          setJoins={setJoins}
          setJoining={setJoining}
          player={player}
        />
        <div className="main_lobby row-centered">
          <div className="side_buttons">
            <LongButton onClick={openMarket} icon={marketIcon}>
              Market
            </LongButton>
            <LongButton onClick={openExchange} icon={exchangeIcon}>
              Exchange
            </LongButton>
          </div>
          <MaintainFrame
            showCarSelector={openGarage}
            showDriverSelector={openOffice}
          />
          <div className="side_buttons">
            <LongButton onClick={openGarage} icon={garageIcon}>
              Garage
            </LongButton>
            <LongButton onClick={openOffice} icon={officeIcon}>
              Office
            </LongButton>
          </div>
          {carSelector && (
            <CarSelectionModal
              onSelect={(car) => {
                sendMessage(() => {
                  room.send("setCurrentCar", car);
                });
                showCarSelector(false);
              }}
              closeModal={() => {
                showCarSelector(false);
              }}
            />
          )}
          {driverModalSwitch && (
            <DriverSelectionModal
              onSelect={(driver) => {
                sendMessage(() => {
                  room.send("setCurrentDriver", driver);
                });
                showDriverSelector(false);
              }}
              closeModal={() => {
                showDriverSelector(false);
              }}
            />
          )}
          {claimModalSwitch &&
            (player.rewards.claimable_derby > 0 ||
              player.rewards.on_chain_nfts.length > 0 ||
              player.rewards.claimable_nfts.length > 0) && (
              <ClaimModal
                onSelect={(nft) => {
                  console.log("reward selected", nft);
                }}
                closeModal={() => {
                  setClaimModalSwitch(false);
                }}
              />
            )}
        </div>
      </div>
      <ClosedButton />
    </>
  );
};

const ClosedButton = () => {
  const { room, player } = useContext(GameContext);

  return (
    <div className="close-container">
      {player?.flag?.state == "Open" && (
        <ImageButton onClick={() => attemptCloseGame(room)} src={closeBtn} />
      )}
    </div>
  );
};

const MaintainFrame = ({
  showCarSelector,
  showDriverSelector,
  showPupSelector,
  setPupIndex,
}) => {
  const { player } = useContext(GameContext);

  return (
    <div className="main_lobby_center row-centered">
      <SelectionSpot
        className="selection_large"
        type="Driver"
        showSelector={showDriverSelector}
        selectionObject="selectedDriver"
      />
      <CarSpot showCarSelector={showCarSelector} />
      <UpgradeSlotsDisplay />
    </div>
  );
};
