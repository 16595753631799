import React, { useContext } from "react";
import {
  createBurnAction,
  SignTransaction,
  GameContext, sendMessage,
  nftBurnedCallback
} from "../../../network/Connector";
import { ConsumablesSelectionModal } from "../GameScreen/LobbyScreens/components/EnergyComponents";

export function CarPartModal({ carPartModalSwitch, closePartModal, processingBurn }) {
  const { room, ual, } = useContext(GameContext);

  return (
    <>
      {carPartModalSwitch && (
        <ConsumablesSelectionModal
          onBurn={async (parts) => {
            const actions = Array.from(parts).map((asset_id) => {
              return createBurnAction(
                ual.activeUser.accountName,
                parseInt(asset_id)
              );
            });

            console.log("debug-1 actions", actions);
            const success = await SignTransaction(actions);
            if (success) {
              console.log("d-1", { room });
              //parts = key, energytest = schema_name
              sendMessage(() => {
                setTimeout(() => {
                  room.send("refreshNfts", {
                    key: "parts",
                    schema: "repairtest",
                  });
                }, 5000);
              });
              nftBurnedCallback.call();
              console.log("[debug-nft-burn] nft burned");
              //closePartModal();
            } else {
              alert("Burn part failed!");
            }
          }}
          closeModal={() => {
            if (processingBurn) {
              alert("Processing burn...");
              return;
            }
            closePartModal();
          }}
          poolKey="parts"
          valueKey="repair"
          processingBurn={processingBurn} />
      )}
    </>
  );
}
