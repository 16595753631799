import React from "react";
import { render } from "react-dom";
import Main from "./components/Main";
import MainLogin from "./components/MainLogin";
import "./index.css";

const GameApp = () => {
  return <MainLogin main={Main}></MainLogin>;
};

render(<GameApp />, document.getElementById("main"));
