import React, { useContext, useState, useEffect } from "react";
import { GameContext } from "../../../../../network/Connector";
import _, { forIn } from "lodash";
import fillerCard from "../../../../../static/popup_filler.png";
import Modal from "../../../../atom/Modal";
import { IPFS_BASE_URL } from "../../../../../Constants";
import NftImage from "../../../../atom/NftImage";

const ConsumableNftCard = ({
  asset,
  regainValue,
  regainText = "Repair",
  className,
  stateText,
  onSelect,
}) => {
  return (
    <a
      onClick={() => {
        onSelect(asset);
      }}
      className={className}
    >
      <span className="state">{stateText}</span>

      <NftImage
        className="selection_img"
        imgClass="selection_img"
        // style={{ maxWidth: "calc(100% / 3)", objectFit: "contain" }}
        src={asset == null ? fillerCard : `${IPFS_BASE_URL}${asset?.img}`}
        hash={asset?.img}
      />

      <span className="carPart stats">
        {asset.name} <br /> +{regainValue} {regainText}
      </span>
    </a>
  );
};

const ConsumablesSelectionPanel = ({
  onSelect,
  selectedNfts,
  poolKey = "drinks",
  valueKey = "energy",
}) => {
  const { player } = useContext(GameContext);
  const consumables = player[poolKey];

  //Selected is used for the currently clicked energyDrink in the selection menu

  var consumablesSelection = consumables.map((nft, index) => {
    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";
    if (selectedNfts.has(nft.asset_id)) {
      renderClass += " selected";
      stateText = `Selected`;
    }

    return (
      <div
        className="selection_cell"
        key={"energyDrink_selection_" + nft.asset_id}
      >
        <ConsumableNftCard
          asset={nft}
          regainText="Energy"
          regainValue={nft[valueKey]}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
        />
      </div>
    );
  });

  return (
    <div
      className="selection_panel"
      style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
    >
      {consumablesSelection}
    </div>
  );
};

const ConsumablesSelectionModal = ({
  onBurn,
  closeModal,
  processingBurn,
  poolKey = "drinks",
  valueKey = "energy",
}) => {
  const { player } = useContext(GameContext);
  const consumables = player[poolKey];

  const [burning, setBurning] = useState(false);

  const [selectedNfts, setSelectedNfts] = useState(() => new Set());
  const [burnStack, setBurnStack] = useState({});

  const [totalRecovered, setTotalRecovered] = useState(0);

  const selectNft = (asset) => {
    setSelectedNfts((prev) => {
      let newSet = new Set(prev);

      if (newSet.has(asset.asset_id)) {
        newSet.delete(asset.asset_id);
        burnStack[asset.name].burned--;
        setTotalRecovered((_recovered) => _recovered - asset[valueKey]);
      } else {
        newSet.add(asset.asset_id);
        burnStack[asset.name].burned++;
        setTotalRecovered((_recovered) => _recovered + asset[valueKey]);
      }

      return newSet;
    });
  };

  const BurnPreview = () => {
    let preview = [];

    for (const schemaName in burnStack) {
      if (Object.hasOwnProperty.call(burnStack, schemaName)) {
        const _drink = burnStack[schemaName];

        if (_drink.burned > 0)
          preview.push(
            <>
              <NftImage
                className="selection_img selection_img_smaller"
                src={
                  _drink == null ? fillerCard : `${IPFS_BASE_URL}${_drink?.img}`
                }
                hash={_drink?.img}
                imgStyle={{
                  height: "100%",
                }}
              />
              <span>x{_drink.burned}</span>
            </>
          );
      }
    }

    return preview;
  };

  useEffect(() => {
    if (processingBurn == false) {
      setSelectedNfts(() => new Set());
      setTotalRecovered(0);

      //Reset drinks to burn
      let _burnStack = {};

      consumables.forEach((_nft) => {
        if (!_burnStack[_nft.name]) {
          _burnStack[_nft.name] = {};
          _burnStack[_nft.name].img = _nft.img;
          _burnStack[_nft.name].burned = 0;
        }
      });

      setBurnStack(_burnStack);
    }
  }, [processingBurn]);

  return (
    <Modal
      title={"Select NFT"}
      onClose={() => {
        if (burning) {
          alert("Please wait for current burn to finish processing...");
          return;
        }

        closeModal();
      }}
      buttonDisabled={processingBurn || burning}
      buttonLabel={
        processingBurn ? "Burning..." : burning ? "Burning..." : "Burn NFT"
      }
      onClick={async () => {
        if (selectedNfts.size == 0) {
          alert("Please select NFTs to burn...");
          return;
        }

        if (burning) {
          alert("Please wait for current burn to finish processing...");
          return;
        }
        setBurning(true);
        await onBurn(selectedNfts);

        setBurning(false);
      }}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <div className="multiple_select">
              <BurnPreview />
            </div>
            <div
              className="nft_text_container"
              style={{ backgroundColor: "rgb(216, 53, 42)" }}
            >
              +{valueKey.charAt(0).toUpperCase() + valueKey.substring(1)}:{" "}
              {totalRecovered}
            </div>
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <ConsumablesSelectionPanel
            selectedNfts={selectedNfts}
            poolKey={poolKey}
            valueKey={valueKey}
            onSelect={(newlySelectedEnergyDrink) => {
              selectNft(newlySelectedEnergyDrink);
            }}
          />
        </div>
      </>
    </Modal>
  );
};

export { ConsumablesSelectionModal };
