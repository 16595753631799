import React, { useContext } from "react";
import {
  createBurnAction,
  SignTransaction,
  GameContext, sendMessage,
  nftBurnedCallback
} from "../../../network/Connector";
import {  ConsumablesSelectionModal} from "../GameScreen/LobbyScreens/components/EnergyComponents";

export function EnergyDrinkModal({
  energyDrinkModalSwitch, closeDrinkModal, processingBurn,
}) {
  const { room, ual, } = useContext(GameContext);

  return (
    <>
      {energyDrinkModalSwitch && (
        <ConsumablesSelectionModal
          onBurn={async (drinks) => {
            const actions = Array.from(drinks).map((asset_id) => {
              return createBurnAction(
                ual.activeUser.accountName,
                parseInt(asset_id)
              );
            });
            console.log("debug-1 actions", actions);
            const success = await SignTransaction(actions);
            if (success) {
              console.log("d-1", { room });
              //drinks = key, energytest = schema_name
              sendMessage(() => {
                setTimeout(() => {
                  room.send("refreshNfts", {
                    key: "drinks",
                    schema: "energytest",
                  });
                }, 5000);
              });
              nftBurnedCallback.call();
              console.log("[debug-nft-burn] nft burned");
              //closeDrinkModal();
            } else {
              alert("Burn drink failed!");
            }
          }}
          closeModal={() => {
            if (processingBurn) {
              alert("Processing burn...");
              return;
            }
            closeDrinkModal();
          }}
          poolKey="drinks"
          valueKey="energy"
          processingBurn={processingBurn} />
      )}
    </>
  );
}
