import React, { useContext } from "react";
import { GameContext } from "../../../../../network/Connector";
import fillerCard from "../../../../../static/popup_filler.png";
import _ from "lodash";
import NftImage from "../../../../atom/NftImage";

const SelectionSpot = ({
  type,
  width,
  height,
  showSelector,
  selectionObject,
  style,
  className,
}) => {
  const { player } = useContext(GameContext);

  var thumbnail = _.get(player, selectionObject + ".img", "");
  var thumbnailLink = "https://cloudflare-ipfs.com/ipfs/" + thumbnail;

  //If there is no thumbnail, set default fillerCard
  if (thumbnail == "") {
    thumbnailLink = fillerCard;
  }

  const getThumbnail = () => (
    <NftImage
      style={{ width: width, height: height }}
      src={thumbnailLink}
      hash={thumbnail}
    />
  );

  const workingPopup = () => {
    showSelector(true);
  };

  const disabledPopup = () => {
    alert("You do not have enough power-ups to equip");
  };

  return (
    <div
      className={"selection_spot " + className}
      onClick={workingPopup}
      style={style}
    >
      <div
        className="selection_spot_thumbnail"
        style={{ width: width, height: height }}
      >
        {getThumbnail()}
      </div>
    </div>
  );
};

export default SelectionSpot;
