import React from "react";
import { ATOMIC_HUB_LINK } from "../../../Constants";
import { LongButton } from "../../atom/Button";

export default () => {
  return (
    <div className="container_centered">
      <div className=" alert_container column-centered">
        <div className="alert_header">ALERT!</div>
        <div className="alert_subtext">
          At least 1 Car <span>&#38;</span> Driver required
        </div>
        <a
          href={`${ATOMIC_HUB_LINK}/explorer/collection/crashemtest3`}
          target="_blank"
          className="alert_link"
        >
          Get Cars/Drivers NFTs at the atomic hub now!
        </a>
        <LongButton
          onClick={() => {
            window.open(ATOMIC_HUB_LINK + "", "_blank");
          }}
        >
          Buy Pack
        </LongButton>
      </div>
    </div>
  );
};
