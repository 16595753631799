import React from "react";
import CenterConsole from "./CenterConsole";
import RefreshIcon from "../../static/refresh-btn.png";
import AddIcon from "../../static/add-icon.png";
import logo from "../../static/logo.png";
import "./Stage.css";
import EnergyIcon from "../../static/icons/Energy 1.png";
import RepairIcon from "../../static/icons/Repair 2.png";
import DerbyTokenIcon from "../../static/icons/Token.png";
import JoinsIcon from "../../static/icons/Joins.png";
import {
  initializeDashboardCallback,
  onRefreshedCallback,
  openEnergyDrinkBurnModalCallback,
  openCarPartBurnModalCallback,
  sendMessage,
  onDisconnectedCallback,
  onReconnectedCallback,
  nftBurnedCallback,
} from "../../network/Connector";
import { DERBY_TOKEN_DISPLAY } from "../../Constants";
import { AppContext } from "../AppContext";
import ReactTooltip from "react-tooltip";

class Dashboard extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      player: null,
      ual: null,
      room: null,
      loading: false,
    };
  }

  componentDidMount() {
    initializeDashboardCallback.setListener(
      this.initializeDashboard.bind(this)
    );
    onRefreshedCallback.setListener(this.onRefreshed.bind(this));

    openEnergyDrinkBurnModalCallback.setListener(
      this.onOpenEnergyDrinkModal.bind(this)
    );
    openCarPartBurnModalCallback.setListener(
      this.onOpenCarPartModal.bind(this)
    );
    nftBurnedCallback.setListener(this.onNftBurn.bind(this));
    onDisconnectedCallback.setListener(this.onDisconnect.bind(this));
    onReconnectedCallback.setListener(this.onReconnect.bind(this));
  }

  onDisconnect = () => {
    const appContext = this.context;

    console.log({ appContext });

    appContext.ShowLoading(true, "Waiting for internet connection...");
  };

  onReconnect = () => {
    const appContext = this.context;

    appContext.ShowLoading(false);
  };

  onNftBurn = () => {
    this.props.processingBurn(true);
    console.log("[debug-nft-burn] nft burned");
  };

  initializeDashboard = (_player, _ual, _room) => {
    console.log("showDashboard triggered");
    this.setState({
      visible: true,
      player: _player,
      ual: _ual,
      room: _room,
    });
  };

  onOpenEnergyDrinkModal = () => {
    const { player } = this.state;
    //Add Stamina
    if (player.drinks.length == 0) {
      alert("You have no drinks to burn!");
      return;
    }
    this.props.openEnergyDrinkModal();
  };

  onOpenCarPartModal = () => {
    const { player } = this.state;
    //Add Stamina
    if (player.parts.length == 0) {
      alert("You have no parts to burn!");
      return;
    }
    this.props.openCarPartModal();
  };

  onRefreshed = () => {
    let btn = document.getElementById("refresh-btn");
    btn.classList.remove("continuously-rotate");
    console.log("Energy Refreshed");
    this.setState({
      loading: false,
    });

    this.props.processingBurn(false);
    console.log("[debug-nft-burn] nft burn processed");
  };

  refresh = () => {
    //this.toggleDashboard();
    this.setState({
      loading: true,
    });

    console.log("Refreshing Energy");

    let btn = document.getElementById("refresh-btn");
    btn.classList.add("continuously-rotate");

    sendMessage(() => {
      this.state.room.send("refreshEnergy");
    });
  };

  toggleDashboard = () => {
    const dashb = document.getElementById("dashboard");

    dashb.classList.toggle("showdashboard");
    dashb.classList.toggle("hidedashboard");

    const e = document.getElementById("refresh-btn");

    e.classList.toggle("rotatedon");
    e.classList.toggle("rotatedoff");
  };

  render() {
    const { player, ual, room, visible } = this.state;
    console.log({ player });
    if (!visible) return <div className="empty-dashboard" />;

    return (
      <div className="showdashboard" id="dashboard">
        <div>
          <span id="account-name">{ual.activeUser.accountName}</span>
          <div
            id="refresh-btn-container"
            onClick={() => {
              if (this.state.loading) {
                return;
              }
              this.refresh();
            }}
          >
            <img className="rotatedon" id="refresh-btn" src={RefreshIcon} />
            <small>Refresh Balance</small>
          </div>
        </div>
        {room ? (
          <>
            <div className="acct_stats">
              <img
              
    data-tip={"DERBY"}
    data-for={"main-tip"}
     src={DerbyTokenIcon} className="energy-icon" />
              {player?.tokenAmount?.replace("DERBY", DERBY_TOKEN_DISPLAY)}
            </div>
            {player ? (
              <>
                <div className="acct_stats">
                  <img
                    alt="Energy"
                    title="Energy"
                    src={EnergyIcon}
                    className="energy-icon"
                    data-tip={"Energy"}
                    data-for={"main-tip"}
                  />
                  {player?.energy.stamina}
                  {player.energy.consumedStamina > 0
                    ? `(-${player?.energy?.consumedStamina})`
                    : ""}
                  /{player?.energy.maxStamina}
                  <img
                    className="add-energy-btn"
                    onClick={this.onOpenEnergyDrinkModal}
                    src={AddIcon}
                  />
                </div>
                <div className="acct_stats">
                  <img
                    alt="Repair"
                    title="Repair"
                    src={RepairIcon}
                    className="energy-icon"
                    data-tip={"Repair"}
                    data-for={"main-tip"}
                  />
                  {player?.energy.durability}
                  {player.energy.consumedDurability > 0
                    ? `(-${player?.energy?.consumedDurability})`
                    : ""}
                  /{player?.energy.maxDurability}
                  <img
                    alt="Auto joins"
                    title="Auto joins"
                    className="add-energy-btn"
                    src={AddIcon}
                    onClick={this.onOpenCarPartModal}
                  />
                </div>
                {player.selectedManager != null ? (
                  <div className="acct_stats">
                    {" "}
                    <img src={JoinsIcon} className="energy-icon"  
                    data-tip={"Auto-Joins Left"}
                    data-for={"main-tip"}/>
                    {player?.autoJoins}/{player?.selectedManager?.leadership}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <img src={logo} className="dashboard_logo" />
        
        <ReactTooltip 
        className="tooltip" id={"main-tip"} aria-haspopup="true"></ReactTooltip>
      </div>
    );
  }
}

class Stage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      energyDrinkModalSwitch: false,
      carPartModalSwitch: false,
      processingBurn: false,
    };
  }

  render() {
    const { energyDrinkModalSwitch, carPartModalSwitch } = this.state;
    return (
      <div style={{ height: "100%", position: "relative" }}>
        {this.props.ual.activeUser ? (
          <Dashboard
            openEnergyDrinkModal={() => {
              this.setState({ energyDrinkModalSwitch: true });
            }}
            openCarPartModal={() => {
              this.setState({ carPartModalSwitch: true });
            }}
            processingBurn={(newState) => {
              this.setState({ processingBurn: newState });
            }}
          />
        ) : (
          ""
        )}

        <CenterConsole
          ual={this.props.ual}
          energyDrinkModalSwitch={energyDrinkModalSwitch}
          closeDrinkModal={() => {
            this.setState({ energyDrinkModalSwitch: false });
          }}
          carPartModalSwitch={carPartModalSwitch}
          closePartModal={() => {
            this.setState({ carPartModalSwitch: false });
          }}
          processingBurn={this.state.processingBurn}
        />

      </div>
    );
  }
}

export default Stage;
