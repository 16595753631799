import React, { useContext, useEffect, useMemo, useState } from "react";
import MessageFrame from "../MessageFrame";
import EventPanel from "./components/EventPanel";
import NoticePanel from "./components/NoticePanel";
import {
  GameContext,
  stopEngineSfxCallback,
  sendMessage,
} from "../../../network/Connector";
import ClaimModal from "./components/ClaimModal.jsx";
import "./screen.css";
import { ClaimButton } from "./components/ClaimButton";
import { IPFS_BASE_URL } from "../../../Constants";
import NftImage from "../../atom/NftImage";
import { MarketplaceButton } from "./GameScreen";

export default () => {
  const { room, roomState, player } = useContext(GameContext);

  //Claim modal switches
  const [claimModalSwitch, setClaimModalSwitch] = useState(false);
  const showClaimModal = (bool) => setClaimModalSwitch(bool);

  const [coinRewardsText, setCoinRewardsText] = useState("");
  const [nftRewardsText, setNftRewardsText] = useState("");

  useEffect(() => {
    if (player) {
      setCoinRewardsText(`${player.rewards.claimable_derby}`);
      setNftRewardsText(
        player.rewards.claimable_nfts.map((nft) => nft.template_name).join(", ")
      );
    }
  }, [player]);

  const ClaimRewards = async () => {
    showClaimModal(true);
  };

  useEffect(() => {
    stopEngineSfxCallback.call();
  }, []);

  const PlayerDisplay = () => {
    const { player } = useContext(GameContext);

    return (
      <NoticePanel>
        <div className="notice_large">
          Winner&nbsp;{player.result.winnerName}
        </div>
        <div className="nextRound_equipped">
          <NftImage
            src={`${IPFS_BASE_URL}${player.result.winnerDriver?.img}`}
            hash={player.result.winnerDriver?.img}
            style={{
              backgroundPositionX: "center",
            }}
          />
          <NftImage
            src={`${IPFS_BASE_URL}${player.result.winnerCar?.img}`}
            hash={player.result.winnerCar?.img}
            style={{
              backgroundPositionX: "center",
            }}
          />
        </div>
        <div className="nextRound_rank">
          Your rank for this round is:
          <span>{player.result.rank}</span>
        </div>
      </NoticePanel>
    );
  };

  const SpectatorDisplay = () => {
    return (
      <NoticePanel>
        <div className="notice_large">
          Winner <br />
          <span className="nextRound_rank">{player.result.winnerName}</span>
        </div>
      </NoticePanel>
    );
  };

  const displayMode = useMemo(() => {
    return player.result.rank != "nth" ? (
      <PlayerDisplay />
    ) : (
      <SpectatorDisplay />
    );
  }, [player.result.rank]);

  return (
    <>
      {player.playing ? (
        player.autoJoins > 0 ? (
          <MessageFrame
            onClick={() => {}}
            maintext={"Joining"}
            subtext={"Next Game..."}
            noCursor
          />
        ) : (
          <ClaimButton
            onClaim={() => {
              ClaimRewards();
            }}
            coinRewardsText={coinRewardsText}
            trophyAmount={player.rewards.claimable_trophies}
          />
        )
      ) : (
        //RETURN TO LOBBY
        <MessageFrame
          onClick={() => {
            sendMessage(async () => {
              room.send("returnToLobby");
            });
          }}
          maintext={"Return"}
          subtext={"to Lobby"}
        />
      )}
      <div
        className="container_row"
      >
        <div className="ingame-marketplace_container ">
          <MarketplaceButton />
        </div>
        <EventPanel events={[...player.result.events, ...roomState.messages]} />
        {displayMode}
      </div>
      {claimModalSwitch &&
        (player.rewards.claimable_derby > 0 ||
          player.rewards.claimable_nfts.length > 0) && (
          <ClaimModal
            onSelect={(nft) => {
              console.log("reward selected", nft);
            }}
            closeModal={() => {
              setClaimModalSwitch(false);
            }}
          />
        )}
    </>
  );
};
