import React, { useContext } from "react";
import UnplayableScreen from "../GameScreen/UnplayableScreen";
import center_console from "../../../static/center_console.png";
import centerConsoleFallback from "../../../static/center_console_fallback.png";
import { GameContext } from "../../../network/Connector";
import GameScreen from "../GameScreen";
import FallbackedImage from "../../atom/FallbackedImage";
import { ClosedButton } from "./ClosedButton";

export const ClosedWrapper = () => {
  const { player } = useContext(GameContext);

  return (
    <>
      <FallbackedImage
        className="center_console_img"
        src={center_console}
        fallbackimage={centerConsoleFallback}
        height={634}
        width={1186} />
      {player.flag.state == "Open" ? (
        <GameScreen />
      ) : (
        <>
          {player.cars.length > 0 && player.drivers.length > 0 ? (
            <div className="container_centered">
              <div
                style={{
                  position: "fixed",
                  left: 0,
                  top: 0,
                  zIndex: 1,
                  width: "100%",
                  backgroundColor: "rgba(0,0,1,.7)",
                }}
              >
                {/* <img src={city_bg} style={{ width: "100%" }} /> */}
              </div>
              <ClosedButton />
            </div>
          ) : (
            <UnplayableScreen />
          )}
        </>
      )}
    </>
  );
};
