export default {
    ROUND_WAIT_TIME: 5,
    ROUND_WAIT_END_TIME: 30,
    TOTAL_HEALTH: 100,
    COLLISION_RATE: .6, //Reset TO 60%[.6] after testing
    DEFAULT_STATS: {
           HEALTH: 100,
           INITIATIVE: 20,
           INITIATIVE_MOD: 1,
           DAMAGE_INFLICTION: 20,
           DAMAGE_INFLICTION_MOD: 1,  //Should be just 1 but testing games take too long
           ARMOR: 6,
           ARMOR_MOD: 1,
           LUCK: 1,
           STAMINA: 5,
           SLOTS: 1,
           REWARD: 1,
    },
    REWARD_POOL: 10,
    
    //STATS
    CAR_DAMAGE_STAT_MULTIPLIER: 2,
    CAR_DURABILITY_STAT_MULTIPLIER: 2,
    UPGRADE_DAMAGE_STAT_MULTIPLIER: 4,
    UPGRADE_LUCK_MULTIPLIER: 10,
    LUCK_DIVISOR: 4,

    TROPHY_DROP_SKILL_MULTIPLIER: 10
}