import React, { useContext, useState, useEffect } from "react";
import { GameContext, requestCooldown } from "../../../../../network/Connector";
import _ from "lodash";
import fillerCard from "../../../../../static/popup_filler.png";
import { IPFS_BASE_URL } from "../../../../../Constants";
import Modal from "../../../../atom/Modal";
import NftImage from "../../../../atom/NftImage";
import lock from "../../../../../static/close.png";

//Nft selection
const SelectedSpot = ({ id, selectedNft, type }) => {
  console.log("NftSpot", { nft: selectedNft });

  return (
    <div id={id}>
      <NftImage
        src={
          selectedNft == null
            ? fillerCard
            : `${IPFS_BASE_URL}${selectedNft?.img}`
        }
        style={{ width: "auto" }}
        hash={selectedNft?.img}
      />
    </div>
  );
};

const NftCard = ({ nft, className, onSelect, selectable = true }) => {
  const { room } = useContext(GameContext);
  return (
    <a
      onClick={() => {
        if (selectable) onSelect(nft);
        else {
          console.log("Requesting cooldown");
          
          alert("Cannot equip until: " + new Date(nft.cooldownEnd * 1000));
          //requestCooldown(room, nft.asset_id);
        }
      }}
      className={className}
      style={{ position: "relative" }}
    >
      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={nft == null ? fillerCard : `${IPFS_BASE_URL}${nft?.img}`}
        hash={nft?.img}
      />
      {!selectable && (
        <img
          src={lock}
          style={{
            position: "absolute",
            width: "100%",
            top: "15%",
            opacity: 0.45,
          }}
        />
      )}
    </a>
  );
};

const NftsSelectionPanel = ({ nftPool, onSelect, selectedNftId }) => {
  //Selected is used for the currently clicked nft in the selection menu
  console.log({ nftPool });
  var nftsSelection = nftPool.map((nft, index) => {
    console.log("nft", nft);

    //default classes to be added here
    var renderClass = "btn";
    var stateText = "";

    if (nft.asset_id == selectedNftId) {
      renderClass += " selected";
      stateText = "Selected";
    }

    //Selectable logic
    if(nft.cooldownEnd){
      //alert("Cooldown ends on:" + nft.cooldownEnd);
      //Check if cooldown date has passed
      let cooldownEnd = nft.cooldownEnd * 1000; //translate to end of date
      let now = new Date().getTime();

      if(now < cooldownEnd)
        nft.locked = true;
    }

    return (
      <div className="selection_cell" key={"nft_selection_" + nft.asset_id}>
        <NftCard
          nft={nft}
          className={renderClass}
          onSelect={onSelect}
          stateText={stateText}
          selectable={!nft?.locked}
        />
      </div>
    );
  });

  return <div className="selection_panel">{nftsSelection}</div>;
};

const NftSelectionModal = ({ nfts, selectedNfts, onSelect, closeModal }) => {
  console.log("NftSelectionModal", { nfts, selectedNfts });
  //TODO: REPLACE THIS WITH ACTUAL UNIQUE FILTERING
  let selectedNames = new Set();

  selectedNfts.forEach((nft) => {
    if (!selectedNames.has(nft?.name)) {
      selectedNames.add(nft?.name);
    }
  });

  const nftPool = nfts.filter((nft) => !selectedNames.has(nft.name));
  const [selectedNft, setSelectedNft] = useState(null);

  console.log("nftPool", nftPool);

  return (
    <Modal
      title={"Select An Nft"}
      onClose={() => closeModal()}
      buttonLabel={selectedNft != null ? "Equip Nft" : "Select an Nft"}
      onClick={() => {
        selectedNft != null
          ? onSelect(selectedNft)
          : alert("Please select an NFT");
      }}
    >
      <>
        <div className="selected_display">
          <div className="nft_info">
            <NftImage
              className="nft_img"
              style={{ width: "auto" }}
              imgClass="nft_img"
              src={
                selectedNft == null
                  ? fillerCard
                  : `${IPFS_BASE_URL}${selectedNft?.img}`
              }
              hash={selectedNft?.img}
            />
          </div>
        </div>
        <div className="selection_panel_container panel_container">
          <NftsSelectionPanel
            nftPool={nftPool}
            onSelect={(newlySelectedNft) => setSelectedNft(newlySelectedNft)}
            selectedNftId={selectedNft?.asset_id}
          />
        </div>
      </>
    </Modal>
  );
};

export { SelectedSpot, NftSelectionModal };
