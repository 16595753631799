import React from "react";
import "./panel.css";
import CrashArtDisplay from "./CrashArtDisplay";

export default ({ children }) => (
  <div id="notice_panel">
    <div className="notice_content">
      <CrashArtDisplay />
      <div className="notice_text">{children}</div>
    </div>
  </div>
);
