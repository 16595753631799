import React from "react";
import MessageFrame from "../../MessageFrame";

export function ClaimNftsButton({ onClaim, claimableNfts }) {
  console.log({ onClaim, claimableNfts });

  return (
    <MessageFrame
      onClick={onClaim}
      maintext={"CLAIM"}
      subtext={`${claimableNfts} remaining nfts`}
    />
  );
}
