import * as Colyseus from "colyseus.js";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppContext } from "../components/AppContext";
import {
  gameRoomName,
  GAME_CONTEXT,
  WAX_DERBY_MARKET_ACCOUNT,
  WSSERVER,
  eos,
  WAX_DERBY_CONTRACT_ACCOUNT,
  DEFAULT_TRANSACTION_CONFIG,
  DERBY_TOKEN_ACCOUNT,
} from "../Constants";
import { JsonRpc } from "eosjs";

import eosHelper from "../utils/EosHelper";
import BigNumber from "bignumber.js";
import Callback from "../utils/Callback";
import Ping from "./Ping.js";

var client = new Colyseus.Client(WSSERVER);

let initializeDashboardCallback = new Callback();
let onRefreshedCallback = new Callback();
let startDayCallback = new Callback();

let openEnergyDrinkBurnModalCallback = new Callback();
let openCarPartBurnModalCallback = new Callback();

let updateMaxStaminaCallback = new Callback();
let updateMaxDurabilityCallback = new Callback();

let damagedCallback = new Callback();
let crackedCallback = new Callback();

let inventoryDataValidatedCallback = new Callback();
let officeDataValidatedCallback = new Callback();
let ping = new Ping();

let reconnect = () => {};
let isRoomConnected = () => {};

let connectionFailHandler = () => {
  alert("Reconnection failed, the browser will now refresh");
  window.location.reload();
};

const sendMessage = async (callback, tryAttempt = 0) => {
  console.log("[sendMessage]", tryAttempt);
  const isConnected = await isRoomConnected();
  const maxRetryAttempts = 5;
  console.log("[sendMessage] isConnected", isConnected);
  if (!isConnected) {
    console.log("[sendMessage] not connected");
    if (tryAttempt < maxRetryAttempts) {
      var reconnected = await reconnect();
      if (reconnected) {
        console.log("[sendMessage] reconnection success");
        callback();
        return {
          success: true,
        };
      } else {
        console.log("[sendMessage] reconnection failed");

        //Wait 1 second before second try
        await new Promise((r) => setTimeout(r, 1000));
        return await sendMessage(callback, tryAttempt + 1);
      }
    } else {
      console.log("[sendMessage] max retry attempts done, request failed");
      connectionFailHandler();

      return {
        success: false,
        message: "Max attempts reached, could not complete request",
      };
    }
  } else {
    callback();
    return {
      success: true,
    };
  }
};

//Sfx callbacks
let hardCollisionSfxCallback = new Callback();
let lightCollisionSfxCallback = new Callback();
let startEngineSfxCallback = new Callback();
let boostEngineSfxCallback = new Callback();
let runEngineSfxCallback = new Callback();
let stopEngineSfxCallback = new Callback();
let explodeEngineSfxCallback = new Callback();
let glassBreakSfxCallback = new Callback();
let newChallengerSfxCallback = new Callback();

let claimResolvedCallback = new Callback();
let claimSignedCallback = new Callback();

let joinCallback = new Callback();

let nftBurnedCallback = new Callback();

let cooldownCallback = new Callback();

let dcTimer = null;

let onDisconnectedCallback = new Callback();
let onReconnectedCallback = new Callback();

const joinServer = async (eosioName) => {
  const room = await client.joinOrCreate(gameRoomName, {
    eosioName,
    autoDispose: false,
  });
  console.log("room joined", eosioName);
  return room;
};

const requestCooldown = async (room, asset_id) => {
  sendMessage(() => {
    room.send("requestCooldown", asset_id);
  });
};

const attemptCloseGame = async (
  room,
  confirmMessage = "Are you sure you want to close for the night?"
) => {
  const confirm = window.confirm(confirmMessage);
  if (confirm) {
    sendMessage(() => {
      room.send("closeForDay");
    });
  }
};

const rpc = new JsonRpc(
  eos.rpcEndpoints[0].protocol + "://" + eos.rpcEndpoints[0].host
);
var ual = null;
var setUAL = (_ual) => {
  ual = _ual;
};

const GameContext = createContext({});

const convertActionToDefaultTransaction = (actions) => {
  return {
    actions,
    configurations: DEFAULT_TRANSACTION_CONFIG,
  };
};

const createWithdrawAction = (user, amount) => {
  var data = {
    user,
    amount,
  };

  console.log("createWithdrawAction data", data);

  const actions = [
    {
      account: WAX_DERBY_CONTRACT_ACCOUNT,
      name: "withdraw",
      authorization: [
        {
          actor: user,
          permission: "active",
        },
      ],
      data,
    },
  ];

  return actions;
};

const createClaimNftsAction = (user, amount) => {
  var data = {
    user,
    amount,
  };

  console.log("createWithdrawAction data", data);

  const actions = [
    {
      account: WAX_DERBY_CONTRACT_ACCOUNT,
      name: "claimnfts",
      authorization: [
        {
          actor: user,
          permission: "active",
        },
      ],
      data,
    },
  ];

  return actions;
};

const createNftTransferActions = (asset_ids, from, memo, to) => {
  console.log({ asset_ids, from, memo, to});
  let actions = [];

  let data = {
    from,
    to,
    asset_ids,
    memo,
  };

  actions.push({
    account: "atomicassets",
    name: "transfer",
    authorization: [
      {
        actor: from,
        permission: "active",
      },
    ],
    data,
  });

  return actions;
}

const createTrophyWithdrawalActions = (user, amount) => {
  let actions = [];

  let data = {user, amount
  };

  actions.push({
    account: WAX_DERBY_CONTRACT_ACCOUNT,
    name: "gettrophies",
    authorization: [
      {
        actor: user,
        permission: "active",
      },
    ],
    data,
  });

  return actions;
}

const createDepositTrophyActions = (user, trophies) => {
  console.log({ user, trophies });

  return createNftTransferActions(trophies, user, "trophy_burn", WAX_DERBY_CONTRACT_ACCOUNT)
}

const createMarketPurchaseActions = (user, template_id) => {
  console.log({ user, template_id });
  let actions = [];

  var purchaseData = {
    user,
    template_id,
  };

  actions.push({
    account: WAX_DERBY_MARKET_ACCOUNT,
    name: "buyfrmmarket",
    authorization: [
      {
        actor: user,
        permission: "active",
      },
    ],
    data: purchaseData,
  });

  return actions;
};

const createBurnAction = (asset_owner, asset_id) => {
  var data = {
    asset_owner,
    asset_id,
  };

  console.log("createBurnAction data", data);

  return {
    account: "atomicassets",
    name: "burnasset",
    authorization: [
      {
        actor: asset_owner,
        permission: "active",
      },
    ],
    data,
  };
};

const createDepositAction = (user, amount) => {
  var data = {
    from: user,
    to: WAX_DERBY_CONTRACT_ACCOUNT,
    quantity: amount,
    memo: "deposit",
  };

  console.log("createDepositAction data", data);

  const actions = [
    {
      account: DERBY_TOKEN_ACCOUNT,
      name: "transfer",
      authorization: [
        {
          actor: user,
          permission: "active",
        },
      ],
      data,
    },
  ];

  return actions;
};

const ClaimNfts = async function (_room, user, amount) {
  let actions = createClaimNftsAction(user, amount);
  console.log({ actions });
  const success = await SignTransaction(actions);
  if (success) {
    sendMessage(() => {
      _room.send("REFRESH_CLAIMABLE_NFTS");
    });
    return true;
  } else {
    alert("Claim NFTs failed!");
    return false;
  }
};

const SignTransaction = async function (actions, overrideTxConfig = {}, showError = true) {
  var transactionData = convertActionToDefaultTransaction(actions);
  console.log("SignTransaction", { transactionData });
  try {
    var receipt = await ual.activeUser.signTransaction(
      {
        actions: transactionData.actions,
      },
      { ...transactionData.configurations, ...overrideTxConfig }
    );
    console.log("SignTransaction receipt", receipt);
    return true;
  } catch (error) {
    //Only show error
    let errorMessage = error.toString();
    console.log("SignTransaction failed with error:", {
      error,
      errorMessage,
    });

    //Skip unnecesary error message
    if(showError)
      alert(
        "Error:" + errorMessage.substring(errorMessage.indexOf("message:") + 8)
      );
      
    return false;
  }
};

export {
  GameContext,
  rpc,
  ual,
  setUAL,
  SignTransaction,
  createWithdrawAction,
  createDepositAction,
  createBurnAction,
  createMarketPurchaseActions,
  createDepositTrophyActions,
  createNftTransferActions,
  createTrophyWithdrawalActions,
  onRefreshedCallback,
  
  initializeDashboardCallback,
  hardCollisionSfxCallback,
  lightCollisionSfxCallback,
  startEngineSfxCallback,
  boostEngineSfxCallback,
  runEngineSfxCallback,
  glassBreakSfxCallback,
  stopEngineSfxCallback,
  explodeEngineSfxCallback,
  damagedCallback,
  crackedCallback,
  claimResolvedCallback,
  claimSignedCallback,
  newChallengerSfxCallback,
  attemptCloseGame,
  startDayCallback,
  openEnergyDrinkBurnModalCallback,
  openCarPartBurnModalCallback,
  updateMaxStaminaCallback,
  updateMaxDurabilityCallback,
  cooldownCallback,
  requestCooldown,
  joinCallback,
  inventoryDataValidatedCallback,
  officeDataValidatedCallback,
  createClaimNftsAction,
  ClaimNfts,
  sendMessage,
  nftBurnedCallback,
  onDisconnectedCallback,
  onReconnectedCallback,
};

export default ({
  eosioName,
  children,
  ual,
  connectedCallback,
  notConnectedCallback,
}) => {

  const [room, setRoom] = useState(null);
  const [player, setPlayer] = useState(null);
  const [claimableDerby, setClaimableDerby] = useState("0.0000");
  const [roomState, setRoomState] = useState(null);

  const showAlert = (message) => {
    alert(message);
  };

  const _reconnect = useMemo(() => {
    return async () => {
      try {
        let _room = await client.reconnect(room.id, room.sessionId);

        setRoom(_room);
        await bindRoomEvents(_room);

        ping.resetPingPong();

        console.log("Reconnected successfully", _room);
        //alert("Reconnected successfully");
        return true;
      } catch (e) {
        console.error("reconnect error", e);
        return false;
      }
    };
  }, [room]);

  //Check if room has disconnected or if dc time > 5 secs
  const _isRoomConnected = useMemo(() => {
    return async () => {
      console.log("_isRoomConnected", { ping, room });
      return ping.dcTime < 5;
    };
  }, [room]);

  const bindRoomEvents = (_room) => {
    _room.onMessage("DEBUG", (debugObject) => {
      console.log("Client received DEBUG", debugObject);
      if (debugObject.message) alert(debugObject.message);
    });

    _room.onMessage("PONG", ping.onPong);
    _room.onMessage("RECONNECTED", () => {
      console.log("[RECONNECTED]");
    });

    _room.onMessage("NEW_CHALLENGER", () => {
      console.log("HERE COMES A NEW CHALLENGER")
      newChallengerSfxCallback.call();
    });

    _room.onMessage("REFRESHED", () => {
      onRefreshedCallback.call();
    });

    _room.onMessage("DEATH", () => {
      explodeEngineSfxCallback.call();
    });

    _room.onMessage("JOIN_RESPONSE", (response) => {
      joinCallback.call(response);
    });

    _room.onMessage("PLAYER_DETAIL", setPlayer);
    _room.onMessage("ALERT", showAlert);
    _room.onMessage("UPDATE_CLAIMABLE_DERBY", setClaimableDerby);

    setRoomState({ ..._room.state });

    _room.onMessage("DAMAGED", (damage) => {
      if (damage <= 0) return;

      console.log("debug-1 calling damage callback");
      damagedCallback.call(damage);

      if (damage > 7) {
        hardCollisionSfxCallback.call();
      } else {
        lightCollisionSfxCallback.call();
      }
    });

    _room.onMessage("CRACKED", (remainingHP) => {
      crackedCallback.call(remainingHP);
      //TODO: Add glass shatter sfx
    });

    _room.onMessage("OPEN_SUCCESS", async (success) => {
      startDayCallback.call(success);
    });

    _room.onMessage("UPDATE_MAX_ENERGY_TX", async (txData) => {
      try {
        //Attempt open transaction
        var success = await SignTransaction(txData.actions);
        if (success) {
          //if successful, check energy if filled
          console.log("UPDATE_MAX_ENERGY_TX Success", success);
          if (txData.intent == "update_max_stamina") {
            updateMaxStaminaCallback.call(true);
          } else {
            updateMaxDurabilityCallback.call(true);
          }
        } else {
          if (txData.intent == "update_max_stamina") {
            updateMaxStaminaCallback.call(false);
          } else {
            updateMaxDurabilityCallback.call(false);
          }
        }
        //If energy is filled, update energy data on client
      } catch (error) {
        console.log("OPEN_TRANSACTION error", error);
        if (txData.intent == "update_max_stamina") {
          updateMaxStaminaCallback.call(false);
        } else {
          updateMaxDurabilityCallback.call(false);
        }
      }
    });

    _room.onMessage("COOLDOWN_RECEIVED", async (cooldown) => {
      try {
        alert("Cannot equip item until: " + new Date(cooldown));
        cooldownCallback.call(cooldown);
      } catch (error) {
        console.log("COOLDOWN_RECEIVED error", error);
        cooldownCallback.call(null);
      }
    });

    _room.onMessage("OPEN_TRANSACTION", async (txData) => {
      try {
        //Attempt open transaction
        var success = await SignTransaction(txData.actions);
        if (success) {
          //if successful, check energy if filled
          console.log("OPEN_TRANSACTION Success", success);
          sendMessage(() => {
            //TODO: Replace this as this is unsafe, call on to startDaySuccess instead and have it check on-chain first before switching flags
            //_room.send("switchFlag", "Open");

            _room.send("openSuccess");
          });
        } else {
          startDayCallback.call(false);
        }
        //If energy is filled, update energy data on client

        //claimSignedCallback.call(success);
      } catch (error) {
        console.log("OPEN_TRANSACTION error", error);
        startDayCallback.call(false);
      }
    });

    _room.onMessage("PROCESS_CLAIM", async (txData) => {
      try {
        var success = await SignTransaction(txData.actions);
        console.log("PROCESS_CLAIM Success", success);

        claimSignedCallback.call(success);
        sendMessage(() => {
          _room.send("DERBY_CLAIM_RESPONSE");
        });
      } catch (error) {
        console.log("PROCESS_CLAIM error", error);
      }
    });

    _room.onMessage("CLAIM_RESOLVED", async (resolved) => {
      console.log("debug-1 claim_resolved at " + new Date(), resolved);
      claimResolvedCallback.call(resolved);
    });

    _room.onMessage("EQUIP_FAILED", async (data) => {
      console.log("debug-1 EQUIP_FAILED with " + { data });
      alert(data.error);
    });

    _room.onMessage("INVENTORY_DATA_VALIDATION_RESULT", async (result) => {
      console.log("debug-1 INVENTORY_DATA_VALIDATION_RESULT", { result });
      inventoryDataValidatedCallback.call(result);
    });

    _room.onStateChange((newState) => {
      console.log("Room state changed", newState);
      const player = newState.players.get(eosioName);
      setPlayer(player);
      if (player) {
        initializeDashboardCallback.call(player, ual, _room);
      }
      setRoomState({ ...newState });
      connectedCallback();
    });

    _room.onLeave((code) => {
      console.log("[room.onLeave] Client Disconnected:", code);
      /*Codes
      1000 - Regular socket shutdown
      Between 1001 and 1015 - Abnormal socket shutdown
      Between 4000 and 4999 - Custom socket close code (https://docs.colyseus.io/colyseus/server/room/#table-of-websocket-close-codes)
      */
      if (code > 1000) {
        // abnormal disconnection!
        console.log("[room.onLeave] Abnormal disconnection");

        reconnect();
      } else {
        // the client has initiated the disconnection
        console.log("[room.onLeave] Client left by will");
      }
    });

    window.addEventListener("offline", function (e) {
      console.log("[NetworkState] Disconnected");
      dcTimer = setTimeout(() => {
        connectionFailHandler();
      }, 62000);
      onDisconnectedCallback.call();
    });

    // Add event listener online to detect network recovery.
    window.addEventListener("online", function (e) {
      console.log("[NetworkState] Reconnected");
      clearTimeout(dcTimer);
      dcTimer = null;

      reconnect();

      onReconnectedCallback.call();
    });

    _room.onError((e) => {
      console.log("[room.onError] ", e);
    });

    ping.room = _room;
  };

  useEffect(async () => {
    try {
      const _room = await joinServer(eosioName);

      setRoom(_room);
      bindRoomEvents(_room);
    } catch (error) {
      console.log("Error connecting to server", error);
      notConnectedCallback();
    }
  }, []);

  useEffect(() => {
    if (room != null) {
      reconnect = _reconnect;
      isRoomConnected = _isRoomConnected;
      ping.room = room;
    }
  }, [room]);

  return (
    player && (
      <GameContext.Provider
        value={{ player, claimableDerby,  room, roomState, ual }}
      >
        {children}
      </GameContext.Provider>
    )
  );
};
