import React from "react";
import btn from "../../static/btn.png";
import buttonbg from "../../static/btn_bg.png";
import buttonbgFallback from "../../static/btn_bg_fallback.png";
import buttonmain from "../../static/btn_main.png";
import "./style.css";

export const Screws = ({ small }) => {
  const Screw = ({ style }) => (
    <img src={btn} className="button-screw" {...{ style }} />
  );

  return (
    <>
      {small ? (
        <>
          <Screw style={{ right: 4 }} />
          <Screw style={{ left: 4 }} />
        </>
      ) : (
        <>
          <Screw style={{ top: 4, right: 4 }} />
          <Screw style={{ top: 4, left: 4 }} />
          <Screw style={{ bottom: 4, right: 4 }} />
          <Screw style={{ bottom: 4, left: 4 }} />
        </>
      )}
    </>
  );
};

export const LongButton = ({
  onClick,
  disabled = false,
  className = "",
  icon,
  children,
  style,
  iconStyle,
  tooltip = "",
  small,
  tooltipHolder=""
  
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={"button " + (icon ? "icon-button " : "long-button ") + className}
    style={{
      backgroundImage: `url(${buttonbg}), url(${buttonbgFallback})`,
      ...style,
    }}
    data-tip={tooltip}
    data-for={tooltipHolder}
  >
    <Screws {...{ small }} />
    {icon && (
      <img src={icon} className="icon-button-img" style={{ ...iconStyle }} />
    )}
    {children}
  </button>
);

export default ({ onClick, disabled, className = "", children, style }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={"button button-default " + className}
    style={{ backgroundImage: `url(${buttonmain})`, ...style }}
  >
    {children}
  </button>
);
