import React from "react";
import { NftSlot, LockedNftSlot } from "./NftSlot.jsx";

export function InventorySlotsDisplay({
  nftsAvailable,
  slots,
  setSelectedNftIndex,
  selectedNftIndex,
  showNftSelector,
  container_type,
  onUnequip,
}) {
  let inventorySlotDisplay = [];

  for (let i = 0; i < slots.length; i++) {
    const slot = slots[i];
    if (slot.status == "locked") {
      inventorySlotDisplay.push(<LockedNftSlot key={"inventory_slot_" + i} />);
    } else {
      inventorySlotDisplay.push(
        <NftSlot
          showSelector={(selectorState) => {
            if (slot.nft) {
              //Deselect
              if (selectedNftIndex == i) {
                let confirmed = window.confirm(
                  "Do you want to unequip this nft from this slot?"
                );
                if (confirmed) {
                  onUnequip();
                }
                return;
              }
              //Select

              setSelectedNftIndex(i);
              return;
            }
            if (nftsAvailable > 0) showNftSelector(selectorState, i);
            else alert("You have no more nfts to equip");
          }}
          key={"inventory_slot_" + i}
          nft={slot.nft}
          selected={selectedNftIndex == i}
        />
      );
    }
  }

  return (
    <div className={"selection_panel_container " + container_type}>
      {inventorySlotDisplay}
    </div>
  );
}
