import React from "react";
import "./panel.css";

export default ({ events }) => (
  <div id="event_panel">
    <div className="event_content">
      {events
        .slice(0)
        .reverse()
        .map((message, index) => {
          return (
            <div key={"event_message_" + index} className="event_message">
              {message}
            </div>
          );
        })}
    </div>
  </div>
);
