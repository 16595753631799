import React from "react";
import Slider from "react-input-slider";

export default ({ value, setValue, style = {}, className }) => (
  <Slider
    axis="x"
    x={value}
    onChange={({ x }) => {
      setValue(x);
    }}
    className={className}
    style={{ ...style }}
    styles={{
      track: {
        backgroundColor: "#f5e7be",
      },
      active: {
        backgroundColor: "#821f19",
      },
      thumb: {
        width: 16,
        height: 16,
      },
      disabled: {
        opacity: 0.5,
      },
    }}
  />
);
