import React, { useContext, useState, useEffect } from "react";
import { GameContext } from "../../../../../network/Connector";
import { AppContext } from "../../../../AppContext.js";

import * as Colyseus from "colyseus.js";
import "./../../components/selectionStyles.css";
import _ from "lodash";
import {
  SelectedSpot,
  NftSelectionModal,
} from "../components/InventoryComponents";
import {
  MAX_INVENTORY_SLOTS,
  DEFAULT_INVENTORY_SLOTS,
  RARITY_WEIGHTS,
} from "../../../../../Constants";
import { StatPanel } from "../components/StatPanel";
import { InventorySlotsDisplay } from "../components/InventorySlotsDisplay";
import ColyseusHelper from "../../../../../utils/ColyseusHelper";
import { UpgradeSlotsDisplay } from "../components/UpgradeComponents";
/**
 * @param
 * @param {Status} String options: "filled","empty","locked"
 * @param {DerbyCar||DerbyDriver} nft
 */
class Slot {
  constructor(status, nft) {
    this.status = status;
    this.nft = nft;
  }
}
export default () => {
  /**
   * @type {{room:Colyseus.Room}}
   */
  const { room, player } = useContext(GameContext);
  const { switchMenu, ShowLoading } = useContext(AppContext);

  //Upgrade modal switches
  const [garageSlotIndex, setGarageSlotIndex] = useState(false);
  const [garageMechanicSlotIndex, setGarageMechanicSlotIndex] = useState(false);

  const [garageSlots, setGarageSlots] = useState([]);
  const [garageMechanicSlots, setGarageMechanicSlots] = useState([]);
  const [selectedMechanic, setSelectedMechanic] = useState(null);

  let [carPool, setCarPool] = useState([]);
  let [selectedCars, setSelectedCars] = useState([]);
  const [equippedCar, setEquippedCar] = useState(null);
  const [selectedCarIndex, setSelectedCarIndex] = useState(-1);

  let [mechanicsPool, setMechanicsPool] = useState([]);
  let [selectedMechanics, setSelectedMechanics] = useState([]);
  const [equippedMechanic, setEquippedMechanic] = useState(null);
  const [selectedMechanicIndex, setSelectedMechanicIndex] = useState(-1);

  const [carSelector, setCarSelector] = useState(false);
  const showCarSelector = (bool, index) => {
    setGarageSlotIndex(index);
    setCarSelector(bool);
  };

  const [mechanicSelector, setMechanicSelector] = useState(false);

  const showMechanicSelector = (bool, index) => {
    setGarageMechanicSlotIndex(index);
    setMechanicSelector(bool);
  };

  const updateSlot = (index, nft) => {
    const slots = [...garageSlots];
    slots[index].status = "filled";
    slots[index].nft = nft;
    if (selectedCarIndex < 0) {
      setSelectedCarIndex(index);
    }
    setGarageSlots([...slots]);

    let selected = [];

    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];
      if (slot.status == "filled") {
        selected.push(slot.nft);
      }
    }

    setSelectedCars([...selected]);
  };

  const onUnequip = (
    _garageSlots,
    _selectedIndex,
    _setGarageSlots,
    _setSelectedSlots,
    _setSelectedIndex
  ) => {
    try {
      const slots = [..._garageSlots];

      console.log("onUnequip 1", {
        garageSlots: [..._garageSlots],
        _selectedIndex,
        _setGarageSlots,
        _setSelectedSlots,
        _setSelectedIndex,
      });
      //Empty slot
      slots[_selectedIndex].status = "empty";
      slots[_selectedIndex].nft = null;

      //Auto Equip
      let rarityWeights = slots.map((slot) => {
        if (slot.status == "filled") {
          console.log("onUnequip 3", {
            slot,
          });
          return RARITY_WEIGHTS[slot?.nft?.rarity?.toLowerCase()];
        }

        return -1;
      });

      let selectedIndex = -1;
      let highestRarity = Math.max(...rarityWeights);
      if (highestRarity > 0)
        selectedIndex = rarityWeights.indexOf(highestRarity);

      console.log("onUnequip 2", {
        selectedIndex,
        highestRarity,
        rarityWeights,
      });
      _setGarageSlots([...slots]);

      let selected = [];

      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        if (slot.status == "filled") {
          selected.push(slot.nft);
        }
      }

      _setSelectedSlots([...selected]);
      _setSelectedIndex(selectedIndex);
    } catch (error) {
      console.error("onUnequip", { error });
    }
  };

  const onUnequipMechanic = () => {
    onUnequip(
      garageMechanicSlots,
      selectedMechanicIndex,
      setGarageMechanicSlots,
      setSelectedMechanics,
      setSelectedMechanicIndex
    );
  };

  const onUnequipCar = () => {
    onUnequip(
      garageSlots,
      selectedCarIndex,
      setGarageSlots,
      setSelectedCars,
      setSelectedCarIndex
    );
  };

  const updateMechanicSlot = (index, nft) => {
    const slots = [...garageMechanicSlots];
    slots[index].status = "filled";
    slots[index].nft = nft;
    setGarageMechanicSlots([...slots]);
    if (selectedMechanicIndex < 0) {
      setSelectedMechanicIndex(index);
    }

    let selected = [];

    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];
      if (slot.status == "filled") {
        selected.push(slot.nft);
      }
    }

    setSelectedMechanics([...selected]);
  };

  const returnToLobby = async () => {
    console.log("Return To Lobby");
    switchMenu("Main");
  };

  useEffect(() => {
    /**
     *
     */
    console.log("debug-garage garageMechanicSlots 0", {
      ...garageSlots,
    });
    let ctr = 0;
    let _carSlots = garageSlots.reduce((slotsResult, slot) => {
      if (slot.nft) slotsResult[ctr] = slot.nft;
      ctr++;
      return slotsResult;
    }, {});
    console.log("debug-garage garageMechanicSlots 1", {
      ..._carSlots,
    });
    loadCarSlots(_carSlots, selectedMechanics.length);
    console.log("debug-garage garageMechanicSlots 2", {
      ..._carSlots,
    });
  }, [garageMechanicSlots]);

  useEffect(() => {
    console.log("garageUseEffect");
    let _carSlots = player?.garage?.cars?.slots;
    let _mechanicSlots = player?.garage?.mechanics?.slots;
    let _activeCarIndex = player?.garage?.cars?.active;
    let _activeMechanicIndex = player?.garage?.mechanics?.active;

    window.mechanicSlots = _mechanicSlots;
    console.log("componentDidMount 1", {
      _activeMechanicIndex,
      _activeCarIndex,
    });

    setEquippedMechanic(_mechanicSlots?.[_activeMechanicIndex]);
    setEquippedCar(_carSlots?.[_activeCarIndex]);

    setSelectedMechanicIndex(_activeMechanicIndex);
    setSelectedCarIndex(_activeCarIndex);

    loadMechanicSlots(_mechanicSlots);
    console.log("aaaa", { _mechanicSlots, _carSlots });
    loadCarSlots(
      _carSlots,
      Object.keys(ColyseusHelper.mapSchemaToArrayObject(_mechanicSlots)).length
    );

    console.log("garageUseEffect", {
      _activeMechanicIndex,
      _activeCarIndex,
      _mechanicSlots,
      _carSlots,
    });

    setMechanicsPool([...player.mechanics]);
    setCarPool([...player.cars]);
  }, []);

  useEffect(() => {
    //console.log("[getDerivedStateFromProps] 1", { selectedCarIndex });
    setEquippedCar(garageSlots[selectedCarIndex]?.nft);
  }, [selectedCarIndex, garageSlots]);
  useEffect(() => {
    setEquippedMechanic(garageMechanicSlots[selectedMechanicIndex]?.nft);
  }, [selectedMechanicIndex]);

  const loadMechanicSlots = (slotsSource) => {
    let slots = [];
    let equipped = [];

    for (let i = 0; i < MAX_INVENTORY_SLOTS.mechanics; i++) {
      let slot = new Slot("locked", null);

      let garageSlot = slotsSource[i];
      if (garageSlot) {
        slot.status = "filled";
        slot.nft = garageSlot;
        equipped.push(garageSlot);
      } else {
        slot.status = "empty";
      }

      slots.push(slot);
    }

    console.log({ slots });
    setGarageMechanicSlots(slots);
    setSelectedMechanics(equipped);
  };

  const loadCarSlots = (slotsSource, mechanicsEquipped) => {
    let slots = [];
    let equipped = [];

    let capacity = DEFAULT_INVENTORY_SLOTS.cars + mechanicsEquipped;
    console.log("garageSlots d 0", {
      DEFAULT_INVENTORY_SLOTS,
      capacity,
      mechanicsEquipped,
    });

    console.log("garageSlots d 1", { slotsSource, capacity });
    for (let i = 0; i < MAX_INVENTORY_SLOTS.cars; i++) {
      let slot = new Slot("locked", null);

      if (i < capacity) {
        let garageSlot = slotsSource[i];
        console.log("garageSlots d 2", { garageSlot, i });
        if (garageSlot) {
          slot.status = "filled";
          slot.nft = garageSlot?.nft || garageSlot;
          equipped.push(garageSlot?.nft || garageSlot);
        } else {
          slot.status = "empty";
        }
      }

      slots.push(slot);
    }

    console.log("setGarageSlots", { slots });
    setGarageSlots(slots);
    setSelectedCars(equipped);
  };

  window.driverSlots = player.office.drivers.slots;
  console.log("garageSlots d 3", { garageSlots });
  return (
    <>
      <div
        id="backdrop"
        onClick={(event) => {
          event.preventDefault();
          if (event.target === event.currentTarget) {
            returnToLobby();
          }
        }}
      />
      <div className="inventory-container">
        <div className="inventory-details">
          <SelectedSpot
            selectedNft={equippedMechanic}
            id="equipped"
            type="mechanic"
          />
          <div className="inventory-center">
            <StatPanel
              title={"Garage"}
              slots={garageSlots}
              showCarSelector={showCarSelector}
              garageSlotIndex={garageSlotIndex}
              selectedMechanic={selectedMechanic}
              player={player}
              selectedCars={selectedCars}
              selectedDrivers={Object.keys(
                ColyseusHelper.mapSchemaToArrayObject(
                  player.office.drivers.slots
                )
              ).map((key) => player.office.drivers.slots[key])}
              equippedCar={equippedCar}
              equippedMechanic={equippedMechanic}
              equippedDriver={player.selectedDriver}
              equippedManager={player.selectedManager}
              onClose={returnToLobby}
              inventoryData={{
                cars: {
                  //We convert garageSlots from array into object with slot keys
                  slots: garageSlots
                    .filter((slot) => slot.status == "filled")
                    .reduce(
                      (slotsResult, slot) => (
                        (slotsResult[
                          garageSlots.findIndex(
                            (_slot) =>
                              _slot?.nft?.asset_id == slot?.nft?.asset_id
                          )
                        ] = slot.nft),
                        slotsResult
                      ),
                      {}
                    ),
                  active: selectedCarIndex,
                },
                mechanics: {
                  //We convert garageSlots from array into object with slot keys
                  slots: garageMechanicSlots
                    .filter((slot) => slot.status == "filled")
                    .reduce(
                      (slotsResult, slot) => (
                        (slotsResult[
                          garageMechanicSlots.findIndex(
                            (_slot) => _slot?.nft?.asset_id == slot.nft.asset_id
                          )
                        ] = slot.nft),
                        slotsResult
                      ),
                      {}
                    ),
                  active: selectedMechanicIndex,
                },
              }}
              inventoryDataKeys={["mechanics", "cars"]}
            />
            <UpgradeSlotsDisplay className="inventory-upgrades" />
          </div>
          <SelectedSpot selectedNft={equippedCar} id="equipped" type="car" />
        </div>
        <div className="inventory-slots">
          <InventorySlotsDisplay
            nftsAvailable={mechanicsPool.length - selectedMechanics.length}
            slots={garageMechanicSlots}
            showNftSelector={showMechanicSelector}
            container_type="inventory_left"
            setSelectedNftIndex={setSelectedMechanicIndex}
            selectedNftIndex={selectedMechanicIndex}
            onUnequip={onUnequipMechanic}
          />
          <InventorySlotsDisplay
            nftsAvailable={carPool.length - selectedCars.length}
            slots={garageSlots}
            showNftSelector={showCarSelector}
            container_type="inventory_right"
            setSelectedNftIndex={setSelectedCarIndex}
            selectedNftIndex={selectedCarIndex}
            onUnequip={onUnequipCar}
          />
        </div>
        {carSelector && (
          <NftSelectionModal
            nfts={carPool}
            selectedNfts={selectedCars}
            onSelect={(car) => {
              updateSlot(garageSlotIndex, car);
              showCarSelector(false);
            }}
            closeModal={() => {
              showCarSelector(false);
            }}
          />
        )}

        {mechanicSelector && (
          <NftSelectionModal
            nfts={mechanicsPool}
            selectedNfts={selectedMechanics}
            onSelect={(mechanic) => {
              console.log("SelectMechanic", { mechanic });
              updateMechanicSlot(garageMechanicSlotIndex, mechanic);
              showMechanicSelector(false);
            }}
            closeModal={() => {
              showMechanicSelector(false);
            }}
          />
        )}
      </div>
    </>
  );
};
