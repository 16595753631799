import React, { useContext, useEffect, useState } from "react";
import { GameContext, boostEngineSfxCallback } from "../../network/Connector";
import Button from "../atom/Button";
import "./frame.css";

export default ({ onClick, time, text = "JOIN NOW" }) => {
  const { roomState } = useContext(GameContext);

  const minutes = Math.floor(roomState[time] / 60);
  let seconds = roomState[time] % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  useEffect(() => {
    if (time == "roundStartTime") {
      if (roomState[time] == 1) {
        boostEngineSfxCallback.call();
      }
    }
  }, [roomState]);

  return <CountdownTimer {...{ onClick, minutes, seconds, text }} />;
};

function CountdownTimer({
  onClick,
  hours = 0,
  minutes = 0,
  seconds = 0,
  text,
}) {
  if (typeof hours === "string") hours = parseInt(hours);
  if (typeof minutes === "string") minutes = parseInt(minutes);
  if (typeof seconds === "string") seconds = parseInt(seconds);

  const hoursDisplay =
    hours < 10 ? (hours > 0 ? `0${hours}:` : ``) : `${hours}:`;
  const minutesDisplay =
    minutes < 10 ? (minutes > 0 ? `0${minutes}:` : ``) : `${minutes}:`;
  const secondsDisplay =
    seconds < 10 ? `0${seconds}` : seconds;

  return (
    <div className="countdown_timer" id="timerframe" onClick={onClick}>
      <Button className="countdown_timer_button">
        <label>{text}</label>
        <div className="countdown_text">
          {hoursDisplay}
          {minutesDisplay}
          {secondsDisplay}
        </div>
      </Button>
    </div>
  );
}

export { CountdownTimer };
