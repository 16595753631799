import React from "react";
import { LongButton } from "./Button";
import returnIcon from "../../static/return-icon.png";
import "./inventoryHeader.css";

export default ({
  className = "",
  title,
  onReturn,
  onSave = null,
  onSaveText,
  children,
}) => (
  <div className={"inventory-header " + className}>
    <div className="inventory-title">{title}</div>
    <div className="inventory-buttons">
      {children}
      {onSave != null && <LongButton onClick={onSave}>{onSaveText}</LongButton>}
      <LongButton
        className="inventory-header-return"
        onClick={onReturn}
        icon={returnIcon}
      >
        Return
      </LongButton>
    </div>
  </div>
);
