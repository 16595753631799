import React, { useContext, useState,useMemo, useEffect } from "react";
import { GameContext } from "../../../../../network/Connector";
import { AppContext } from "../../../../AppContext.js";
import "./marketStyles.css";
import { ATOMIC_HUB_LINK } from "../../../../../Constants";
import InventoryHeader from "../../../../atom/InventoryHeader";
import { LongButton } from "../../../../atom/Button";
import DerbyTokenIcon from "../../../../../static/icons/Token.png";
import AtomichubIcon from "../../../../../static/icons/Atomichub.png";
import TrophyIcon from "../../../../../static/icons/Skill.png";
import MarketItem from "./MarketItem";

function Market() {
  /**
   * @type {{room:Colyseus.Room}}
   */
  const { room, player } = useContext(GameContext);
  const { switchMenu } = useContext(AppContext);
  const [category, setCategory] = useState("All");
  const [MarketItemsDisplay, setMarketItemsDisplay] = useState([]);

  const returnToLobby = async () => {
    console.log("Return To Lobby");
    switchMenu("Main");
  };

  useEffect(() => {
    
    const display = [];
    room.state.market.items.forEach((_item) => {
      if (
        category == "All" ||
        _item.name.toLowerCase().includes(category.slice(0, -1).toLowerCase())
      ) {
        console.log({ _item });
        if (_item.remaining > 0) {
          display.push(
            <MarketItem key={"market_item_" + _item.template_id} {..._item} />
          );
        }
      }
    });

    setMarketItemsDisplay(display);

    }, [room.state.market.items, category])

  const topCategories = [
    "All",
    "Cars",
    "Drivers",
    "Managers",
    "Mechanics",
    "Upgrades",
    "Crash art",
  ];

  const TopButton = ({ value }) => (
    <LongButton
      className={
        "inventory-header-return " + (category == value ? "highlight-on" : "")
      }
      onClick={() => setCategory(value)}
      small
    >
      {value}
    </LongButton>
  );

  return (
    <>
      <InventoryHeader title={"Market"} onReturn={() => returnToLobby()}>
        <LongButton
          onClick={() => {
            window.open(
              `${ATOMIC_HUB_LINK}/explorer/collection/crashemtest3`,
              "_blank"
            );
          }}
          icon={AtomichubIcon}
          className="market-wallet-display market-top-button"
          style={{
            cursor: "pointer",
            background: "#14192e",
            color: "white",
          }}
        >
          AtomicHub
        </LongButton>
        <LongButton
          disabled={true}
          icon={DerbyTokenIcon}
          className="market-wallet-display market-top-button"
        >
          &nbsp;
          {player.tokenAmount.split(" ")[0]}
        </LongButton>
        <LongButton
          disabled={true}
          icon={TrophyIcon}
          className="market-wallet-display market-top-button"
        >
          &nbsp;
          {player.trophyAmount}
        </LongButton>
      </InventoryHeader>
      <div
        id="backdrop"
        onClick={(event) => {
          event.preventDefault();
          if (event.target === event.currentTarget) {
            returnToLobby();
          }
        }}
      ></div>

      <div className="inventory-container market_panel_container">
        <div className="market-tabs">
          {topCategories.map((value, i) => (
            <TopButton key={i} value={value} />
          ))}
        </div>
        <div className="market_panel">
          {MarketItemsDisplay}
        </div>
      </div>
    </>
  );
}

export default Market;
