import React, { useContext } from "react";
import { GameContext } from "../../../network/Connector";
import NextRoundWait from "./NextRoundWait";
import PlayingScreen from "./PlayingScreen";
import UnplayableScreen from "./UnplayableScreen";
import WaitingScreen from "./WaitingScreen";
import WinnerScreen from "./WinnerScreen";
import SfxHandler from "./SfxHandler";
import { AppContext } from "../../AppContext";
import LobbyScreens from "./LobbyScreens";
import { GameStates } from "../../../Constants.js";
import { LongButton } from "../../atom/Button";
import VolumeControl from "../../atom/VolumeControl";
import DerbyTokenIcon from "../../../static/Derby_Mascot_250x250.png";
import MarketIcon from "../../../static/icons/market-icon.png";
import Market from "./LobbyScreens/Market";
import ReactTooltip from "react-tooltip";

const RewardsPool = () => {
  const { player, room } = useContext(GameContext);

  return (
    <>
      <LongButton
        className="rewardpool disable-highlight"
        icon={DerbyTokenIcon}
        style={{
          cursor: "auto",
        }}
        tooltip={"Rewards Pool"}
        tooltipHolder={"rewards-tip"}
      >
        &nbsp;
        {player.playerStatus === "winner" || player.playerStatus === "roundEnd"
          ? player.rewards.pool
          : room.state.joinedPlayer}
      </LongButton>

      <ReactTooltip
        className="tooltip"
        id="rewards-tip"
        aria-haspopup="true"
      ></ReactTooltip>
    </>
  );
};

export const MarketplaceButton = () => {
  const { switchMenu } = useContext(AppContext);

  const openMarket = async () => {
    console.log("Open Market");
    switchMenu("Market");
  };

  return (
    <>
      <LongButton
        onClick={openMarket}
        className="ingame-marketplace"
        icon={MarketIcon}
        tooltip="Marketplace"
        tooltipHolder={"marketplace-button-tip"}
      />

      <ReactTooltip
        className="tooltip"
        id="marketplace-button-tip"
        aria-haspopup="true"
      ></ReactTooltip>
    </>
  );
};

const StatsData = (player) => {
  let totalUpgradeStats = {
    durability: 0,
    damage: 0,
    initiative: 0,
    luck: 0,
  };

  for (let i = 1; i <= 4; i++) {
    const upgrade = player["selectedPup" + i];
    //console.log("getDerivedStateFromProps", { upgrade });
    if (upgrade) {
      //Loop through attributes and add to total
      for (const attr in totalUpgradeStats) {
        if (Object.hasOwnProperty.call(totalUpgradeStats, attr)) {
          totalUpgradeStats[attr] += upgrade[attr];
        }
      }
    }
  }

  let skill =
    parseInt(player.selectedDriver.skill) +
    parseInt(totalUpgradeStats.initiative);
  let luck =
    parseInt(player.selectedDriver.luck) +
    parseInt(totalUpgradeStats.luck * 10);
  let reward = player.selectedDriver.reward;

  let damage =
    parseInt(player.selectedCar.durability) +
    parseInt(totalUpgradeStats.damage);
  let durability =
    parseInt(player.selectedCar.durability) +
    parseInt(totalUpgradeStats.durability);

  let display = {
    skill,
    damage,
    durability,
    luck,
    reward,
  };

  console.log("[StatsData]", display);

  return display;
};

export default () => {
  const { activeMenu } = useContext(AppContext);
  const { player, room } = useContext(GameContext);
  const {
    sfxStartEngine,
    sfxHardCrash,
    sfxLightCrash,
    sfxRollEngine,
    sfxBoostEngine,
    sfxExplosion,
    sfxRunningEngine,
    sfxGlassBreak,
    sfxNewChallenger,
  } = useContext(AppContext);
  const getScreen = () => {
    console.log({ gameState: room.state.gameState, GameStates });

    //#region post-game screen
    
    if (
      room.state.gameState == GameStates.waiting ||
      room.state.gameState == GameStates.results
    ) {
      if (player.playerStatus === "waiting")
        return player.cars.length > 0 && player.drivers.length > 0 ? (
          <LobbyScreens />
        ) : (
          <UnplayableScreen />
        );
        
      if (player.playerStatus === "entered")
        return <WaitingScreen {...StatsData(player)} />;

      if (player.playerStatus === "winner") return <WinnerScreen />;
      if (player.playerStatus === "roundEnd") return <NextRoundWait />;

      //No handler for player stuck in playing/preRoundEnd state
      //This is to catch the players stuck in playing state
      //Should we just update state here?
      console.log("This shouldn't happen: ", { gameState: room.state.gameState, playerState: player.playerStatus });
      return  <LobbyScreens /> 
    }
    //#endregion


    if (
      room.state.gameState == GameStates.running ||
      room.state.gameState == GameStates.preresults
    ) {
      if (player.playerStatus === "winner") return <WinnerScreen />;
      if (player.playerStatus === "roundEnd") return <NextRoundWait />;

      return <PlayingScreen {...StatsData(player)} />;
    }

    console.log("No screen yet", { gameState: room.state.gameState, playerState: player.playerStatus});
    return <div>Loading...</div>;
  };

  return (
    <div className="container" style={{ position: "relative" }}>
      <SfxHandler
        sfxStartEngine={sfxStartEngine}
        sfxHardCrash={sfxHardCrash}
        sfxRollEngine={sfxRollEngine}
        sfxLightCrash={sfxLightCrash}
        sfxBoostEngine={sfxBoostEngine}
        sfxExplosion={sfxExplosion}
        sfxRunningEngine={sfxRunningEngine}
        sfxGlassBreak={sfxGlassBreak}
        sfxNewChallenger={sfxNewChallenger}
      />

      {(player.playerStatus === "entered" ||
        player.playerStatus === "winner" ||
        player.playerStatus === "roundEnd") && (
        <div className="rewardpool_container ">
          <RewardsPool />
        </div>
      )}
      <VolumeControl />
      {activeMenu === "Market" && <Market />}
      {getScreen()}
    </div>
  );
};
