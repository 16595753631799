import React, { useEffect, useState, useContext } from "react";
import default_crash_art from "../../../../static/default_crash_art.png";
import useInterval from "../../../../utils/CustomHooks/useInterval";
import { IPFS_BASE_URL } from "../../../../Constants";
import { GameContext } from "../../../../network/Connector";

const CrashArtDisplay = () => {
  const { player } = useContext(GameContext);
  const [imagePool, setImagePool] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [crashartsText, setCrashartsText] = useState("");

  const intervals = 5000;

  useEffect(() => {
    setImagePool([default_crash_art]);
  }, []);
  useEffect(() => {
    if (crashartsText != player.crasharts.join(",")) {
      //console.log("CrashArts Changed");
      setImagePool([
        default_crash_art,
        ...player.crasharts.map((art) => {
          return IPFS_BASE_URL + art.img;
        }),
      ]);
      setCrashartsText(player.crasharts.join(","));
    }
  }, [player.crasharts]);

  useInterval(() => {
    if (currentIndex >= imagePool.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((_cur) => {
        return _cur + 1;
      });
    }
  }, intervals);

  return (
    <div className="crt">
      <img id="crash_art_display" src={imagePool[currentIndex]} />
    </div>
  );
};

export default CrashArtDisplay;
