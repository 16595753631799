import React from "react";
import Button from "../atom/Button";
import buttonmain from "../../static/btn_main.png";
import DamageDisplay from "./DamageComponents/DamageDisplay";
import "./frame.css";

export const SpecialFrame = ({
  onMouseDown = null,
  maintext,
  subtext,
  style,
}) => (
  <div
    className="message_frame special_frame "
    id="messageframe"
    style={{ backgroundImage: `url(${buttonmain})`, ...style }}
  >
    <Button
      onClick={onMouseDown}
      className="special_frame_button"
      style={{ backgroundImage: "none" }}
    >
      <span className="message_frame_maintext">{maintext}</span>
    </Button>
    <div className="message_frame_custom">
      <small className="message_frame_subtext">{subtext}</small>
    </div>
  </div>
);

export default ({
  onClick = null,
  onMouseDown = null,
  maintext,
  subtext,
  noCursor,
  children,
  style,
}) => (
  <div
    onClick={onClick}
    onMouseDown={onMouseDown}
    className="message_frame"
    id="messageframe"
    style={style}
  >
    <DamageDisplay />
    <Button className={noCursor && "no-cursor"}>
      {maintext ? (
        <span className="message_frame_maintext">
          {maintext}
          <br />
          <small className="message_frame_subtext">{subtext}</small>
        </span>
      ) : (
        children
      )}
    </Button>
  </div>
);
