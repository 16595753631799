const eos = {
  chainId: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "testnet.wax.pink.gg",
      port: "443",
    },
    // {
    //   protocol: "https",
    //   host: "waxtestnet.greymass.com",
    //   port: "443",
    // },
    //Greymass is down
    // {
    //   protocol: "https",
    //   host: "wax-test.eosdac.io",
    //   port: "443",
    // },
  ],
};


let appName = "CRASHDERBYIO_STAGE";

let WSSERVER = "ws://stage.crashderby.io:2567";
if (process.env.BUILD_FOR == "DEV") {
  WSSERVER = "ws://localhost:2567";
  appName = "CRASHDERBYIO_LOCAL";
}
if(process.env.BUILD_FOR=='community')
{
  WSSERVER = "wss://testnet.crashderby.io/serve";
  appName = "CRASHDERBYIO_community"; //redeploy-test
}

console.log(process.env.BUILD_FOR);
console.log({WSSERVER,appName});


const gameRoomName = "DerbyRoom";
const GAME_CONTEXT = "GameContext";
const MINIMUM_PLAYERS = 3;

const IPFS_BASE_URL = "https://cloudflare-ipfs.com/ipfs/";
const IPFS_ENDPOINTS = [
  "https://cloudflare-ipfs.com/ipfs/%IPFS_HASH%",
  "https://%IPFS_HASH%.ipfs.infura-ipfs.io/"
];

/**
 * 
 * STAGE
const WAX_DERBY_CONTRACT_ACCOUNT = "wxdrbycntrct";
LOCAL
 * const WAX_DERBY_CONTRACT_ACCOUNT = "wxdrbylocal2";
 */

let WAX_DERBY_CONTRACT_ACCOUNT = "wxdrbystage1";
if (process.env.BUILD_FOR == "DEV") {
  WAX_DERBY_CONTRACT_ACCOUNT = "wxdrbylocal2";
}
if(process.env.BUILD_FOR=='community')
{
  WAX_DERBY_CONTRACT_ACCOUNT = "wxdrbycntrct";
}

let WAX_DERBY_MARKET_ACCOUNT = "wxdrbymarkt2";
if (process.env.BUILD_FOR == "DEV") {
  WAX_DERBY_MARKET_ACCOUNT = "wxdrbymarkt1";
}
if(process.env.BUILD_FOR=='community')
{
  WAX_DERBY_MARKET_ACCOUNT = "wxdrbymarkt4";
}

const DERBY_TOKEN_SYMBOL = "DERBY";
const DERBY_TOKEN_DISPLAY = "DERBY";
let DERBY_TOKEN_ACCOUNT = "derbytokenlo";

if (process.env.BUILD_FOR == "DEV") {
  DERBY_TOKEN_ACCOUNT = "derbytokenst";
}
if(process.env.BUILD_FOR=='community')
{
  DERBY_TOKEN_ACCOUNT = "drybetoken23";
}

const ATOMIC_HUB_LINK =
  "https://wax-test.atomichub.io";

const DEFAULT_TRANSACTION_CONFIG = { blocksBehind: 3, expireSeconds: 30 };
const MAX_INVENTORY_SLOTS = {
  cars: 8,
  drivers: 8,
  managers: 6,
  mechanics: 6,
};

const DEFAULT_INVENTORY_SLOTS = {
 cars: 2,
 drivers: 2,
 managers: 6,
 mechanics: 6 
}

const ENERGY_MULTIPLIER = {
  durability: 10,
  skill: 7,
}

//Update server equivalent when updating this
const GameStates = {
  waiting: "waiting",
  running: "running",
  preresults: "preresults",
  results: "results",
};

//Update server equivalent when updating this
const PlayerStates = {
  waiting: "waiting",
  entered: "entered",
  playing: "playing",
  winner: "winner",
  preRoundEnd: "preRoundEnd",
  roundEnd: "roundEnd",
};

const RARITY_WEIGHTS = {
  common: 1,
  uncommon: 2,
  rare: 3,
  epic: 4,
  legendary: 5,
  mythic: 6,
}

export {
  eos,
  appName,
  GameStates,
  PlayerStates,
  WSSERVER,
  gameRoomName,
  GAME_CONTEXT,
  MINIMUM_PLAYERS,
  ATOMIC_HUB_LINK,
  WAX_DERBY_CONTRACT_ACCOUNT,
  WAX_DERBY_MARKET_ACCOUNT,
  DEFAULT_TRANSACTION_CONFIG,
  DERBY_TOKEN_SYMBOL,
  DERBY_TOKEN_ACCOUNT,
  MAX_INVENTORY_SLOTS,
  DEFAULT_INVENTORY_SLOTS,
  ENERGY_MULTIPLIER,
  RARITY_WEIGHTS,
  DERBY_TOKEN_DISPLAY,
  IPFS_BASE_URL,
  IPFS_ENDPOINTS
};
