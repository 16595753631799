import React from "react";

import crack_1_1 from "../../../static/cracks/Crack_1_1.png";
import crack_1_2 from "../../../static/cracks/Crack_1_2.png";
import crack_1_3 from "../../../static/cracks/Crack_1_3.png";

import crack_2_1 from "../../../static/cracks/Crack_2_1.png";
import crack_2_2 from "../../../static/cracks/Crack_2_2.png";
import crack_2_3 from "../../../static/cracks/Crack_2_3.png";

import crack_3_1 from "../../../static/cracks/Crack_3_1.png";
import crack_3_2 from "../../../static/cracks/Crack_3_2.png";
import crack_3_3 from "../../../static/cracks/Crack_3_3.png";

import crack_4_1 from "../../../static/cracks/Crack_4_1.png";
import crack_4_2 from "../../../static/cracks/Crack_4_2.png";
import crack_4_3 from "../../../static/cracks/Crack_4_3.png";

import { glassBreakSfxCallback } from "../../../network/Connector";
import "./damage.css";

const crack_variants = [
  {
    variant_1: crack_1_1,
    variant_2: crack_1_2,
    variant_3: crack_1_2,
    left_offset: -20,
    top_offset: -50,
  },
  {
    variant_1: crack_2_1,
    variant_2: crack_2_1,
    variant_3: crack_2_1,
    left_offset: -20,
    top_offset: -60,
  },
  {
    variant_1: crack_4_2,
    variant_2: crack_4_2,
    variant_3: crack_4_3,
    left_offset: -20,
    top_offset: -50,
  },
];

class DamageCrack extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    glassBreakSfxCallback.call();
  }

  render() {
    const { variant, remaining_health } = this.props;

    const crack = crack_variants[variant];
    const damage_severity =
      remaining_health <= 25 ? 3 : remaining_health <= 50 ? 2 : 1;

    const crack_img = crack["variant_" + damage_severity];
    const left = this.props.position.left + crack.left_offset;
    const top = this.props.position.top + crack.top_offset;

    return (
      <div>
        {remaining_health >= 75 ? (
          ""
        ) : (
          <img
            className="damagecrack"
            src={crack_img}
            style={{
              left,
              top,
            }}
          />
        )}
      </div>
    );
  }
}

export { crack_variants };

export default DamageCrack;
