import React from "react";
import { GameContext } from "../../../../../network/Connector";
import fillerCard from "../../../../../static/popup_filler.png";
import lockedCard from "../../../../../static/locked_popup.png";
import lock from "../../../../../static/close.png";
import _, { fill } from "lodash";
import "./nftSlotStyles.css";
import NftImage from "../../../../atom/NftImage";

const NftSlot = ({
  containerStyle,
  imageStyle,
  nft,
  showSelector,
  withChange = true,
  selected = false,
}) => {
  var thumbnail = nft?.img || "";

  var thumbnailLink = "https://cloudflare-ipfs.com/ipfs/" + thumbnail;
  console.log("NftSlot", { nft });
  //If there is no thumbnail, set default fillerCard
  if (thumbnail == "") {
    thumbnailLink = fillerCard;
  }

  const getThumbnail = () => (
    <NftImage
      className="nft_slot_card_display"
      style={imageStyle}
      src={thumbnailLink}
      hash={nft?.img}
    />
  );

  const workingPopup = () => {
    showSelector(true);
  };

  return (
    <div style={containerStyle} className="nft_slot" onClick={workingPopup}>
      {getThumbnail()}
      {withChange && (
        <span className="nft_slot_label selection_highlight">
          {selected ? <img src={lock} /> : ""}
        </span>
      )}
    </div>
  );
};

const LockedNftSlot = () => {
  var thumbnailLink = lockedCard;

  const getThumbnail = () => {
    return <img className="nft_slot_card_display" src={thumbnailLink} />;
  };

  const disabledPopup = () => {
    alert("Unlock more slots by getting a better manager.");
  };

  return (
    <div className="nft_slot" onClick={disabledPopup}>
      {getThumbnail()}
    </div>
  );
};

export { NftSlot, LockedNftSlot };
