import { sendMessage } from "./Connector.js";

export default class Ping {
  constructor() {
    this.isActive = true;

    this.pingTime = 20;
    this.pingTimer = null;

    //DC Part
    this.dcTime = 0;
    this.dcTimer = null;
    this.lastPongTime = new Date();

    this.room = null;

    this.startPingTimer();
  }

  //Ping Pong Start

  clearPingTimer = () => {
    clearInterval(this.pingTimer);
    this.pingTimer = null;
  };

  onPingTimer = async () => {
    this.pingTime--;
    //console.log("[pingTimer] tick", this.pingTime);

    if (this.pingTime <= 0) {
      this.clearPingTimer();
      await this.ping();
    }
  };

  resetPingPong = () => {
    this.lastPongTime = new Date();
    this.isPingPongActive = true;
    this.dcTime = 0;

    if (this.dcTimer) this.clearDcTimer();

    if (this.pingTimer) this.clearPingTimer();

    this.pingTime = 20;
    this.startPingTimer();
  };

  startPingTimer = () => {
    this.pingTimer = setInterval(this.onPingTimer, 1000);
  };

  clearDcTimer = () => {
    clearInterval(this.dcTimer);
    this.dcTimer = null;
  };

  onDcTimer = async () => {
    //console.log("[dcTimer] tick", this.dcTime);
    this.dcTime++;

    //Should I remove this and just rely on DC Time?
    if (this.dcTime >= 5) {
      this.isPingPongActive = false;
    }
  };

  startDcTimer = () => {
    this.dcTimer = setInterval(this.onDcTimer, 1000);
  };

  onPong = async () => {
    //Reset timer and update last pongTime
    console.log("[ping/pong] received at: ", new Date());
    this.resetPingPong();
  };

  ping = async () => {
    try {
      console.log("[ping] sent at: ", new Date());
      if (this.room == null) {
        console.warn("Room not found");
        return;
      }
      sendMessage(async () => {
        await this.room.send("ping");
      });
      this.startDcTimer();
    } catch (e) {
      console.log("[ping] Error", e);
    }
  };

  //Ping Pong End
}
