import React from "react";
import "./damage.css";

class DamageText extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("debug-1 spawned damage");

    setTimeout(() => {
      this.props.onExpire(this.props.addedAt);
    }, 2000);
  }

  render() {
    const { damage } = this.props;
    return (
      <span id="damage-text" className="damage-fade">
        -{damage}
      </span>
    );
  }
}

export default DamageText;
