import React, { useContext, useMemo } from "react";
import EventPanel from "./components/EventPanel";
import NoticePanel from "./components/NoticePanel";
import MessageFrame from "../MessageFrame";
import { GameContext } from "../../../network/Connector";
import "./screen.css";
import DamageCracksDisplay from "../DamageComponents/DamageCracksDisplay";

import DamageIcon from "../../../static/icons/Damage.png";
import DurabilityIcon from "../../../static/icons/Durability.png";
import LuckIcon from "../../../static/icons/Luck.png";
import SkillIcon from "../../../static/icons/Skill.png";
import RewardIcon from "../../../static/icons/Reward.png";
import { MarketplaceButton } from "./GameScreen";

const SpectatorMessageFrame = () => {
  const { player, roomState } = useContext(GameContext);
  return (
    <MessageFrame
      time="roundEndTime"
      maintext={roomState.alivePlayers}
      subtext={roomState.alivePlayers > 1 ? "Players Left" : "Player Left"}
      noCursor
    />
  );
};

const PlayerMessageFrame = () => {
  const { player, room } = useContext(GameContext);
  return player.health > 0 ? (
    <MessageFrame
      time="roundEndTime"
      maintext={player.health}
      subtext={"HP Left"}
      noCursor
    />
  ) : (
    <MessageFrame time="roundEndTime" noCursor maintext={player.result.rank} />
  );
};

export default (props) => {
  const { player, roomState } = useContext(GameContext);

  const activeMessageFrame = useMemo(() => {
    return player.playing ? <PlayerMessageFrame /> : <SpectatorMessageFrame />;
  }, [player.playing]);

  return (
    <>
      {activeMessageFrame}
      <div className="container_row">
        
      <div className="ingame-marketplace_container ">
          <MarketplaceButton />
        </div>
        <EventPanel events={roomState.messages} />
        <NoticePanel>
          <DamageCracksDisplay leftBound={400} topBound={260} />
          <div>
            Cars are
            <div className="notice_larger">Crashing</div>
            INTO THIS ROUND
          </div>
          {player.playing ? (
            <div className="screen_stats">
              <strong>Your stats:</strong>
              <br />
              <img src={SkillIcon} className="battle_screen_icons" />:{" "}
              {props.skill}
              &nbsp;|&nbsp;{" "}
              <img src={DamageIcon} className="battle_screen_icons" /> :{" "}
              {props.damage}
              &nbsp;|&nbsp;{" "}
              <img
                src={DurabilityIcon}
                className="battle_screen_icons"
              /> : {props.durability}
              &nbsp;|&nbsp;{" "}
              <img src={LuckIcon} className="battle_screen_icons" /> :{" "}
              {props.luck}
              &nbsp;|&nbsp;{" "}
              <img src={RewardIcon} className="battle_screen_icons" /> :{" "}
              {props.reward}%
            </div>
          ) : (
            ""
          )}
          <div>
            {roomState.alivePlayers} out of {roomState.joinedPlayer} alive
          </div>
        </NoticePanel>
      </div>
    </>
  );
};
