import React, { useEffect, useState } from "react";
import fallback from "../../static/nft_placeholder.png";
import loadingImg from "../../static/loading_card.png";

/**
 *
 * @param {Prop} props
 * @returns Image
 * @description handles fallback image in cases where nft images fail to load
 */
export default function FallbackedImage(props) {
  const [imgSrc, setImgSrc] = useState(props.src);
  const [oldImgSrc, setOldImgSrc] = useState(props.src);
  const [renderingDefault, setRenderingDefault] = useState(true);

  const onError = () => {
    console.log("onError", { fallback, imgSrc });
    if (renderingDefault) {
      setImgSrc(props.fallbackimage);
      setRenderingDefault(false);
    } else {
      setImgSrc(oldImgSrc);
      setRenderingDefault(true);
    }
  };

  useEffect(() => {
    if (oldImgSrc !== props.src) {
      setOldImgSrc(props.src);
      setImgSrc(props.src);
    }
  }, [props.src]);

  return (
    <>
      <img
        {...props}
        src={imgSrc}
        onError={onError}
        style={{
          ...props?.style,
        }}
      />
    </>
  );
}
