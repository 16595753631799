import React, { useContext, useState, useEffect } from "react";
import MessageFrame from "../MessageFrame";
import EventPanel from "./components/EventPanel";
import NoticePanel from "./components/NoticePanel";
import TimerFrame from "../TimerFrame";
import {
  GameContext,
  startEngineSfxCallback,
} from "../../../network/Connector";
import { MINIMUM_PLAYERS } from "../../../Constants";

import DamageIcon from "../../../static/icons/Damage.png";
import DurabilityIcon from "../../../static/icons/Durability.png";
import LuckIcon from "../../../static/icons/Luck.png";
import SkillIcon from "../../../static/icons/Skill.png";
import RewardIcon from "../../../static/icons/Reward.png";
import { MarketplaceButton } from "./GameScreen";

export default (props) => {
  const { roomState, player } = useContext(GameContext);

  const [playersNeeded, setPlayersNeeded] = useState(MINIMUM_PLAYERS);

  useEffect(() => {
    startEngineSfxCallback.call();
  }, []);

  useEffect(() => {
    setPlayersNeeded(MINIMUM_PLAYERS - roomState.joinedPlayer);
  }, [roomState]);

  const SpectatorNoticePanel = () => {
    return (
      <NoticePanel>
        <div className="notice_medium">You</div>
        <div className="notice_larger">ARE ENTERED</div>
        <div className="notice_medium"> INTO THIS ROUND</div>
      </NoticePanel>
    );
  };

  const PlayerNoticePanel = () => {
    return (
      <NoticePanel>
        <div>
          You
          <div className="notice_larger">ARE ENTERED</div>
          INTO THIS ROUND
        </div>
        <div>
          <strong>Your stats:</strong>
          <br />
          <img src={SkillIcon} className="battle_screen_icons" />: {props.skill}
          &nbsp;|&nbsp; <img
            src={DamageIcon}
            className="battle_screen_icons"
          />{" "}
          : {props.damage}
          &nbsp;|&nbsp;{" "}
          <img src={DurabilityIcon} className="battle_screen_icons" /> :{" "}
          {props.durability}
          &nbsp;|&nbsp; <img
            src={LuckIcon}
            className="battle_screen_icons"
          /> : {props.luck}
          &nbsp;|&nbsp; <img
            src={RewardIcon}
            className="battle_screen_icons"
          />{" "}
          : {props.reward}%
        </div>
      </NoticePanel>
    );
  };

  return (
    <>
      {playersNeeded > 0 ? (
        <MessageFrame
          time="roundStartTime"
          maintext={playersNeeded}
          subtext={"MORE PLAYERS NEEDED \n BEFORE ROUND STARTS"}
          noCursor
        />
      ) : (
        <TimerFrame time="roundStartTime" text="STARTING" />
      )}

      <div className="container_row">
        <div className="ingame-marketplace_container ">
          <MarketplaceButton />
        </div>
        <EventPanel events={roomState.messages} />
        {player.playing ? <PlayerNoticePanel /> : <SpectatorNoticePanel />}
      </div>
    </>
  );
};
