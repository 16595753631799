import React, { createContext, useEffect, useState } from "react";
import start_engine_music_file from "../static/audio/car-engine-starting.wav";
import new_challenger_file from "../static/audio/new-challenger.wav";
import roll_engine_music_file from "../static/audio/car-engine-rolling.wav";
import boost_engine_music_file from "../static/audio/Boost.wav";
import running_engine_music_file from "../static/audio/RunningEngine.wav";
import stop_engine_music_file from "../static/audio/truck-engine-stoping.wav";
import hard_collision_music_file from "../static/audio/car_collide_deep.wav";
import light_collision_music_file from "../static/audio/car_collide_light.wav";
import explosion_music_file from "../static/audio/Explosion.wav";
import glass_break_music_file from "../static/audio/GlassBreak.wav";
import { Howl } from "howler";

const AppContext = createContext({});

export { AppContext };

export default ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Initializing");
  const [activeMenu, setActiveMenu] = useState("Main");

  let sfxRollEngine = new Howl({
    src: [roll_engine_music_file],
    preload: true,
    volume: 0.2,
    loop: true,
    autoSuspend: false,
  });

  let sfxRunningEngine = new Howl({
    src: [running_engine_music_file],
    preload: true,
    volume: 0.2,
    loop: true,
    autoSuspend: false,
  });

  let sfxBoostEngine = new Howl({
    src: [boost_engine_music_file],
    preload: true,
    volume: 0.2,
    autoSuspend: false,
    onend: function () {
      if (sfxRunningEngine) sfxRunningEngine.start();
      sfxRollEngine.stop();
    },
  });

  let sfxStartEngine = new Howl({
    src: [start_engine_music_file],
    preload: true,
    volume: 0.2,
    autoSuspend: false,
    onend: function () {
      sfxRollEngine.play();
    },
  });


  let sfxNewChallenger = new Howl({
    src: [new_challenger_file],
    preload: true,
    volume: 0.25,
    autoSuspend: false,
  });

  let sfxStopEngine = new Howl({
    src: [stop_engine_music_file],
    preload: true,
    volume: 0.2,
    autoSuspend: false,
  });

  let sfxLightCrash = new Howl({
    src: [light_collision_music_file],
    preload: true,
    volume: 0.2,
    autoSuspend: false,
  });

  let sfxHardCrash = new Howl({
    src: [hard_collision_music_file],
    preload: true,
    volume: 0.2,
    autoSuspend: false,
  });

  let sfxExplosion = new Howl({
    src: [explosion_music_file],
    preload: true,
    volume: 0.1,
    autoSuspend: false,
  });

  let sfxGlassBreak = new Howl({
    src: [glass_break_music_file],
    preload: true,
    volume: 0.2,
    autoSuspend: false,
  });

  useEffect(() => {
    return () => {
      if (sfxStartEngine) {
        sfxStartEngine.stop();
      }
      if (sfxNewChallenger) {
        sfxNewChallenger.stop();
      }
      if (sfxBoostEngine) {
        sfxBoostEngine.stop();
      }
      if (sfxRollEngine) {
        sfxRollEngine.stop();
      }
      if (sfxStopEngine) {
        sfxStopEngine.stop();
      }
      if (sfxLightCrash) {
        sfxLightCrash.stop();
      }
      if (sfxHardCrash) {
        sfxHardCrash.stop();
      }
      if (sfxExplosion) {
        sfxExplosion.stop();
      }
      if (sfxRunningEngine) {
        sfxRunningEngine.stop();
      }
      if (sfxGlassBreak) {
        sfxGlassBreak.stop();
      }
    };
  }, []);

  const ShowLoading = (newLoading, loadingText = "") => {
    setLoading(newLoading);
    setLoadingText(loadingText);
  };

  const switchMenu = (newMenu) => {
    setActiveMenu(newMenu);
  };

  return (
    <AppContext.Provider
      value={{
        loading,
        loadingText,
        ShowLoading,
        sfxStartEngine,
        sfxNewChallenger,
        sfxRollEngine,
        sfxBoostEngine,
        sfxStopEngine,
        sfxLightCrash,
        sfxHardCrash,
        sfxExplosion,
        sfxRunningEngine,
        sfxGlassBreak,
        activeMenu,
        switchMenu,
      }}
    >
      {children}
      
    </AppContext.Provider>
  );
};
