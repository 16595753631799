import React from "react";

import _ from "lodash";
import DamageCrack, { crack_variants } from "./DamageCrack";
import { crackedCallback } from "../../../network/Connector";

//Refer to css for dimensions
const crackDimensions = {
  width: 215,
  height: 215,
};

class DamageCracksDisplay extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Crack Object
     * Remaining Health: [Integer],
     * position: {
     * top: Integer,
     * left: Integer
     * },
     * variant: [Integer]
     */
    this.state = {
      cracks: [],
    };

    window.overlaps = this.overlaps;
  }

  overlaps = (crack1, crack2) => {
    function getPositions(crack) {
      const { width, height } = crackDimensions;
      return [
        [crack.left, crack.left + width],
        [crack.top, crack.top + height],
      ];
    }

    function comparePositions(p1, p2) {
      var r1 = p1[0] < p2[0] ? p1 : p2;
      var r2 = p1[0] < p2[0] ? p2 : p1;
      return r1[1] > r2[0] || r1[0] === r2[0];
    }

    var pos1 = getPositions(crack1),
      pos2 = getPositions(crack2);

    var isOverlapping =
      comparePositions(pos1[0], pos2[0]) && comparePositions(pos1[1], pos2[1]);

    console.log("debug-2 check overlap", crack1, crack2, isOverlapping);

    return isOverlapping;
  };

  hasCollision = (position) => {
    const { cracks } = this.state;

    for (let i = 0; i < cracks.length; i++) {
      const crack = cracks[i];
      if (this.overlaps(crack.position, position)) {
        return true;
      }
    }
    return false;
  };

  getRandomCrackPosition = (attempts = 1) => {
    const { leftBound, topBound } = this.props;
    const maxAttempts = 5;

    var crackPosition = {
      left: _.random(leftBound),
      top: _.random(topBound),
    };

    var hasCollision = this.hasCollision(crackPosition);

    if (hasCollision && attempts < maxAttempts)
      crackPosition = this.getRandomCrackPosition(attempts);

    return crackPosition;
  };

  crackedListener = (remaining_health) => {
    console.log("debug-2 remaining_health", remaining_health);
    const randomPosition = this.getRandomCrackPosition();
    console.log({ randomPosition });
    this.addCrack(remaining_health, randomPosition);
  };

  componentDidMount() {
    crackedCallback.setListener(this.crackedListener.bind(this));
  }

  addCrack = (remaining_health, position) => {
    const { cracks } = this.state;
    const randomVariant = _.random(crack_variants.length - 1, false);
    const newCrack = {
      remaining_health,
      position,
      variant: randomVariant,
    };

    console.log("debug-2 newCrack", newCrack);

    this.setState({
      cracks: [...cracks, newCrack],
    });
  };

  render() {
    const cracks_display = this.state.cracks.map((crack, i) => {
      return (
        <DamageCrack
          key={crack.position + i}
          remaining_health={crack.remaining_health}
          position={crack.position}
          variant={crack.variant}
        />
      );
    });

    return <div id="damage-cracks-container">{cracks_display}</div>;
  }
}

export default DamageCracksDisplay;
