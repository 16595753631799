import React, { useContext, useState, useEffect } from "react";
import {
  GameContext,
  createWithdrawAction,
  createDepositAction,
  SignTransaction,
  sendMessage,
  createTrophyWithdrawalActions,
  createDepositTrophyActions,
} from "../../../../../network/Connector";
import _ from "lodash";
import { AppContext } from "../../../../AppContext.js";
import Button, { LongButton } from "../../../../atom/Button";
import { DERBY_TOKEN_SYMBOL } from "../../../../../Constants";
import Modal from "../../../../atom/Modal";
import "./exchangeStyles.css";
import ReactTooltip from "react-tooltip";

const onChainDelay = 5000;
const refreshDelay = 5000;

const TokenExchange = () => {
  const { player, room, ual, } = useContext(GameContext);
  const [processing, setProcessing] = useState(false);
  // const [activeScreen, setActiveScreen] = useState("Bank");
  const [amount, setAmount] = useState(0);

  const Withdraw = async () => {
    let bankBalance = parseFloat(player.tokenAmount.split(" ")[0]);

    if (amount <= 0) {
      alert("Amount must be greater than 0");
      return;
    }

    if (bankBalance < amount) {
      alert("Overdrawn balance");
      return;
    }

    setProcessing(true);

    const actions = createWithdrawAction(
      ual.activeUser.accountName,
      amount.toFixed(4) + ` ${DERBY_TOKEN_SYMBOL}`
    );
    console.log("debug-1 actions", actions);
    const success = await SignTransaction(actions);

    if (success) {
      setProcessing(true);
      setTimeout(() => {
        sendMessage(() => {
          room.send("refreshBalance");

          setTimeout(() => {
            setProcessing(false);
          }, refreshDelay);
        });
      }, onChainDelay);
    }
  };

  const Deposit = async () => {
    let walletBalance = parseFloat(player.walletDerby.split(" ")[0]);

    if (amount <= 0) {
      alert("Amount must be greater than 0");
      return;
    }
    if (walletBalance < amount) {
      alert("Not enough DERBY for deposit");
      return;
    }

    const actions = createDepositAction(
      ual.activeUser.accountName,
      amount.toFixed(4) + ` ${DERBY_TOKEN_SYMBOL}`
    );
    console.log("debug-1 actions", actions);
    const success = await SignTransaction(actions);
    if (success) {
      setProcessing(true);
      setTimeout(() => {
        sendMessage(() => {
          room.send("refreshBalance");

          setTimeout(() => {
            setProcessing(false);
          }, refreshDelay);
        });
      }, onChainDelay);
    }
  };

  const handleInputChange = (e) => {
    let input = parseFloat(e.target.value);

    setAmount(input);
  };

  return (
    <>
      <span className="exchange-title">
        Wallet BALANCE: {player.walletDerby.split(" ")[0]}
      </span>
      <span className="exchange-title">
        Bank BALANCE: {player.tokenAmount.split(" ")[0]}
      </span>
      <input
        type="number"
        className="exchange-input"
        onChange={handleInputChange}
        value={amount}
      />
      <div className="exchange-buttons">
        <Button disabled={processing} onClick={() => Withdraw()}>
          {processing ? "Processing" : "Withdraw"}
        </Button>
        <Button disabled={processing} onClick={() => Deposit()}>
          {processing ? "Processing" : "Deposit"}
        </Button>
      </div>
    </>
  );
};

const TrophyExchange = () => {
  const { player, room, ual } = useContext(GameContext);

  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);

  const Withdraw = async () => {
    let bankBalance = player.trophyAmount;

    if (amount <= 0) {
      alert("Amount must be greater than 0");
      return;
    }
    if (bankBalance < amount) {
      alert("Overdrawn balance");
      return;
    }

    const actions = createTrophyWithdrawalActions(
      ual.activeUser.accountName,
      amount
    );
    console.log("debug-1 actions", actions);
    const success = await SignTransaction(actions, {}, false);

    if (success) {
      setProcessing(true);
      setTimeout(() => {
        sendMessage(() => {
          room.send("refreshBalance");

          setTimeout(() => {
            setProcessing(false);
          }, refreshDelay);
        });
      }, onChainDelay);
    }
    else{
      alert('Please try splitting your withdrawals in smaller amounts to avoid maxing out CPU usage.');
    }
  };

  const Deposit = async () => {
    let walletBalance = player.trophies.length;

    if (amount <= 0) {
      alert("Amount must be greater than 0");
      return;
    }
    if (walletBalance < amount) {
      alert("Not enough trophies");
      return;
    }

    let trophiesToBurn = [];

    for (let i = 0; i < amount; i++) {
      const _trophy = player.trophies[i];
      trophiesToBurn.push(_trophy.asset_id);
    }

    const actions = createDepositTrophyActions(
      ual.activeUser.accountName,
      trophiesToBurn
    );

    console.log("debug-1 actions", actions);
    const success = await SignTransaction(actions);
    if (success) {
      setProcessing(true);
      setTimeout(() => {
        sendMessage(() => {
          room.send("refreshBalance");

          setTimeout(() => {
            setProcessing(false);
          }, refreshDelay);
        });
      }, onChainDelay);
    }
  };

  return (
    <>
      <span className="exchange-title">
        Wallet Balance: {player.trophies.length}
      </span>
      <span className="exchange-title">
        Bank Balance: {player.trophyAmount} 
        
            
      </span>

      <input
        type="number"
        className="exchange-input"
        onChange={(e) => setAmount(parseInt(e.target.value))}
        value={amount}
      />
      <div className="exchange-buttons">
        <Button disabled={processing} onClick={() => Withdraw()}>
          {processing ? "Processing" : "Withdraw"}
              
        </Button>
        <Button disabled={processing} onClick={() => Deposit()}>
          {processing ? "Processing" : "Deposit"}
        </Button>
      </div>
    </>
  );
};

export default () => {
  const { switchMenu } = useContext(AppContext);
  const [exchangeMode, setExchangeMode] = useState("Token");

  return (
    <Modal
      title={"Bank"}
      onClose={() => switchMenu("Main")}
      style={{
        flexDirection: "column",
        alignItems: "center",
      }}
      autoHeight
    >
      <div className="market-tabs">
        <LongButton
          className={
            "inventory-header-return " +
            (exchangeMode == "Token" ? "highlight-on" : "")
          }
          onClick={() => setExchangeMode("Token")}
          small
        >
          Derby
        </LongButton>
        <LongButton
          className={
            "inventory-header-return " +
            (exchangeMode == "Trophy" ? "highlight-on" : "")
          }
          onClick={() => setExchangeMode("Trophy")}
          small
        >
          Trophy
        </LongButton>
      </div>
      
      {exchangeMode == "Token" && <TokenExchange />}
      {exchangeMode == "Trophy" && <TrophyExchange />}
    </Modal>
  );
};
