import React from "react";
import "./style.css";

export default ({ src, onClick, disabled, className = "", style }) => (
  <img
    disabled={disabled}
    onClick={onClick}
    className={"imgbutton " + className}
    src={src}
    style={style}
  />
);
