import React, { useEffect, useState } from "react";
import { LongButton } from "./Button";
import SliderInput from "./Slider";
import AudioIcon from "../../static/icons/audio.png";
import FullAudioIcon from "../../static/icons/icon-volume_full.png";
import MediumAudioIcon from "../../static/icons/icon-volume_med.png";
import LowAudioIcon from "../../static/icons/icon-volume_low.png";
import LowestAudioIcon from "../../static/icons/icon-volume_lowest.png";
import MutedAudioIcon from "../../static/icons/icon-volume_mute.png";
import buttonbg from "../../static/btn_bg.png";
import { Howler } from "howler";
import CacheHelper from "../../utils/CacheHelper";

export default function VolumeControl() {
  const [controlsOn, setControlsOn] = useState(false);

  const [volume, setVolume] = useState(
    CacheHelper.loadFromLocal("VOLUME", 50, "int")
  );

  const [audioIcon, setAudioIcon] = useState(FullAudioIcon);

  useEffect(() => {
    const newVolume = volume/100;
    if(newVolume <= 0){
      setAudioIcon(MutedAudioIcon);
    }
    else if(newVolume <= .25){
      setAudioIcon(LowestAudioIcon);
    }
    else if(newVolume <= .5){
      setAudioIcon(LowAudioIcon);
    }
    else if(newVolume <= .75){
      setAudioIcon(MediumAudioIcon);
    }
    else{
      setAudioIcon(FullAudioIcon);
    }

    Howler.volume(newVolume);
  }, [volume]);

  return (
    <div className="volume-container">
      <LongButton
        className="volume"
        onClick={() => {
          setControlsOn(!controlsOn);
        }}
        icon={audioIcon}
        iconStyle={{height: 33}}
      />
      {controlsOn && (
        <div
          className="volume-bar"
          style={{ backgroundImage: `url(${buttonbg})` }}
        >
          <SliderInput
            value={volume}
            setValue={(_vol) => {
              setVolume(_vol);
              CacheHelper.saveToLocal("VOLUME", parseInt(_vol));
            }}
            className="volume-bar-input"
          />
        </div>
      )}
    </div>
  );
}
