import React from "react";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Scatter } from "ual-scatter";

import { Anchor } from "ual-anchor";

import { Wax } from "@eosdacio/ual-wax";

import "regenerator-runtime/runtime";
import { eos, appName } from "../Constants";

export default ({ main }) => {
  const AppWithUAL = withUAL(main);

  const scatter = new Scatter([eos], { appName });
  const anchor = new Anchor([eos], { appName });
  //const wax = new Wax([eos]); //wax is disabled on testnet

  return (
    <UALProvider
      chains={[eos]}
      appName={appName}
      authenticators={[
          scatter
          ,anchor
         // ,wax //wax is disabled on testnet
        ]}
    >
      <AppWithUAL />
    </UALProvider>
  );
};
