import React, { useContext, useState, useEffect } from "react";
import {
  GameContext,
  startDayCallback,
  joinCallback,
  sendMessage,
} from "../../../../../network/Connector";
import { ClaimButton } from "../../components/ClaimButton";
import { CountdownTimer } from "../../../TimerFrame";
import MessageFrame, { SpecialFrame } from "../../../MessageFrame";
import CacheHelper from "../../../../../utils/CacheHelper";
import { ClaimNftsButton } from "../../components/ClaimNftsButton";

export default ({
  onClaim,
  onClaimNfts,
  onJoin,
  joining,
  setJoining,
  setJoins,
  joins,
}) => {
  const { player, room, roomState } = useContext(GameContext);
  const [maxJoins, setMaxJoins] = useState(0);

  const joinResponse = (response) => {
    if (response.success) {
    } else {
      alert(response.error);
      setJoining(false);
    }
  };

  useEffect(() => {
    //Calculate max joins possible
    const _maxStaminaGames =
      (player.energy.stamina - (player?.energy?.consumedStamina || 0)) /
      room.state.staminaConsumption;
    const _maxDurabilityGames =
      (player.energy.durability - (player?.energy?.consumedDurability || 0)) /
      room.state.durabilityConsumption;

    //Calculate default val
    let maxGames = Math.min(_maxStaminaGames, _maxDurabilityGames);
    let defaultVal = Math.min(
      maxGames,
      player?.selectedManager?.leadership || 1
    );
    defaultVal = parseInt(defaultVal);

    setMaxJoins(defaultVal);
    console.log("debug-autojoin", {
      maxGames,
      _maxStaminaGames,
      _maxDurabilityGames,
      defaultVal,
    });

    let cacheExists = CacheHelper.loadFromLocal("JOINS", false);

    //Only set default val
    if (joins > defaultVal || !cacheExists || (joins <= 0 && defaultVal > 0)) {
      console.log("debug-joins Overlapping max joins", { joins, defaultVal });
      setJoins(defaultVal);
    }

    joinCallback.setListener(joinResponse);
  }, [player.energy.stamina, player.energy.durability]);

  const canPlay = () => {
    const requiredStamina = roomState.staminaConsumption;
    const hasEnoughStamina =
      player.energy.stamina - player.energy.consumedStamina >= requiredStamina;

    const requiredDurability = roomState.durabilityConsumption;
    const hasEnoughDurability =
      player.energy.durability - player.energy.consumedDurability >=
      requiredDurability;

    return hasEnoughStamina && hasEnoughDurability;
  };

  return (
    <>
      {player.rewards.claimable_derby > 0 ||
      player.rewards.claimable_nfts.length > 0 ||
      player.rewards.on_chain_nfts.length > 0 ||
      player.rewards.is_claiming ||
      player.rewards.is_fetching_claimables ? (
        player.rewards.on_chain_nfts.length > 0 ? (
          <ClaimNftsButton
            onClaim={onClaimNfts}
            claimableNfts={`${player.rewards.on_chain_nfts.length}`}
          />
        ) : player.rewards.is_fetching_claimables > 0 ? (
          <ClaimNftsButton
            onClaim={() => {
              alert("Fetching claimable nfts");
            }}
            claimableNfts={"Fetching"}
          />
        ) : (
          <ClaimButton
            onClaim={onClaim}
            coinRewardsText={player.rewards.claimable_derby.toFixed(4)}
            trophyAmount={player.rewards.claimable_trophies}
          />
        )
      ) : canPlay() ? (
        joining ? (
          <MessageFrame
            onClick={() => {}}
            maintext={"JOINING"}
            subtext={"Please wait..."}
          />
        ) : (
          <>
            <SpecialFrame
              time="roundStartTime"
              //onMouseDown={console.warn("CLICKED!")}
              onMouseDown={(event) => {
                let hit = event.target;
                //Prevent triggering join when clicking input
                if (hit.tagName === "INPUT") {
                  return;
                }

                if (hit.className === "joins-controls") {
                  return;
                }
                //calculate if stamina and durability are enough
                const consumedDurability =
                  joins * room.state.durabilityConsumption;
                if (consumedDurability > player.energy.durability) {
                  alert(
                    "Insufficient durability to enter this amount of rounds"
                  );
                  return;
                }

                const consumedStamina = joins * room.state.staminaConsumption;
                if (consumedStamina > player.energy.stamina) {
                  alert("Insufficient stamina to enter this amount of rounds");
                  return;
                }

                let canJoin = onJoin();
                if (canJoin) setJoining(true);
              }}
              maintext="JOIN"
              subtext={
                player.selectedManager != null ? (
                  <label className="join-entries" htmlFor="joins">
                    Entries
                    <br />
                    <div style={{ display: "flex" }}>
                      <button
                        className="joins-controls"
                        onClick={() => {
                          console.log("debug-joins Decreasing joins");
                          setJoins((_joins) => {
                            let entries = _joins - 1;
                            if (entries < 1) {
                              entries = 1;
                            }

                            CacheHelper.saveToLocal("JOINS", entries);
                            return entries;
                          });
                        }}
                      >
                        -
                      </button>
                      <input
                        id="joins"
                        type="text"
                        value={joins}
                        onChange={(e) => {
                          //TODO: CONTINUE AUTO JOIN INPUT UPDATE
                          let input = e.target.value;
                          if (input == "") {
                            input = "1";
                          }
                          let entries = parseInt(input);
                          if (entries > maxJoins) {
                            entries = maxJoins;
                          }

                          if (entries < 1) {
                            entries = 1;
                          }
                          console.log("debug-joins Typing joins");

                          CacheHelper.saveToLocal("JOINS", entries);
                          setJoins(entries);
                        }}
                      />
                      <button
                        className="joins-controls"
                        onClick={() => {
                          setJoins((_joins) => {
                            console.log("debug-joins Increasing joins");
                            let entries = _joins + 1;
                            if (entries > maxJoins) {
                              entries = maxJoins;
                            }

                            CacheHelper.saveToLocal("JOINS", entries);
                            return entries;
                          });
                        }}
                      >
                        +
                      </button>
                    </div>
                  </label>
                ) : (
                  ""
                )
              }
            />
          </>
        )
      ) : (
        ""
      )}
    </>
  );
};
