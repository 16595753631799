import React, { useContext, useState, useEffect } from "react";
import {
  GameContext,
  claimResolvedCallback,
  claimSignedCallback,
  ClaimNfts,
  ual,
  sendMessage,
} from "../../../../network/Connector";
import "./claimModal.css";
import {
  DERBY_TOKEN_DISPLAY,
  IPFS_BASE_URL,
  ATOMIC_HUB_LINK,
} from "../../../../Constants";
import Modal from "../../../atom/Modal";
import NftImage from "../../../atom/NftImage";

const NftCard = ({ nft }) => {
  useEffect(() => {
    console.log("nft", nft);
  }, []);
  return (
    <a
      onClick={() => {
        window.open(`${ATOMIC_HUB_LINK}/explorer/asset/${nft.asset_id}`);
      }}
      className={"btn selected"}
    >
      <NftImage
        className="selection_img"
        imgClass="selection_img"
        src={`${IPFS_BASE_URL}${nft?.img}`}
        style={{
          backgroundColor: "transparent",
          backgroundPositionX: "center",
        }}
      />
    </a>
  );
};

const NftRewardsDisplay = () => {
  const { player } = useContext(GameContext);
  const [nftContainerStyle, setNftContainerStyle] = useState({});

  const allNfts =
    player?.rewards?.claimable_nfts?.length > 0
      ? player?.rewards?.claimable_nfts
      : player?.rewards?.on_chain_nfts;
  console.log({ allNfts });
  useEffect(() => {
    let style = {};

    if (allNfts.length <= 5) {
      style.overflowX = "hidden";
    }
    setNftContainerStyle(style);
  }, []);

  var nftsDisplay = allNfts.map((nft, index) => {
    console.log("nft", nft);

    return (
      <div className="nft_card" key={"nft_selection_" + index}>
        <NftCard nft={nft} />
      </div>
    );
  });

  return (
    <div className="nft_rewards_container" style={nftContainerStyle}>
      {nftsDisplay}
    </div>
  );
};

const ClaimModal = ({ closeModal }) => {
  const { player, room } = useContext(GameContext);
  const [claimText, setClaimText] = useState("Claim Rewards");
  const [claimState, setClaimState] = useState("Unclaimed");
  const [claimDisabled, setClaimDisabled] = useState(false);

  const ClaimSigned = async (signedTx) => {
    if (signedTx.success) {
      setClaimState("Verifying");
    } else {
      setClaimState("Unclaimed");
    }
  };

  const ClaimResolved = async (resolved) => {
    if (resolved) {
      setClaimText("Claim Successful");
      closeModal();
    } else {
      setClaimText("Retry Claim");
      setClaimState("Unclaimed");
      alert("Claim failed");
    }
    setClaimDisabled(false);
  };

  useEffect(() => {
    console.log("ClaimModal", { player });

    claimResolvedCallback.setListener(ClaimResolved);
    claimSignedCallback.setListener(ClaimSigned);
  }, []);

  useEffect(() => {
    switch (claimState) {
      case "Claiming":
        setClaimText("Claiming...");
        break;
      case "Verifying":
        setClaimText("Processing claim");
        break;
    }
  }, [claimState]);

  const ClaimRewards = async () => {
    if (claimState == "Claiming" || claimState == "Verifying") {
      return;
    }

    setClaimDisabled(true);
    setClaimState("Claiming");

    if (player.rewards.on_chain_nfts.length > 0) {
      let success = await ClaimNfts(
        room,
        ual.activeUser.accountName,
        player.rewards.on_chain_nfts.length >= 10
          ? 10
          : player.rewards.on_chain_nfts.length
      );
      if (!success) {
        setClaimDisabled(false);
        setClaimText("Retry Claim");
        setClaimState("Unclaimed");
        alert("Claim failed");
      }
    } else {
      sendMessage(async () => {
        room.send("claimRewards");
      });
    }
  };

  return (
    <Modal
      title={"Claim your rewards now"}
      onClose={() => closeModal()}
      buttonLabel={claimText}
      buttonDisabled={claimDisabled}
      onClick={ClaimRewards}
      autoHeight={player.rewards.claimable_nfts.length == 0}
      style={{ flexDirection: "column" }}
    >
      <>
        {(player.rewards.claimable_nfts.length > 0 ||
          player.rewards.on_chain_nfts.length > 0) && <NftRewardsDisplay />}

        {player.rewards.claimable_derby > 0 && (
          <div className="claimable_tokens_display" id="tokens-container">
            Claimable Tokens: {player.rewards.claimable_derby.toFixed(4)}{" "}
            {DERBY_TOKEN_DISPLAY} <br />
            
            {parseInt(player.rewards.claimable_trophies) > 0 &&
              `Claimable Trophies: ${player.rewards.claimable_trophies}`}
          </div>
        )}
      </>
    </Modal>
  );
};

export default ClaimModal;
