import React, { useContext, useEffect, useState } from "react";
import MessageFrame from "../MessageFrame";
import { GameContext, stopEngineSfxCallback } from "../../../network/Connector";
import EventPanel from "./components/EventPanel";
import ClaimModal from "./components/ClaimModal.jsx";
import NoticePanel from "./components/NoticePanel";
import { DERBY_TOKEN_DISPLAY } from "../../../Constants";
import "./screen.css";
import { Howl } from "howler";
import { MarketplaceButton } from "./GameScreen";
import cheer_music_file from "../../../static/audio/Cheer.wav";
import { ClaimButton } from "./components/ClaimButton";

let sfxCheer = new Howl({
  src: [cheer_music_file],
  preload: true,
  volume: 0.5,
});

export default () => {
  const { roomState, player } = useContext(GameContext);

  //Claim modal switches
  const [claimModalSwitch, setClaimModalSwitch] = useState(false);
  const showClaimModal = (bool) => setClaimModalSwitch(bool);

  const [coinRewardsText, setCoinRewardsText] = useState("");

  useEffect(() => {
    if (player) {
      setCoinRewardsText(`${player.result.bnxRewards} ${DERBY_TOKEN_DISPLAY}`);
    }
  }, [player]);

  const ClaimRewards = async () => {
    showClaimModal(true);
  };

  useEffect(() => {
    stopEngineSfxCallback.call();
    sfxCheer.play();

    return () => {
      if (sfxCheer) {
        sfxCheer.stop();
      }
    };
  }, []);

  const PlayerDisplay = () => {
    return (
      <NoticePanel>
        <div className="winner_first">
          You are 1<span>ST</span>
          <br />
          PLACE WINNER
        </div>
      </NoticePanel>
    );
  };

  return (
    <>
      {player.autoJoins > 0 ? (
        <MessageFrame maintext={"Joining"} subtext={"Next Game..."} />
      ) : (
        <ClaimButton
          onClaim={() => {
            ClaimRewards();
          }}
          coinRewardsText={coinRewardsText}
          trophyAmount={player.rewards.claimable_trophies}
        />
      )}

      <div className="container_row">
        <div className="ingame-marketplace_container ">
          <MarketplaceButton />
        </div>
        <EventPanel events={[...player.result.events, ...roomState.messages]} />
        <PlayerDisplay />
        {claimModalSwitch &&
          (player.rewards.claimable_derby > 0 ||
            player.rewards.claimable_nfts.length > 0) && (
            <ClaimModal
              onSelect={(nft) => {
                console.log("reward selected", nft);
              }}
              closeModal={() => {
                setClaimModalSwitch(false);
              }}
            />
          )}
      </div>
    </>
  );
};
