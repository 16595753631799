import React, { useEffect, useState, useContext } from "react";
import { CountdownTimer } from "../TimerFrame";
import MessageFrame from "../MessageFrame";
import {
  GameContext,
  startDayCallback,
  sendMessage
} from "../../../network/Connector";

export const ClosedButton = () => {
  const { room, player } = useContext(GameContext);
  const [buttonText, setButtonText] = useState("Open");

  const [loaded, setLoaded] = useState(false);
  const [waitTime, setWaitTime] = useState(10000);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const calculateWaitTime = (interval = null) => {
    let now = new Date().getTime();
    let unlockDate = player.flag.timestamp + room.state.openCooldown;

    let remainingSecs = Math.floor((unlockDate - now) / 1000);

    if (remainingSecs <= 0 && interval != null) {
      clearInterval(interval);
    }

    let mins = Math.floor(remainingSecs / 60);
    const hrs = Math.floor(mins / 60);
    mins -= hrs * 60;

    let secs = remainingSecs % 60;
    if (secs < 10) {
      secs = `0${secs}`;
    }
    console.log({ remainingSecs, mins, secs });

    setHours(hrs);
    setMinutes(mins);
    setSeconds(secs);

    setWaitTime(remainingSecs);
  };

  const handleOpenResponse = (success) => {
    if (success) {
    } else {
      setButtonText("Open");
    }
  };

  useEffect(() => {
    calculateWaitTime();
    startDayCallback.setListener(handleOpenResponse);
    const interval = setInterval(() => {
      calculateWaitTime(interval);
    }, 1000);

    setTimeout(() => {
      setLoaded(true);
    });
    return () => {
      clearInterval(interval);
    };
  }, []);

  const doOpen = () => {
    console.log("Do Open 1");
    if (waitTime > 0) {
      alert("Too early to open");
      return;
    }
    console.log("Do Open 2");

    if (buttonText == "Open") {
      console.log("Attempt start day");
      sendMessage(() => {
        room.send("startDay");
      });
      setButtonText("OPENING");
    }
  };

  return (
    <>
      {loaded ? (
        waitTime > 0 ? (
          <CountdownTimer
            onClick={doOpen}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            text={"OPENING IN:"} />
        ) : (
          <MessageFrame
            onClick={doOpen}
            overrideStyle={{
              zIndex: 2,
            }}
            maintext={buttonText}
            subtext={"START CRASHING NOW!"} />
        )
      ) : (
        <h1 className="loading">Loading...</h1>
      )}
    </>
  );
};
