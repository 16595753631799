import React, { useContext, useEffect, useState } from "react";
import {
  GameContext,
  updateMaxDurabilityCallback,
  createBurnAction,
  SignTransaction,
  inventoryDataValidatedCallback,
  updateMaxStaminaCallback,
  openCarPartBurnModalCallback,
  openEnergyDrinkBurnModalCallback, //Called when garage data is validated
  sendMessage,
} from "../../../../../network/Connector";

import ReactTooltip from "react-tooltip";

import { BurnCarSelectionModal } from "./CarComponents.jsx";
import { BurnDriverSelectionModal } from "./DriverComponents";
import { ENERGY_MULTIPLIER } from "../../../../../Constants";
import Modifiers from "../../../../../utils/StatCalculator/Modifiers";
import InventoryHeader from "../../../../atom/InventoryHeader";
import { LongButton } from "../../../../atom/Button";
import Modal from "../../../../atom/Modal";
import modalbg from "../../../../../static/btn_bg.png";
import "./statPanel.css";
import "../../../../atom/style.css";
//icons
import DamageIcon from "../../../../../static/icons/Damage.png";
import DurabilityIcon from "../../../../../static/icons/Durability.png";
import LeadershipIcon from "../../../../../static/icons/Leadership.png";
import LuckIcon from "../../../../../static/icons/Luck.png";
import ManagerResourcefulnessIcon from "../../../../../static/icons/Manager Resourcefulness.png";
import MechanicResourcefulnessIcon from "../../../../../static/icons/Mechanic Resourcefulness.png";
import SkillIcon from "../../../../../static/icons/Skill.png";
import RewardIcon from "../../../../../static/icons/Reward.png";
import EnergyIcon from "../../../../../static/icons/Energy 1.png";
import RepairIcon from "../../../../../static/icons/Repair 2.png";
import DerbyTokenIcon from "../../../../../static/icons/Token.png";

import "../../components/panel.css";

export const TotalStats = ({
  selectedCars = [],
  selectedDrivers = [],
  onSave,
  selectedMechanics = [],
  selectedMechanic,
  savingData,
  garageData,
  inventoryData,
  inventoryDataKeys,
  onBurnCar,
  onBurnDriver,
  onClose,
  stats,
  title,
  openHelp,
}) => {
  const { player, room } = useContext(GameContext);

  console.log("componentDidMount TotalStats", { stats });

  return (
    <>
      <InventoryHeader
        title={title}
        onSave={() => {
          if (savingData) {
            return;
          }
          onSave();
          sendMessage(() => {
            room.send("validateInventoryData", {
              inventoryData,
              inventoryDataKeys,
            });
          });
        }}
        onSaveText={savingData ? "Loading...." : "Save \n Configurations"}
        onReturn={() => onClose()}
      />
      <div id="left-stat-buttons">
        {inventoryDataKeys.includes("cars") ? (
          <>
            <LongButton
              onClick={() => openCarPartBurnModalCallback.call()}
              style={{ marginBottom: 8 }}
            >
              Burn Part <br /> for Repair
            </LongButton>
            <LongButton onClick={() => onBurnCar()}>
              Dismantle Car <br /> for Parts
            </LongButton>
          </>
        ) : (
          <>
            <LongButton
              onClick={() => openEnergyDrinkBurnModalCallback.call()}
              style={{ marginBottom: 8 }}
            >
              Consume Snacks <br /> for Energy
            </LongButton>
            <LongButton onClick={() => onBurnDriver()}>
              Retire Driver <br /> for Snacks
            </LongButton>
          </>
        )}
      </div>
      <div id="total-stats">
        <div id="stats-title">
          <strong>Stats</strong>
          <div className="stat_question" onClick={openHelp}>
            ?
          </div>
        </div>
        <hr />
        <div id="stat-container">
          {stats != null ? (
            <>
              <StatDetail
                stat={<img src={EnergyIcon} className="stat_panel_icons" />}
                tooltip="Energy"
                value={stats?.energy?.stamina}
              />
              <StatDetail
                stat={<img src={RepairIcon} className="stat_panel_icons" />}
                tooltip="Repair"
                value={stats?.energy?.durability}
              />
              <StatDetail
                stat={<img src={DamageIcon} className="stat_panel_icons" />}
                tooltip="Damage"
                value={(stats?.durability || 0) + stats.upgrades.damage}
              />
              <StatDetail
                stat={<img src={RewardIcon} className="stat_panel_icons" />}
                tooltip="Reward"
                value={`${parseFloat(stats?.reward.toFixed(2)) || 0}%`}
              />
              <StatDetail
                stat={<img src={DurabilityIcon} className="stat_panel_icons" />}
                tooltip="Durability"
                value={(stats?.durability || 0) + stats.upgrades.durability}
              />
              <StatDetail
                stat={<img src={LuckIcon} className="stat_panel_icons" />}
                tooltip="Luck"
                value={
                  (stats?.luck || 0) +
                  stats.upgrades.luck * Modifiers.UPGRADE_LUCK_MULTIPLIER
                }
              />
              <StatDetail
                stat={<img src={SkillIcon} className="stat_panel_icons" />}
                tooltip="Skill"
                value={(stats?.skill || 0) + stats.upgrades.initiative}
              />

              <StatDetail
                stat={<img src={LeadershipIcon} className="stat_panel_icons" />}
                tooltip="Leadership"
                value={stats?.leadership || 0}
              />
              <StatDetail
                stat={
                  <img
                    src={MechanicResourcefulnessIcon}
                    className="stat_panel_icons"
                  />
                }
                tooltip="Mechanic Resourcefulness"
                value={stats?.resourcefulness?.mechanic || 0}
              />
              <StatDetail
                stat={
                  <img
                    src={ManagerResourcefulnessIcon}
                    className="stat_panel_icons"
                  />
                }
                tooltip="Manager Resourcefulness"
                value={stats?.resourcefulness?.manager || 0}
              />
            </>
          ) : (
            "No stats"
          )}
        </div>
        
        <ReactTooltip
        className="tooltip"
          id={"stat-tooltip"}
          aria-haspopup="true"
          role="stats-display"
        >
        </ReactTooltip>
      </div>
    </>
  );
};

const StatDetail = ({ stat, tooltip = "Tooltip", value }) => {
  return (
    <>
      <span
        className="stat-detail"
        data-tip={tooltip}
        data-for={"stat-tooltip"}
      >
        {stat}
        <small className="stat-detail-value">{`: ${value}`}</small>
      </span>
    </>
  );
};

const StatDefinition = ({ icon, title, definition }) => {
  return (
    <div
      style={{ position: "relative" }}
    >
      {icon}
      <span className="stat_title">{title}</span>
      <p className="stat_definition">{definition}</p>
    </div>
  );
};

const StatModal = ({ closeModal }) => {
  return (
    <Modal
      title={"Stats Definition"}
      onClose={() => closeModal()}
      buttonLabel={""}
      buttonDisabled={false}
      onClick={() => {}}
      autoHeight={false}
      style={{ flexDirection: "column", overflowY: "auto" }}
      modalStyle={{
        backgroundImage: `url(${modalbg})`,
        filter: "brightness(1.05)",
      }}
    >
      <br />
      <StatDefinition
        icon={
          <img
            src={EnergyIcon}
            style={{ filter: "sepia(100%)" }}
            className="stat_help_icons"
          />
        }
        title={"Energy"}
        definition={
          <>
            Each entry in the Battle Arena consumes Energy. The player's max
            Energy is determined by the combined Skill stats of every Driver
            card in the player's Office. The player's Energy only refills under
            one of these two conditions:
            <br />
            <ul>
              <li>The game is opened after having been closed for 8 hours.</li>
              <li>An Energy card has been burned.</li>
            </ul>
          </>
        }
      />

      <StatDefinition
        icon={<img src={RepairIcon} className="stat_help_icons" />}
        title={"Repair"}
        definition={
          <>
            Each entry in the Battle Arena consumes Repair. The player's max
            Repair is determined by the combined Durability stats of every Car
            card in the player's Garage. The player's Repair only refills under
            one of these two conditions:
            <br />
            <ul>
              <li>The game is opened after having been closed for 8 hours.</li>
              <li>An Repair card has been burned.</li>
            </ul>
          </>
        }
      />

      <StatDefinition
        icon={<img src={DamageIcon} className="stat_help_icons" />}
        title={"Damage"}
        definition={
          <>
            Determines the maximum possible damage dealt with each hit. Upgrade
            cards can be used to increase this stat.
          </>
        }
      />

      <StatDefinition
        icon={<img src={DurabilityIcon} className="stat_help_icons" />}
        title={"Durability"}
        definition={
          <>
            Durability impacts a Car's damage reduction. Upgrade cards can be
            used to increase this stat. The combined Durability of all Cars
            equipped in the Garage determines the player's max Repair stat. A
            Car's Durability stat also determines its Damage stat.
          </>
        }
      />

      <StatDefinition
        icon={<img src={SkillIcon} className="stat_help_icons" />}
        title={"Skill"}
        definition={
          <>
            Determines both the player's maximum Energy stat, and the chance
            that the player will successfully attack an enemy car during battle.
            The combined Skill of all Drivers equipped in the Office determines
            the player's max Energy stat.
          </>
        }
      />

      <StatDefinition
        icon={
          <img src={MechanicResourcefulnessIcon} className="stat_help_icons" />
        }
        title={"Mechanic Resourcefulness"}
        definition={
          <>
            Increases the chances that a higher number of Repair cards will be
            found when:
            <br />
            <ul>
              <li>Opening for the day.</li>
              <li>Salvaging (burning) a Car card.</li>
            </ul>
          </>
        }
      />

      <StatDefinition
        icon={
          <img src={ManagerResourcefulnessIcon} className="stat_help_icons" />
        }
        title={"Manager Resourcefulness"}
        definition={
          <>
            Increases the chances that a higher number of Energy cards will be
            found when:
            <br />
            <ul>
              <li>Opening for the day.</li>
              <li>Retiring (burning) a Driver card.</li>
            </ul>
          </>
        }
      />

      <StatDefinition
        icon={<img src={RewardIcon} className="stat_help_icons" />}
        title={"Reward"}
        definition={
          <>
            Determines the percentage of round rewards, in DERBY tokens, that a
            user will receive. For example, a Reward of 100% will award the user
            with the maximum possible round reward payout, while a Reward of 50%
            will award only half the maximum possible payout amount.
          </>
        }
      />

      <StatDefinition
        icon={<img src={LuckIcon} className="stat_help_icons" />}
        title={"Luck"}
        definition={
          <>
            Used to determine the chance that a bonus card (Energy, Repair,
            Sponsorship, Raffle Ticket, or Crash Art) will be randomly received
            after a battle.
          </>
        }
      />

      <StatDefinition
        icon={<img src={LeadershipIcon} className="stat_help_icons" />}
        title={"Leadership"}
        definition={
          <>Determines the amount of rounds that the user can auto-join.</>
        }
      />
    </Modal>
  );
};

export class StatPanel extends React.Component {
  static contextType = GameContext;

  constructor(props) {
    super(props);

    this.state = {
      savingData: false,
      burnCarSelectionModal: false,
      burnDriverSelectionModal: false,
      stats: null,
      statModal: false,
    };
  }

  onSave = () => {
    this.setState({
      savingData: true,
    });
  };

  onUpdatedDurability = (success) => {
    const { room } = this.context;

    console.log(new Date());
    if (success) {
      //TODO: HANDLE UPDATING OFF-CHAIN DATA WHEN STAMINA IS SAVED ON-CHAIN

      if (this.props.inventoryDataKeys.includes("cars")) {
        sendMessage(() => {
          room.send("updateGarage", {
            slots: this.props.slots,
            mechanic: this.props.selectedMechanic,
          });
        });
      }

      this.props.onClose();
      this.setState({
        savingData: false,
      });
    } else {
      //TODO: HANDLE ERROR MESSAGE WHEN THIS FAILS
      alert("Update failed.");
      this.setState({
        savingData: false,
      });
    }
  };

  onUpdatedBlockchain = (success) => {
    const { room } = this.context;

    console.log(new Date());
    if (success) {
      sendMessage(() => {
        room.send("updateInventory", {
          inventoryData: this.props.inventoryData,
          inventoryDataKeys: this.props.inventoryDataKeys,
        });
      });

      setTimeout(() => {
        this.props.onClose();
        this.setState({
          savingData: false,
        });
      }, 500);
    } else {
      alert("Update failed.");
      this.setState({
        savingData: false,
      });
    }
  };

  onDataValidated = (result) => {
    const { room, player } = this.context;
    const { inventoryDataKeys, equippedMechanic, equippedManager } = this.props;

    let allValid = true;

    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        if (key == "inventoryDataKeys") continue;

        const _result = result[key];
        if (!_result?.valid) {
          allValid = false;
        }
      }
    }
    console.log("onDataValidated", {
      allValid,
      equippedMechanic,
      equippedManager,
    });
    if (allValid) {
      let energyKey = inventoryDataKeys.includes("cars")
        ? "durability"
        : "stamina";
      let updateRequestData = {
        key: "max_" + energyKey,
        value: this.state.stats.energy[energyKey],
        nft: inventoryDataKeys.includes("cars")
          ? equippedMechanic
          : equippedManager,
      };
      console.log("onDataValidated", { updateRequestData });
      sendMessage(() => {
        room.send("updateMaxEnergy", updateRequestData);
      });
    }

    this.setState({
      savingData: false,
    });
  };

  static getDerivedStateFromProps(props, state) {
    try {
      const {
        selectedCars,
        selectedDrivers,
        equippedCar,
        equippedDriver,
        equippedManager,
        equippedMechanic,
      } = props;

      

      let totalDurability = selectedCars.reduce((total, nft) => {
        console.log({ nft, total });
        return (nft?.durability || 0) + total;
      }, 0);

      let totalSkill = selectedDrivers.reduce((total, nft) => {
        console.log({ nft, total });
        return (nft?.skill || 0) + total;
      }, 0);

      //console.log("getDerivedStateFromProps", { totalSkill, totalDurability });

      let maxEnergy = totalSkill * ENERGY_MULTIPLIER.skill;
      let maxRepair = totalDurability * ENERGY_MULTIPLIER.durability;

     // console.log("getDerivedStateFromProps", { maxEnergy, maxRepair });
      let totalUpgradeStats = {
        durability: 0,
        damage: 0,
        initiative: 0,
        luck: 0,
      };

      for (let i = 1; i <= 4; i++) {
        const upgrade = player["selectedPup" + i];
        //console.log("getDerivedStateFromProps", { upgrade });
        if (upgrade) {
          //Loop through attributes and add to total
          for (const attr in totalUpgradeStats) {
            if (Object.hasOwnProperty.call(totalUpgradeStats, attr)) {
              totalUpgradeStats[attr] += upgrade[attr];
            }
          }
        }
      }

      //console.log("getDerivedStateFromProps", { totalUpgradeStats });
      let _stats = {
        energy: {
          stamina: maxEnergy,
          durability: maxRepair,
        },
        resourcefulness: {
          mechanic: equippedMechanic?.resourcefulness,
          manager: equippedManager?.resourcefulness,
        },
        durability: equippedCar?.durability || 0,
        damage: equippedCar?.durability || 0,
        skill: equippedDriver?.skill || 0,
        leadership: equippedManager?.leadership || 0,
        luck: equippedDriver?.luck || 0,
        reward: equippedDriver?.reward || 0,
        upgrades: totalUpgradeStats,
      };
      console.log("componentDidMount", { _stats });

      return {
        stats: { ..._stats },
      };
    } catch (error) {
      console.error("componentDidMount", { error });
    }
    return null; // No change to state
  }

  componentDidMount = () => {
    updateMaxDurabilityCallback.setListener(
      this.onUpdatedBlockchain.bind(this)
    );

    updateMaxStaminaCallback.setListener(this.onUpdatedBlockchain.bind(this));

    inventoryDataValidatedCallback.setListener(this.onDataValidated.bind(this));
  };

  render() {
    return (
      <>
        <TotalStats
          onSave={this.onSave}
          {...this.props}
          savingData={this.state.savingData}
          //equipped data
          onBurnCar={() => {
            this.setState({
              burnCarSelectionModal: true,
            });
          }}
          onBurnDriver={() => {
            this.setState({
              burnDriverSelectionModal: true,
            });
          }}
          stats={this.state.stats}
          openHelp={() => {
            this.setState({ statModal: true });
          }}
        />

        {this.state.burnCarSelectionModal && (
          <BurnCarSelectionModal
            equippedMechanic={this.props.equippedMechanic}
            selectedCars={this.props.selectedCars}
            onBurn={async (car) => {
              console.log("debug-1 car", car);
              const { room, ual } = this.context;

              const actions = [
                createBurnAction(
                  ual.activeUser.accountName,
                  parseInt(car.asset_id)
                ),
              ];
              console.log("debug-1 actions", actions);
              const success = await SignTransaction(actions);
              if (success) {
                console.log("d-1", { room });
                //drinks = key, energytest = schema_name
                sendMessage(() => {
                  room.send("refreshNfts", {
                    key: "cars",
                    schema: "cars",
                  });
                });
                this.setState({
                  burnCarSelectionModal: false,
                });
              } else {
                alert("Burn drink failed!");
              }
            }}
            closeModal={() => {
              this.setState({
                burnCarSelectionModal: false,
              });
            }}
          />
        )}

        {this.state.statModal && (
          <StatModal
            closeModal={() => {
              this.setState({ statModal: false });
            }}
          />
        )}

        {this.state.burnDriverSelectionModal && (
          <BurnDriverSelectionModal
            equippedManager={this.props.equippedManager}
            selectedDrivers={this.props.selectedDrivers}
            onBurn={async (driver) => {
              console.log("debug-1 driver", driver);
              const { room, ual } = this.context;

              const actions = [
                createBurnAction(
                  ual.activeUser.accountName,
                  parseInt(driver.asset_id)
                ),
              ];
              console.log("debug-1 actions", actions);
              const success = await SignTransaction(actions);
              if (success) {
                console.log("d-1", { room });
                //drinks = key, energytest = schema_name
                sendMessage(() => {
                  room.send("refreshNfts", {
                    key: "drivers",
                    schema: "drivers",
                  });
                });
                this.setState({
                  burnDriverSelectionModal: false,
                });
                //closeDrinkModal();
              } else {
                alert("Burn driver failed!");
              }
            }}
            closeModal={() => {
              this.setState({
                burnDriverSelectionModal: false,
              });
            }}
          />
        )}
      </>
    );
  }
}
